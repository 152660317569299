import { HubConnectionBuilder } from "@microsoft/signalr";

export default {
  install (Vue, options) {
    const connection = new HubConnectionBuilder()
      .withUrl(options.hubUrl)
      .configureLogging(options.logLevel)
      .build();

    const vehicleHub = new Vue();
    Vue.prototype.$vehicleHub = vehicleHub;

    vehicleHub.joinFleet = (fleetId) => {
      return connection.send("joinFleet", fleetId);
    };
    vehicleHub.leaveFleet = (fleetId) => {
      return connection.send("leaveFleet", fleetId);
    };

    vehicleHub.joinVehicleLog = (fleetId, vehicleId) => {
      return connection.send("joinVehicleLog", fleetId, vehicleId);
    };

    vehicleHub.leaveVehicleLog = (fleetId, vehicleId) => {
      return connection.send("leaveVehicleLog", fleetId, vehicleId);
    };

    connection.on("telemetry", (telemetry) => {
      vehicleHub.$emit("telemetry", telemetry);
    });

    connection.on("log", (log) => {
      vehicleHub.$emit("log", log);
    });

    let startPromise = null;
    let start = () => {
      startPromise = connection.start().then(() => {
      }).catch(err => {
        // eslint-disable-next-line
        console.error("Failed to connect to Vehicle hub", err);
        return new Promise((resolve, reject) =>
          setTimeout(() => start().then(resolve).catch(reject), 5000));
      });
      return startPromise;
    };
    connection.onclose(() => start());

    if (options.enabled === "enabled") {
      start();
    }
  }
};
