<template>
  <v-container fluid>
      <v-select
        v-model='weekdays'
        :items="weekdayItems"
        :input="$emit('timeRangeUpdated', timeRangeObject)"
        label="Weekdays"
        multiple
        chips
        deletable-chips
        hint="Pick days when available"
        persistent-hint
    ></v-select>
    <template v-if="weekdays.length > 0">
        <TimeRange v-on:timeRangeUpdated="timeRangeUpdated"></TimeRange>
    </template>
  </v-container>
</template>

<script>
import TimeRange from "../TimeRange/TimeRange";

export default {
  components: {
    TimeRange
  },
  data: () => ({
    weekdays: ["Monday", "Wednesday"],
    weekdayItems: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    startTime: "",
    endTime: ""
  }),
  methods: {
    timeRangeUpdated (timeRangeObj) {
      this.startTime = timeRangeObj.startTime;
      this.endTime = timeRangeObj.endTime;
    }
  },
  computed: {
    timeRangeObject () {
      return {
        weekdays: this.weekdays,
        startTime: this.startTime,
        endTime: this.endTime
      };
    }
  }
};
</script>

<style>

</style>
