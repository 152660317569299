<template>
  <v-dialog
    v-model="show"
    max-width="1000">
    <v-card
      v-if="show">
        <v-toolbar short dark color="primary">
          <v-btn icon dark @click="searchDialog = false">
              <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
              Search
          </v-toolbar-title>
          <template v-slot:extension>
            <v-tabs v-model="modelTab">
              <v-tab
                v-for="item in modelTabs"
                :key=item
              >{{ item }}</v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-card-text>
          <v-row class="mt-2">
            <v-tabs
              v-model="searchModeTab"
              background-color="white">
              <v-tab
                v-for="item in searchModeTabs"
                :key=item
              >{{ item }}</v-tab>
            </v-tabs>
          </v-row>
          <v-tabs-items v-model="modelTab">
            <v-tab-item key="Likuma">
              <v-tabs-items v-model="searchModeTab">
                <v-tab-item key="Search Range">
                  <LikumaRangeSearchForm
                    :searchParams="searchParams"
                    ref=likumaRangeSearchForm
                  ></LikumaRangeSearchForm>
                </v-tab-item>
                <v-tab-item key="Search Single">
                  <LikumaSingleSearchForm
                    :searchParams="searchParams"
                    ref=likumaSingleSearchForm
                  ></LikumaSingleSearchForm>
                </v-tab-item>
              </v-tabs-items>
            </v-tab-item>
            <v-tab-item key="Kela">
              <v-tabs-items v-model="searchModeTab">
                <v-tab-item key="Search Range">
                  <KelaRangeSearchForm
                    :searchParams="searchParams"
                    ref=kelaRangeSearchForm
                  ></KelaRangeSearchForm>
                </v-tab-item>
                <v-tab-item key="Search Single">
                  <KelaSingleSearchForm
                    :searchParams="searchParams"
                    ref=kelaSingleSearchForm
                  ></KelaSingleSearchForm>
                </v-tab-item>
              </v-tabs-items>
            </v-tab-item>
            <v-tab-item key="Transport event">
              <v-tabs-items v-model="searchModeTab">
                <v-tab-item key="Search Range">
                  <TransportEventRangeSearchForm></TransportEventRangeSearchForm>
                </v-tab-item>
                <v-tab-item key="Search Single">
                  <TransportEventSingleSearchForm></TransportEventSingleSearchForm>
                </v-tab-item>
              </v-tabs-items>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="search">
              Search
            </v-btn>
            <v-btn
              @click="cancel"
              class="ml-4">
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import TransportEventRangeSearchForm from "./SearchParamForms/TransportEventRangeSearchForm";
import TransportEventSingleSearchForm from "./SearchParamForms/TransportEventSingleSearchForm";
import LikumaRangeSearchForm from "./SearchParamForms/LikumaRangeSearchForm";
import LikumaSingleSearchForm from "./SearchParamForms/LikumaSingleSearchForm";
import KelaRangeSearchForm from "./SearchParamForms/KelaRangeSearchForm";
import KelaSingleSearchForm from "./SearchParamForms/KelaSingleSearchForm";
import _ from "lodash";
import moment from "moment";

export default {
  props: {
    show: Boolean,
    params: Object
  },
  components: {
    TransportEventRangeSearchForm,
    TransportEventSingleSearchForm,
    LikumaRangeSearchForm,
    LikumaSingleSearchForm,
    KelaRangeSearchForm,
    KelaSingleSearchForm
  },
  data () {
    return {
      searchParams: this.formatSearchParams(this.params),
      modelTabs: ["Likuma", "Kela", "Transport event"],
      modelTab: "Likuma",
      searchModeTabs: ["Search Range", "Search Single"],
      searchModeTab: "Search Range"
    };
  },
  watch: {
    params (val) {
      this.searchParams = this.formatSearchParams(val);
    }
  },
  methods: {
    search () {
      let form = this.getActiveForm();
      if (!form) return;
      let params = _.cloneDeep(form.getSearchParameters());
      if (params.modifiedFrom) {
        params.modifiedFrom = moment(params.modifiedFrom).format();
      }
      if (params.modifiedTo) {
        params.modifiedTo = moment(params.modifiedTo).format();
      }
      if (params.tripStartedFrom) {
        params.tripStartedFrom = moment(params.tripStartedFrom).format();
      }
      if (params.tripStartedTo) {
        params.tripStartedTo = moment(params.tripStartedTo).format();
      }
      if (params.tripEndedFrom) {
        params.tripEndedFrom = moment(params.tripEndedFrom).format();
      }
      if (params.tripEndedTo) {
        params.tripEndedTo = moment(params.tripEndedTo).format();
      }
      this.$emit("search", params);
    },
    cancel () {
      this.searchParams = this.formatSearchParams(this.params);
      this.$emit("close");
    },
    getActiveForm () {
      if (this.modelTab === this.modelTabs.indexOf("Likuma") && this.searchModeTab === this.searchModeTabs.indexOf("Search Single")) {
        return this.$refs.likumaSingleSearchForm;
      }
      if (this.modelTab === this.modelTabs.indexOf("Likuma") && this.searchModeTab === this.searchModeTabs.indexOf("Search Range")) {
        return this.$refs.likumaRangeSearchForm;
      }
      return null;
    },
    formatSearchParams (params) {
      let searchParams = params ? _.cloneDeep(params) : {};
      if (searchParams.modifiedFrom) {
        searchParams.modifiedFrom =
          moment(searchParams.modifiedFrom).format("YYYY-MM-DDTHH:mm");
      }
      if (searchParams.modifiedTo) {
        searchParams.modifiedTo =
          moment(searchParams.modifiedTo).format("YYYY-MM-DDTHH:mm");
      }
      if (searchParams.tripStartedFrom) {
        searchParams.tripStartedFrom =
          moment(searchParams.tripStartedFrom).format("YYYY-MM-DDTHH:mm");
      }
      if (searchParams.tripStartedTo) {
        searchParams.tripStartedTo =
          moment(searchParams.tripStartedTo).format("YYYY-MM-DDTHH:mm");
      }
      if (searchParams.tripEndedFrom) {
        searchParams.tripEndedFrom =
          moment(searchParams.tripEndedFrom).format("YYYY-MM-DDTHH:mm");
      }
      if (searchParams.tripEndedTo) {
        searchParams.tripEndedTo =
          moment(searchParams.tripEndedTo).format("YYYY-MM-DDTHH:mm");
      }
      return searchParams;
    }
  }

};
</script>

<style scoped>
.v-subheader {
  height: 1em;
  margin-top: 0.1em;
}
</style>
