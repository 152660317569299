var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "800" },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [
              _vm._v(
                " " + _vm._s(_vm.isNew ? "Add new rule" : "Edit rule") + " "
              )
            ])
          ]),
          _c(
            "v-card-text",
            { staticStyle: { color: "black" } },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: [
                                function(v) {
                                  return !!v || "Name is required"
                                },
                                function(v) {
                                  return (
                                    !v ||
                                    v.length < 100 ||
                                    "Name can not be longer than 100 characters"
                                  )
                                }
                              ],
                              counter: "100",
                              label: "Name"
                            },
                            model: {
                              value: _vm.rule.name,
                              callback: function($$v) {
                                _vm.$set(_vm.rule, "name", $$v)
                              },
                              expression: "rule.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-subheader",
                            {
                              staticStyle: {
                                color: "black",
                                "font-weight": "bold"
                              }
                            },
                            [_vm._v(" Conditions ")]
                          )
                        ],
                        1
                      ),
                      _vm._l(_vm.rule.conditions, function(condition, i) {
                        return _c(
                          "v-row",
                          {
                            key: "c" + i,
                            staticClass: "mb-2",
                            attrs: { align: "center" }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-1",
                                attrs: { color: "primary", small: "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.openConditionDialog(condition, i)
                                  }
                                }
                              },
                              [_vm._v(" mdi-pencil ")]
                            ),
                            _vm._v(
                              " " +
                                _vm._s(condition.value) +
                                " " +
                                _vm._s(
                                  _vm._f("comparatorToSymbol")(
                                    condition.comparator
                                  )
                                ) +
                                " " +
                                _vm._s(condition.target) +
                                " "
                            )
                          ],
                          1
                        )
                      }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "add-new-btn",
                              attrs: { text: "", color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.openNewConditionDialog()
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("mdi-plus")
                              ]),
                              _vm._v(" Add new condition ")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-subheader",
                            {
                              staticStyle: {
                                color: "black",
                                "font-weight": "bold"
                              }
                            },
                            [_vm._v(" Actions ")]
                          )
                        ],
                        1
                      ),
                      _vm._l(_vm.rule.actions, function(action, i) {
                        return _c(
                          "v-row",
                          {
                            key: "a" + i,
                            staticClass: "mb-2",
                            attrs: { align: "center" }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-1",
                                attrs: { color: "primary", small: "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.openActionDialog(
                                      _vm.condition,
                                      i
                                    )
                                  }
                                }
                              },
                              [_vm._v(" mdi-pencil ")]
                            ),
                            _vm._v(
                              " " + _vm._s(_vm._f("actionToText")(action)) + " "
                            )
                          ],
                          1
                        )
                      }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "add-new-btn",
                              attrs: { text: "", color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.openNewActionDialog()
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("mdi-plus")
                              ]),
                              _vm._v(" Add new action ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-4" },
            [
              _c("v-spacer"),
              _vm.isNew
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.valid,
                        loading: _vm.isUpdating,
                        color: "success"
                      },
                      on: { click: _vm.createRule }
                    },
                    [_vm._v(" Add rule ")]
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: { disabled: !_vm.valid, color: "primary" },
                      on: { click: _vm.saveRule }
                    },
                    [_vm._v(" Save changes ")]
                  ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      ),
      _c("ConditionDialog", {
        attrs: {
          isOpen: _vm.conditionDialog,
          originalCondition: _vm.selectedCondition,
          isNew: _vm.newCondition
        },
        on: {
          create: _vm.addCondition,
          save: _vm.modifySelectedCondition,
          close: _vm.closeConditionDialog
        }
      }),
      _c("ActionDialog", {
        attrs: {
          isOpen: _vm.actionDialog,
          originalAction: _vm.selectedAction,
          isNew: _vm.newAction
        },
        on: {
          create: _vm.addAction,
          save: _vm.modifySelectedAction,
          close: _vm.closeActionDialog
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }