<template>
	<v-container fluid>
    <h1>Home</h1>
    <v-row>
      <v-col cols="12" md="8">
        <v-card
          class="mx-auto"
          height="500"
        >
          <v-card-title class="font-weight-bold">
            # of trips
          </v-card-title>
          <v-skeleton-loader
            :loading="numberOfTripsLoading"
            :transition-group="'scale-transition'"
            type="list-item@7">
            <BarChart
              :data="tripsByDayData"
              :options="tripsByDayDataOptions"
            ></BarChart>
          </v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card
          class="mx-auto"
          height="500"
        >
          <v-card-title class="font-weight-bold">
            <v-row class="mx-0" align="center">
              Statuses of trips driven in last {{ tripStatusesFromDays }} days
              <v-spacer></v-spacer>
              <v-btn-toggle
                v-model="tripStatusesFromDays"
                mandatory
                dense
                rounded>
                <v-btn value="30" small>
                  30
                </v-btn>
                <v-btn value="60" small>
                  60
                </v-btn>
                <v-btn value="90" small>
                  90
                </v-btn>
              </v-btn-toggle>
            </v-row>
          </v-card-title>
          <v-skeleton-loader
            :loading="tripStatusesLoading"
            :transition-group="'scale-transition'"
            type="list-item@5">
            <DoughnutChart
              :data="tripsByTimeRangeData"
              :options="tripsByTimeRangeDataOptions"
            ></DoughnutChart>
          </v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
			<v-col cols=12 md=4>
        <v-card color="error lighten-1">
				<v-card-title class="font-weight-bold">Transport event errors</v-card-title>
          <v-skeleton-loader :loading="failedValidationsLoading" :transition-group="'scale-transition'" type="list-item@3">
            <v-simple-table height="400px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Error</th>
                    <th class="text-left">Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="failReason in failReasons"
                    :key="failReason.item1"
                    @click="searchFailed(failReason.item1)"
                    class="clickable"
                  >
                    <td>{{ failReason.item1 | validationFailReasonToText }}</td>
                    <td class="error--text font-weight-black">{{ failReason.item2 }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-skeleton-loader>
        </v-card>
			</v-col>
      <v-col cols=12 md=4>
        <v-card color="primary lighten-1">
				<v-card-title class="font-weight-bold">Latest sales invoices</v-card-title>
				<v-skeleton-loader :loading="salesInvoicesLoading" :transition-group="'scale-transition'" type="list-item@3">
          <v-simple-table height="400px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Invoice number</th>
                  <th class="text-left">Events</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="salesInvoice in salesInvoices"
                  :key="salesInvoice.item1"
                  @click="searchSalesInvoice(salesInvoice.item1)"
                  class="clickable"
                >
                  <td>{{ salesInvoice.item1 }}</td>
                  <td>{{ salesInvoice.item2 }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-skeleton-loader>
        </v-card>
			</v-col>
      <v-col cols=12 md=4>
        <v-card color="primary lighten-1">
				<v-card-title class="font-weight-bold">Latest purchase invoices</v-card-title>
				<v-skeleton-loader :loading="purchaseInvoicesLoading" :transition-group="'scale-transition'" type="list-item@3">
          <v-simple-table height="400px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Invoice number</th>
                  <th class="text-left">Events</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="purchaseInvoice in purchaseInvoices"
                  :key="purchaseInvoice.item1"
                  @click="searchPurchaseInvoice(purchaseInvoice.item1)"
                  class="clickable"
                >
                  <td>{{ purchaseInvoice.item1 }}</td>
                  <td>{{ purchaseInvoice.item2 }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-skeleton-loader>
        </v-card>
			</v-col>
		</v-row>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
	</v-container>
</template>

<script>
import { TranportEventValidationErrorMixin } from "@/models/ValidationErrors";
import BarChart from "@/components/Charts/BarChart";
import DoughnutChart from "@/components/Charts/DoughnutChart";
import moment from "moment";

export default {
  mixins: [ TranportEventValidationErrorMixin ],
  components: { BarChart, DoughnutChart },
  data () {
    return {
      numberOfTripsLoading: true,
      tripStatusesLoading: true,
      failedValidationsLoading: true,
      salesInvoicesLoading: true,
      purchaseInvoicesLoading: true,
      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
      tripStatusesFromDays: 30,
      tripsByDayDataOptions: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        },
        tooltips: {
          callbacks: {
            afterLabel: function (tooltipItem, data) {
              return "Total charge: " + data["datasets"][tooltipItem["datasetIndex"]]["charges"][tooltipItem["index"]] + " €";
            }
          }
        }
      },
      tripsByTimeRangeDataOptions: {
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            afterLabel: function (tooltipItem, data) {
              return "Total charge: " + data["datasets"][0]["charges"][tooltipItem["index"]] + " €";
            }
          }
        }
      }
    };
  },
  computed: {
    failReasons () {
      return this.$store.state.invoicing.failReasons;
    },
    salesInvoices () {
      return this.$store.state.invoicing.salesInvoices;
    },
    purchaseInvoices () {
      return this.$store.state.invoicing.purchaseInvoices;
    },
    tripsByDayData () {
      let statuses = this.$store.state.invoicing.tripStatusesByDay;
      if (!statuses) return;
      let data = {
        labels: statuses.map(s => moment(s.item1).format("DD.MM.")),
        datasets: [
          {
            label: "Valid",
            backgroundColor: "#5588D2",
            data: statuses.map(s => s.item2.filter(e => e.item1 > 2).map(e => e.item2).reduce((a, b) => a + b, 0)),
            charges: statuses.map(s => s.item2.filter(e => e.item1 > 2).map(e => e.item3).reduce((a, b) => a + b, 0))
          },
          {
            label: "Incomplete",
            backgroundColor: "#D3D3D3",
            data: statuses.map(s => s.item2.filter(e => e.item1 === 1).map(e => e.item2).reduce((a, b) => a + b, 0)),
            charges: statuses.map(s => s.item2.filter(e => e.item1 === 1).map(e => e.item3).reduce((a, b) => a + b, 0))
          },
          {
            label: "Validation failed",
            backgroundColor: "#FF6F6A",
            data: statuses.map(s => s.item2.filter(e => e.item1 === 2).map(e => e.item2).reduce((a, b) => a + b, 0)),
            charges: statuses.map(s => s.item2.filter(e => e.item1 === 2).map(e => e.item3).reduce((a, b) => a + b, 0))
          }
        ]
      };
      return data;
    },
    tripsByTimeRangeData () {
      let statuses = this.$store.state.invoicing.tripStatusesByTimeRange;
      if (!statuses) return;
      let data = {
        labels: ["Incomplete", "Validation failed", "Waiting for invoicing", "Sales invoice sent", "Purchase invoice sent"],
        datasets: [{
          data: statuses.map(s => s.item2),
          backgroundColor: [ "#D3D3D3", "#FF6F6A", "#5588D2", "#53c4d1", "#85D153" ],
          charges: statuses.map(s => s.item3)
        }]
      };
      return data;
    }
  },
  watch: {
    tripStatusesFromDays: function (val) {
      this.getTransportEventStatuses(val);
    }
  },
  methods: {
    async getFailReasons () {
      this.failedValidationsLoading = true;
      this.$store.dispatch("invoicing/getFailReasons")
        .catch(error => {
          this.snackbar = true;
          this.snackbarColor = "error";
          this.snackbarText = "Getting validation errors failed. Error: " + error;
        }).finally(() => {
          this.failedValidationsLoading = false;
        });
    },
    async getSalesInvoices () {
      this.salesInvoicesLoading = true;
      this.$store.dispatch("invoicing/getSalesInvoices")
        .catch(error => {
          this.snackbar = true;
          this.snackbarColor = "error";
          this.snackbarText = "Getting sales invoices failed. Error: " + error;
        }).finally(() => {
          this.salesInvoicesLoading = false;
        });
    },
    async getPurchaseInvoices () {
      this.purchaseInvoicesLoading = true;
      this.$store.dispatch("invoicing/getPurchaseInvoices")
        .catch(error => {
          this.snackbar = true;
          this.snackbarColor = "error";
          this.snackbarText = "Getting purchase invoices failed. Error: " + error;
        }).finally(() => {
          this.purchaseInvoicesLoading = false;
        });
    },
    async getTransportEventStatuses (days = 30) {
      this.tripStatusesLoading = true;
      let maxDate = moment();
      let minDate = moment(maxDate).subtract(days, "days");
      this.$store.dispatch("invoicing/getTripStatusesByTimeRange", { start: minDate.format("YYYY-MM-DDThh:mm"), end: maxDate.format("YYYY-MM-DDThh:mm") })
        .catch(error => {
          this.snackbar = true;
          this.snackbarColor = "error";
          this.snackbarText = "Getting trip statuses by day failed. Error: " + error;
        }).finally(() => {
          this.tripStatusesLoading = false;
        });
    },
    async getTrips () {
      this.numberOfTripsLoading = true;
      let maxDate = moment();
      let minDate = moment(maxDate).subtract(7, "days");
      this.$store.dispatch("invoicing/getTripStatusesByDay", { start: minDate.format("YYYY-MM-DDT00:00:00"), end: maxDate.format("YYYY-MM-DDT23:59:59") })
        .catch(error => {
          this.snackbar = true;
          this.snackbarColor = "error";
          this.snackbarText = "Getting trip statuses by day failed. Error: " + error;
        }).finally(() => {
          this.numberOfTripsLoading = false;
        });
    },
    searchFailed (id) {
      this.$router.push({ name: "Invoicing", query: { validationStates: [4, 6], validationFailReasonCodes: id } });
    },
    searchSalesInvoice (id) {
      this.$router.push({ name: "Invoicing", query: { salesInvoiceNumbers: id } });
    },
    searchPurchaseInvoice (id) {
      this.$router.push({ name: "Invoicing", query: { purchaseInvoiceNumbers: id } });
    }
  },
  created () {
    this.getFailReasons();
    this.getSalesInvoices();
    this.getPurchaseInvoices();
    this.getTrips();
    this.getTransportEventStatuses();
  }
};
</script>

<style scoped>
.clickable:hover {
  cursor:pointer;
}
</style>
