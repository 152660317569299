var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "800" },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [
              _vm._v(
                " " + _vm._s(_vm.isNew ? "Add new action" : "Edit action") + " "
              )
            ])
          ]),
          _c(
            "v-card-text",
            { staticStyle: { color: "black" } },
            [
              _c(
                "v-form",
                {
                  ref: "actionForm",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-select", {
                                attrs: {
                                  items: Object.values(_vm.actionTypes).filter(
                                    function(a) {
                                      return a !== 0
                                    }
                                  ),
                                  label: "Action type",
                                  rules: [_vm.requiredRule],
                                  required: ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("actionTypeToText")(item)
                                            ) +
                                            " "
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("actionTypeToText")(item)
                                            ) +
                                            " "
                                        )
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.action.type,
                                  callback: function($$v) {
                                    _vm.$set(_vm.action, "type", $$v)
                                  },
                                  expression: "action.type"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.action.type === 1
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: Object.values(
                                        _vm.transportEventConditionProperties
                                      ),
                                      label: "Property",
                                      rules: [_vm.requiredRule],
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.action.property,
                                      callback: function($$v) {
                                        _vm.$set(_vm.action, "property", $$v)
                                      },
                                      expression: "action.property"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Value",
                                      rules: [_vm.requiredRule],
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.action.value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.action, "value", $$v)
                                      },
                                      expression: "action.value"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm.action.type === 3
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Validation fail reason code",
                                      rules: [_vm.requiredRule],
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.action.value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.action, "value", $$v)
                                      },
                                      expression: "action.value"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-4" },
            [
              _c("v-spacer"),
              _vm.isNew
                ? _c(
                    "v-btn",
                    {
                      attrs: { disabled: !_vm.valid, color: "success" },
                      on: { click: _vm.createAction }
                    },
                    [_vm._v(" Add action ")]
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: { disabled: !_vm.valid, color: "primary" },
                      on: { click: _vm.saveAction }
                    },
                    [_vm._v(" Save changes ")]
                  ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }