import axios from "axios";
import { Util } from "@microsoft/applicationinsights-web";
import AuthService from "../../msal";

const validationUrl = process.env.VUE_APP_VALIDATIONSERVICE_URL;
const pricingUrl = process.env.VUE_APP_PRICINGSERVICE_URL;
const paymentUrl = process.env.VUE_APP_PAYMENTSERVICE_URL;
const invoicingUrl = process.env.VUE_APP_INVOICESERVICE_URL;
const geoUrl = process.env.VUE_APP_GEOSERVICE_URL;

const authService = new AuthService();

const apiKey = process.env.VUE_APP_APIM_KEY;

async function getAccessToken () {
  const token = await authService.getToken(authService.applicationConfig.backendScopes);
  return token.accessToken;
}

let defaultConfig = async (config) => {
  config.headers["Request-Id"] = Util.generateW3CId();
  config.headers.Authorization = `Bearer ${await getAccessToken()}`;
  config.headers["apikey"] = apiKey;
  return config;
};

let defaultErrorHandler = (error) => {
  return Promise.reject(error);
};

var validationService = axios.create({ baseURL: validationUrl });
validationService.interceptors.request.use(defaultConfig, defaultErrorHandler);

var pricingService = axios.create({ baseURL: pricingUrl });
pricingService.interceptors.request.use(defaultConfig, defaultErrorHandler);

var paymentService = axios.create({ baseURL: paymentUrl });
paymentService.interceptors.request.use(defaultConfig, defaultErrorHandler);

var invoicingService = axios.create({ baseURL: invoicingUrl });
invoicingService.interceptors.request.use(defaultConfig, defaultErrorHandler);

var geoService = axios.create({ baseURL: geoUrl });
geoService.interceptors.request.use(defaultConfig, defaultErrorHandler);

export { validationService, pricingService, paymentService, invoicingService, geoService };
