export const TransportEventSalesInvoiceStates = {
  Unknown: 0,
  Unprocessed: 1,
  NotToBeInvoiced: 2,
  Accepted: 3,
  Denied: 4,
  Waiting: 5,
  Completed: 6
};

export const TransportEventPurchaseInvoiceStates = {
  Unknown: 0,
  Unprocessed: 1,
  NotToBeInvoiced: 2,
  Accepted: 3,
  Denied: 4,
  Waiting: 5,
  Completed: 6
};

export const TransportEventInvoicingMixin = {
  data () {
    return {
      salesInvoiceStates: TransportEventSalesInvoiceStates,
      purchaseInvoiceStates: TransportEventPurchaseInvoiceStates
    };
  },
  filters: {
    salesInvoiceStateToText: function (value) {
      switch (value) {
      case TransportEventSalesInvoiceStates.Unknown:
        return "Not determined";
      case TransportEventSalesInvoiceStates.Unprocessed:
        return "Not invoiced yet";
      case TransportEventSalesInvoiceStates.NotToBeInvoiced:
        return "Not to be invoiced";
      case TransportEventSalesInvoiceStates.Accepted:
        return "Accepted to invoicing";
      case TransportEventSalesInvoiceStates.Denied:
        return "Invoicing denied";
      case TransportEventSalesInvoiceStates.Waiting:
        return "Invoicing in process";
      case TransportEventSalesInvoiceStates.Completed:
        return "Invoicing completed";
      };
    },
    purchaseInvoiceStateToText: function (value) {
      switch (value) {
      case TransportEventPurchaseInvoiceStates.Unknown:
        return "Not determined";
      case TransportEventPurchaseInvoiceStates.Unprocessed:
        return "Not invoiced yet";
      case TransportEventPurchaseInvoiceStates.NotToBeInvoiced:
        return "Not to be invoiced";
      case TransportEventPurchaseInvoiceStates.Accepted:
        return "Accepted to invoicing";
      case TransportEventPurchaseInvoiceStates.Denied:
        return "Invoicing denied";
      case TransportEventPurchaseInvoiceStates.Waiting:
        return "Invoicing in process";
      case TransportEventPurchaseInvoiceStates.Completed:
        return "Invoicing completed";
      };
    }
  }
};
