import { paymentService } from "./repository";

const resource = "/management";

export default {
  async findCustomers () {
    return paymentService.get(`${resource}/customers`);
  },
  async getCustomer (customerId) {
    return paymentService.get(`${resource}/customers/${customerId}`);
  },
  async getUsersByCustomerId (customerId) {
    return paymentService.get(`${resource}/customers/${customerId}/users`);
  },
  async createUser (payload) {
    return paymentService.post(`${resource}/users`, payload);
  },
  async createCustomer (payload) {
    return paymentService.post(`${resource}/customers`, payload);
  }
};
