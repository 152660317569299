<template>
  <v-container fluid>
    <h1>Invoicing Rules</h1>
    <v-skeleton-loader
      :loading="loading"
      :transition-group="'scale-transition'"
      type="table">
      <InvoicingRulePanels
        ref="panels"
        :rules="invoicingRules"
        @update="updateInvoicingRules"
      ></InvoicingRulePanels>
    </v-skeleton-loader>
  </v-container>
</template>

<script>

import InvoicingRulePanels from "@/components/InvoicingRules/InvoicingRulePanels";

export default {
  components: {
    InvoicingRulePanels
  },
  data () {
    return {
      loading: false,
      invoicingRules: [{
        name: "Summa liian iso",
        conditions: [
          { value: "EventType", comparator: 1, target: "Likuma" },
          { value: "WelfareTripType", comparator: 1, target: "SHL" },
          { value: "TotalCharge", comparator: 3, target: "30" }
        ],
        actions: [
          { type: 1, property: "TotalCharge", value: "30" },
          { type: 1, property: "Cost", value: "{{TotalCharge + Deductible}}" },
          { type: 2 },
          { type: 1, property: "PurchaseInvoiceMessage", value: "{{PurchaseInvoiceMessage}} + ' SHL-matkojen suurin sallittu korvaus on 30 €.'" }
        ]
      },
      {
        name: "Liian suuri omavastuu VPL",
        conditions: [
          { value: "EventType", comparator: 1, target: "Likuma" },
          { value: "WelfareTripType", comparator: 1, target: "VPL" },
          { value: "TotalDeductible", comparator: 3, target: "3.20" }
        ],
        actions: [
          { type: 3, value: "Veloitettu omavastuu liian suuri" }
        ]
      },
      {
        name: "Asiakkaalla ei ole oikeutta invalisään",
        conditions: [
          { value: "EventType", comparator: 1, target: "Likuma" },
          { value: "ValidationFailReasonCode", comparator: 1, target: "I" }
        ],
        actions: [
          { type: 1, property: "Cost", value: "{{Cost - HandicapFee}}" },
          { type: 1, property: "TotalCharge", value: "{{TotalCharge - HandicapFee}}" },
          { type: 1, property: "PurchaseInvoiceMessage", value: "{{PurchaseInvoiceMessage}} Asiakkaalla ei ollut oikeutta invalisään, invalisä suuruudeltaan {{HandicapFee}} € on poistettu tilityksestä." },
          { type: 1, property: "HandicapFee", value: "0" },
          { type: 2 }
        ]
      },
      {
        name: "Invalisä liian suuri",
        conditions: [
          { value: "EventType", comparator: 1, target: "Likuma" },
          { value: "HandicapFee", comparator: 3, target: "15.70" }
        ],
        actions: [
          { type: 1, property: "Cost", value: "{{Cost - (HandicapFee - 15.70)}}" },
          { type: 1, property: "TotalCharge", value: "{{TotalCharge - (HandicapFee - 15.70)}}" },
          { type: 1, property: "PurchaseInvoiceMessage", value: "{{PurchaseInvoiceMessage}} Invalisä {HandicapFee} on suurempi kuin suurin sallittu invalisä 15,70 €. Ylimenevä osuus on vähennetty tilityksestä." },
          { type: 1, property: "HandicapFee", value: "15.70" },
          { type: 2 }
        ]
      },
      {
        name: "ALV tuntematon",
        conditions: [
          { value: "VatPercent", comparator: 3, target: "24" }
        ],
        actions: [
          { type: 3, value: "ALV tuntematon" }
        ]
      }]
    };
  },
  /*
  computed: {
    invoicingRules () {
     return this.$store.state.invoicingRules.invoicingRules;
    }
  }
  */
  methods: {
    updateInvoicingRules (rules) {
      this.invoicingRules = rules;
      this.$refs.panels.closeRuleDialog();
    }
  }
};
</script>

<style scoped>
h1 {
    margin-bottom: 1rem;
}
</style>
