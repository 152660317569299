var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "550px" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c("v-btn", _vm._g({ attrs: { color: "primary" } }, on), [
                _vm._v("New Validation")
              ])
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [
              _vm._v("Create new Validation")
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                [
                  _c("v-text-field", {
                    attrs: {
                      placeholder: "Location validation",
                      disabled: _vm.validated,
                      outlined: "",
                      required: "",
                      label: "Name"
                    },
                    model: {
                      value: _vm.name,
                      callback: function($$v) {
                        _vm.name = $$v
                      },
                      expression: "name"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: "taksipartners",
                      disabled: _vm.validated,
                      outlined: "",
                      required: "",
                      label: "Operator"
                    },
                    model: {
                      value: _vm.operator,
                      callback: function($$v) {
                        _vm.operator = $$v
                      },
                      expression: "operator"
                    }
                  }),
                  _c(
                    "v-radio-group",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function() {
                            return [_c("h3", [_vm._v("Validation status:")])]
                          },
                          proxy: true
                        }
                      ]),
                      model: {
                        value: _vm.status,
                        callback: function($$v) {
                          _vm.status = $$v
                        },
                        expression: "status"
                      }
                    },
                    [
                      _c("v-radio", {
                        attrs: { value: "experimental", color: "purple" },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c("div", [
                                  _c(
                                    "strong",
                                    { staticClass: "purple--text" },
                                    [_vm._v("Experiment")]
                                  )
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      }),
                      _c("v-radio", {
                        attrs: { value: "active", color: "success" },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c("div", [
                                  _c(
                                    "strong",
                                    { staticClass: "success--text" },
                                    [_vm._v("Active")]
                                  )
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      }),
                      _c("v-radio", {
                        attrs: { value: "inactive", color: "warning" },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c("div", [
                                  _c(
                                    "strong",
                                    { staticClass: "warning--text" },
                                    [_vm._v("Inactive")]
                                  )
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-radio-group",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function() {
                            return [_c("h3", [_vm._v("Validation type:")])]
                          },
                          proxy: true
                        }
                      ]),
                      model: {
                        value: _vm.type,
                        callback: function($$v) {
                          _vm.type = $$v
                        },
                        expression: "type"
                      }
                    },
                    [
                      _c("v-radio", {
                        attrs: { value: "allow", color: "success" },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c("div", [
                                  _c(
                                    "strong",
                                    { staticClass: "success--text" },
                                    [_vm._v("Allow")]
                                  )
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      }),
                      _c("v-radio", {
                        attrs: { value: "block", color: "error" },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c("div", [
                                  _c("strong", { staticClass: "error--text" }, [
                                    _vm._v("Block")
                                  ])
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _c("h2", [_vm._v("Valid through:")]),
                  _c(
                    "div",
                    { staticClass: "pa-5" },
                    [
                      _c("v-date-picker", {
                        attrs: {
                          "full-width": "",
                          disabled: _vm.validated,
                          required: "",
                          range: ""
                        },
                        model: {
                          value: _vm.dates,
                          callback: function($$v) {
                            _vm.dates = $$v
                          },
                          expression: "dates"
                        }
                      }),
                      _vm.dates.length > 1
                        ? _c("h2", { staticClass: "pt-2" }, [
                            _vm._v("Validation will be valid through:")
                          ])
                        : _vm._e(),
                      _c("h3", { staticClass: "primary--text" }, [
                        _vm._v(_vm._s(_vm.validThroughText))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: {
                        disabled: this.$v.$invalid || this.validated,
                        color: "success"
                      },
                      on: { click: _vm.validate }
                    },
                    [_vm._v("Validate / Confirm")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "red",
                        disabled: !this.validated,
                        text: ""
                      },
                      on: { click: _vm.cancel }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm.validated
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: { color: "primary" },
                          on: { click: _vm.create }
                        },
                        [_vm._v("Create")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }