<template>
  <v-dialog v-model="isOpen" persistent max-width="800">
    <v-card>
      <v-card-title>
        <span class="headline">Join Fleet</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="fleetId" label="Fleet Id" placeholder="Name"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn @click="joinFleet" :loading="isUpdating" color="success">Join</v-btn>
        <v-btn @click="close" :loading="isUpdating">Close</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    isUpdating: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean,
      default: false
    } },
  data () {
    return {
      fleetId: ""
    };
  },
  methods: {
    joinFleet () {
      this.$emit("joinFleet", this.fleetId);
      this.$emit("close");
    },
    close () {
      this.$emit("close");
    }
  }
};
</script>

<style>
.v-subheader {
  padding: 0;
  font-size: 1.2em;
}
</style>
