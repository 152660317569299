var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600px" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c("v-btn", _vm._g({ attrs: { color: "primary" } }, on), [
                _vm._v("Add Customer")
              ])
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [
              _vm._v("Add new Customer")
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                [
                  _c("v-text-field", {
                    attrs: {
                      "error-messages": _vm.nameErrors,
                      disabled: _vm.validated,
                      outlined: "",
                      required: "",
                      label: "Name"
                    },
                    model: {
                      value: _vm.$v.name.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.name, "$model", $$v)
                      },
                      expression: "$v.name.$model"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      disabled: _vm.validated,
                      outlined: "",
                      required: "",
                      placeholder: "Matti Meikäläinen",
                      label: "Contact"
                    },
                    model: {
                      value: _vm.$v.contact.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.contact, "$model", $$v)
                      },
                      expression: "$v.contact.$model"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      "error-messages": _vm.phoneErrors,
                      disabled: _vm.validated,
                      outlined: "",
                      required: "",
                      placeholder: "358501234567",
                      label: "ContactPhone"
                    },
                    model: {
                      value: _vm.$v.contactPhone.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.contactPhone, "$model", $$v)
                      },
                      expression: "$v.contactPhone.$model"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      "error-messages": _vm.emailErrors,
                      disabled: _vm.validated,
                      outlined: "",
                      required: "",
                      placeholder: "matti@gmail.com",
                      label: "ContactEmail"
                    },
                    model: {
                      value: _vm.$v.contactEmail.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.contactEmail, "$model", $$v)
                      },
                      expression: "$v.contactEmail.$model"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      disabled: _vm.validated,
                      outlined: "",
                      required: "",
                      placeholder: "taksipartners",
                      label: "Operator"
                    },
                    model: {
                      value: _vm.$v.operator.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.operator, "$model", $$v)
                      },
                      expression: "$v.operator.$model"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      disabled: _vm.validated,
                      outlined: "",
                      required: "",
                      placeholder: "50012345",
                      label: "Identifier"
                    },
                    model: {
                      value: _vm.$v.identifier.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.identifier, "$model", $$v)
                      },
                      expression: "$v.identifier.$model"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: {
                        disabled: this.$v.$invalid || this.validated,
                        color: "success"
                      },
                      on: { click: _vm.validate }
                    },
                    [_vm._v("Validate / Confirm")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "red",
                        disabled: !this.validated,
                        text: ""
                      },
                      on: { click: _vm.cancel }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm.validated
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: { color: "primary" },
                          on: { click: _vm.create }
                        },
                        [_vm._v("Create")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }