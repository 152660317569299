var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "div",
        { staticClass: "elevation-1 mb-1 pb-2" },
        [
          _c(
            "v-card-title",
            [
              _vm._v(" Transport Events "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-3",
                  attrs: { small: "", color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.openTransportEventDialog(null)
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-plus")]),
                  _vm._v(" Add new ")
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { small: "" },
                  on: {
                    click: function($event) {
                      _vm.searchDialog = !_vm.searchDialog
                    }
                  }
                },
                [_vm._v(" Change search criteria ")]
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "1000" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              { staticClass: "ml-2", attrs: { small: "" } },
                              on
                            ),
                            [_vm._v(" Toggle columns ")]
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.columnDialog,
                    callback: function($$v) {
                      _vm.columnDialog = $$v
                    },
                    expression: "columnDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { short: "", dark: "", color: "primary" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  _vm.columnDialog = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                          _c("v-toolbar-title", [
                            _vm._v(" Select showable columns ")
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", dark: "" },
                              on: { click: _vm.hideAllColumns }
                            },
                            [_vm._v(" Hide all columns ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", dark: "" },
                              on: { click: _vm.showAllColumns }
                            },
                            [_vm._v(" Show all columns ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        {
                          staticClass: "mx-3",
                          attrs: { wrap: "", "align-center": "" }
                        },
                        _vm._l(_vm.headers, function(header, n) {
                          return _c(
                            "v-flex",
                            { key: n, attrs: { xs6: "", md3: "" } },
                            [
                              _c("v-switch", {
                                attrs: { label: header.text },
                                model: {
                                  value: header.show,
                                  callback: function($$v) {
                                    _vm.$set(header, "show", $$v)
                                  },
                                  expression: "header.show"
                                }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-spacer"),
              _c("v-text-field", {
                staticClass: "pt-0 mt-0 mr-2",
                attrs: {
                  "append-icon": "mdi-magnify",
                  label: "Filter",
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.filter,
                  callback: function($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-skeleton-loader",
        {
          attrs: {
            loading: _vm.loading,
            "transition-group": "scale-transition",
            type: "table"
          }
        },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.showableHeaders,
              items: _vm.transportEvents,
              "item-key": "orderId",
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              search: _vm.filter,
              loading: _vm.loadingPage,
              page: _vm.page,
              "items-per-page": _vm.itemsPerPage,
              "footer-props": {
                pagination: _vm.pagination,
                itemsPerPageOptions: _vm.itemsPerPageOptions
              }
            },
            on: {
              "update:page": function($event) {
                _vm.page = $event
              },
              "update:itemsPerPage": function($event) {
                _vm.itemsPerPage = $event
              },
              "update:items-per-page": function($event) {
                _vm.itemsPerPage = $event
              },
              "click:row": _vm.openTransportEventDialog,
              "update:options": _vm.loadMoreTransportEvents
            },
            scopedSlots: _vm._u([
              {
                key: "item.trip.started",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm._f("formatDateTime")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.trip.ended",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm._f("formatDateTime")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.modified",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatDateTime")(
                              _vm._f("formatToLocalTime")(value)
                            )
                          )
                      )
                    ])
                  ]
                }
              },
              {
                key: "item.sentToExternal",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatDateTime")(
                              _vm._f("formatToLocalTime")(value)
                            )
                          )
                      )
                    ])
                  ]
                }
              },
              {
                key: "item.confirmationFromExternal",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatDateTime")(
                              _vm._f("formatToLocalTime")(value)
                            )
                          )
                      )
                    ])
                  ]
                }
              },
              {
                key: "item.fare.cost",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm._f("formatPrice")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.validationStatus",
                fn: function(ref) {
                  var value = ref.value
                  var item = ref.item
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        attrs: {
                                          color: _vm._f(
                                            "validationStatusToColor"
                                          )(value)
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("validationStatusToIcon")(
                                            value
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("validationStatusToText")(value))
                          )
                        ]),
                        item.validationFailReason
                          ? _c("span", [
                              _c("br"),
                              _vm._v(
                                "Validation fail reason: " +
                                  _vm._s(item.validationFailReason)
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ]
                }
              },
              {
                key: "item.eventStatus",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        attrs: {
                                          color: _vm._f(
                                            "transportStatusToColor"
                                          )(value)
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("transportStatusToIcon")(value)
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("transportStatusToText")(value)))
                        ])
                      ]
                    )
                  ]
                }
              },
              {
                key: "item.invoicingStatus",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        attrs: {
                                          color: _vm._f("statusToColor")(value)
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        _vm._s(_vm._f("statusToIcon")(value))
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("statusToToolTipText")(value)))
                        ])
                      ]
                    )
                  ]
                }
              },
              {
                key: "item.settlementStatus",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        attrs: {
                                          color: _vm._f("statusToColor")(value)
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        _vm._s(_vm._f("statusToIcon")(value))
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("statusToToolTipText")(value)))
                        ])
                      ]
                    )
                  ]
                }
              },
              {
                key: "item.fare.extras",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c("span", _vm._g({}, on), [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("formatPrice")(
                                            !value
                                              ? 0
                                              : value.reduce(
                                                  function(a, b) {
                                                    return {
                                                      price: a.price + b.price
                                                    }
                                                  },
                                                  { price: 0.0 }
                                                ).price
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("ul", { staticClass: "extras-list" }, [
                          _c("li", [
                            _vm._v("Pre-booking Extra: "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatPrice")(
                                    !value
                                      ? 0
                                      : value
                                          .filter(function(extra) {
                                            return (
                                              extra.type ===
                                              _vm.feeTypes.PrebookFee
                                            )
                                          })
                                          .reduce(
                                            function(a, b) {
                                              return {
                                                price: a.price + b.price
                                              }
                                            },
                                            { price: 0.0 }
                                          ).price
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("li", [
                            _vm._v("Goods Extra: "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatPrice")(
                                    !value
                                      ? 0
                                      : value
                                          .filter(function(extra) {
                                            return (
                                              extra.type ===
                                              _vm.feeTypes.GoodsFee
                                            )
                                          })
                                          .reduce(
                                            function(a, b) {
                                              return {
                                                price: a.price + b.price
                                              }
                                            },
                                            { price: 0.0 }
                                          ).price
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("li", [
                            _vm._v("Airport Extra: "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatPrice")(
                                    !value
                                      ? 0
                                      : value
                                          .filter(function(extra) {
                                            return (
                                              extra.type ===
                                              _vm.feeTypes.AirportFee
                                            )
                                          })
                                          .reduce(
                                            function(a, b) {
                                              return {
                                                price: a.price + b.price
                                              }
                                            },
                                            { price: 0.0 }
                                          ).price
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("li", [
                            _vm._v("Service Extra: "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatPrice")(
                                    !value
                                      ? 0
                                      : value
                                          .filter(function(extra) {
                                            return (
                                              extra.type ===
                                              _vm.feeTypes.ServiceFee
                                            )
                                          })
                                          .reduce(
                                            function(a, b) {
                                              return {
                                                price: a.price + b.price
                                              }
                                            },
                                            { price: 0.0 }
                                          ).price
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("li", [
                            _vm._v("Aid Extra: "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatPrice")(
                                    !value
                                      ? 0
                                      : value
                                          .filter(function(extra) {
                                            return (
                                              extra.type === _vm.feeTypes.AidFee
                                            )
                                          })
                                          .reduce(
                                            function(a, b) {
                                              return {
                                                price: a.price + b.price
                                              }
                                            },
                                            { price: 0.0 }
                                          ).price
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("li", [
                            _vm._v("Handicap Extra: "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatPrice")(
                                    !value
                                      ? 0
                                      : value
                                          .filter(function(extra) {
                                            return (
                                              extra.type ===
                                              _vm.feeTypes.HandicapFee
                                            )
                                          })
                                          .reduce(
                                            function(a, b) {
                                              return {
                                                price: a.price + b.price
                                              }
                                            },
                                            { price: 0.0 }
                                          ).price
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("li", [
                            _vm._v("Other Extra: "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatPrice")(
                                    !value
                                      ? 0
                                      : value
                                          .filter(function(extra) {
                                            return (
                                              extra.type ===
                                              _vm.feeTypes.Unknown
                                            )
                                          })
                                          .reduce(
                                            function(a, b) {
                                              return {
                                                price: a.price + b.price
                                              }
                                            },
                                            { price: 0.0 }
                                          ).price
                                  )
                                )
                              )
                            ])
                          ])
                        ])
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("TransportEventSearchDialog", {
        attrs: { show: _vm.searchDialog, params: _vm.searchParams },
        on: {
          search: _vm.searchTransportEvents,
          close: function($event) {
            _vm.searchDialog = false
          }
        }
      }),
      _c("TransportEventDialog", {
        attrs: {
          isOpen: _vm.transportEventDialog,
          value: _vm.selectedTransportEvent
        },
        on: {
          save: _vm.transportEventSaved,
          delete: _vm.transportEventDeleted,
          close: _vm.closeTransportEventDialog
        }
      }),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbarColor },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function($event) {
                  _vm.snackbar = false
                }
              }
            },
            [_vm._v(" Close ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }