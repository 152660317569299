<template>
    <v-container fluid fill-height >
        <v-row>
          <v-btn class="ma-2" color="primary" @click="showJoinFleet = true">Join Fleet Group</v-btn>
          <v-btn class="ma-2" color="primary" @click="showJoinVehicleLog = true">Join Vehicle Log</v-btn>
          <v-btn class="ma-2" color="error" @click="vehicles = []">Clear vehicles</v-btn>
          <!-- <v-btn class="ma-2" color="primary" @click="showZones = !showZones">Toggle zones</v-btn>
          <v-btn class="ma-2" color="primary" @click="showStations = !showStations">Toggle stations</v-btn> -->
        </v-row>
        <JoinFleetDialog
          @joinFleet="joinFleet"
          :isOpen="showJoinFleet"
          @close="showJoinFleet = false"></JoinFleetDialog>
          <JoinVehicleLogDialog
          @joinVehicleLog="joinVehicleLog"
          :isOpen="showJoinVehicleLog"
          @close="showJoinVehicleLog = false"></JoinVehicleLogDialog>
        <v-row style="min-height: 600px">
          <v-col cols="8">
            <MapBox
              :center="mapCenter"
              :vehicles="vehicles ? vehicles : []"
              :showZones="showZones"
              :showStations="showStations"
              @vehicleClicked="onVehicleClicked"
              >
            </MapBox>
          </v-col>
          <v-col cols="4">
            <v-tabs
            fixed-tabs
            background-color="indigo"
            dark
            >
              <v-tab>
                  Joined Fleets
              </v-tab>
              <v-tab-item>
                <v-list two-line subheader>
                  <v-list-item
                    v-for="fleet in fleets"
                    :key="fleet"
                  >
                    <v-list-item-avatar>
                      <v-icon
                        class="blue white--text"
                      >{{ fleetIcon(fleet)}}</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title v-text="fleet"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn icon @click="leaveFleet(fleet)">
                        <v-icon color="red lighten-1">mdi-close-circle</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-tab-item>

              <v-tab>
                  Vehicle Details
              </v-tab>
              <v-tab-item>
                <VehicleDetails
                  :car="selectedVehicle"
                  @close="closeDetails"
                  >
                </VehicleDetails>
              </v-tab-item>

              <v-tab>
                  Vehicle Log
              </v-tab>
              <v-tab-item>
                <VehicleLogs
                  :logs="logs"
                  @close="closeLogs"
                  >
                </VehicleLogs>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
import JoinFleetDialog from "../components/Telemetry/JoinFleetDialog";
import JoinVehicleLogDialog from "../components/Telemetry/JoinVehicleLogDialog";
import VehicleDetails from "../components/Telemetry/VehicleDetails";
import VehicleLogs from "../components/Telemetry/VehicleLogs";
import MapBox from "../components/Geo/MapBox";

export default {
  components: {
    JoinFleetDialog,
    JoinVehicleLogDialog,
    VehicleDetails,
    VehicleLogs,
    MapBox
  },
  data () {
    return {
      loading: true,
      vehicles: [],
      logs: null,
      maxLogCount: 500,
      selectedVehicle: null,
      fleets: [],
      vehicleLogs: [],
      showJoinFleet: false,
      showJoinVehicleLog: false,
      showZones: false,
      showStations: false,
      route: null,
      isochrone: null,
      geoCodeLocation: null,
      mapCenter: { lat: 60.326692, lng: 24.840489 }
    };
  },
  methods: {
    joinFleet: function (fleet) {
      if (this.fleets.includes(fleet)) {
        return;
      }

      this.fleets.push(fleet);
      this.$vehicleHub.joinFleet(fleet);
    },
    leaveFleet: function (fleet) {
      if (!this.fleets.includes(fleet)) {
        return;
      }

      this.fleets = this.fleets.filter(g => g !== fleet);
      this.$vehicleHub.leaveFleet(fleet);
    },
    joinVehicleLog: function (vehicleLog) {
      if (this.vehicleLogs.includes(vehicleLog)) {
        return;
      }

      if (!this.logs) {
        this.logs = [];
      }

      this.vehicleLogs.push(vehicleLog);
      this.$vehicleHub.joinVehicleLog(vehicleLog.fleetId, vehicleLog.vehicleId);
    },
    leaveVehicleLog: function (vehicleLog) {
      if (!this.vehicleLogs.includes(vehicleLog)) {
        return;
      }

      this.vehicleLogs = this.fleets.filter(g => g !== vehicleLog);
      this.$vehicleHub.leaveVehicleLog(vehicleLog);
    },
    onNewTelemetry: function (telemetry) {
      this.vehicles = this.vehicles.filter(c => c.vehicleId !== telemetry.vehicleId);
      const point = telemetry.location.point;
      const vehicleLocation = { lat: point.latitude, lng: point.longitude };
      telemetry.location.point = vehicleLocation;
      if (this.selectedVehicle && this.selectedVehicle.vehicleId === telemetry.vehicleId) {
        this.selectedVehicle = telemetry;
        this.mapCenter = vehicleLocation;
      }

      this.vehicles.push(telemetry);
    },
    onNewLog: function (log) {
      if (this.logs.length >= this.maxLogCount) {
        this.logs.shift();
      }
      this.logs.push(log);
    },
    onVehicleClicked: function (vehicle) {
      this.selectedVehicle = vehicle;
      const point = vehicle.location.point;
      const vehicleLocation = { lat: point.lat, lng: point.lng };
      this.mapCenter = vehicleLocation;
    },
    fleetIcon: function (fleet) {
      if (fleet.includes("_car_")) {
        return "mdi-car";
      }

      if (fleet.includes("_area_")) {
        return "mdi-vector-polygon";
      }

      return "mdi-domain";
    },
    closeDetails: function () {
      this.selectedCar = null;
    },
    closeLogs: function () {
      this.logs = null;
    }
  },
  created () {
    this.$vehicleHub.$on("telemetry", this.onNewTelemetry);
    this.$vehicleHub.$on("log", this.onNewLog);
  },
  beforeDestroy () {
    this.fleets.forEach(fleet => {
      this.$vehicleHub.leaveFleet(fleet);
    });

    this.$vehicleHub.$off("telemetry", this.onNewTelemetry);
  }
};
</script>

<style scoped>
h1 {
    margin-bottom: 1rem;
}
</style>
