var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    attrs: {
      items: _vm.carTypes,
      input: _vm.$emit("carTypeUpdated", _vm.carTypeUpdated),
      label: "Car Type",
      solo: ""
    },
    model: {
      value: _vm.carType,
      callback: function($$v) {
        _vm.carType = $$v
      },
      expression: "carType"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }