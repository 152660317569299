<template>
    <v-dialog v-model="isOpen" persistent max-width="1000">
        <v-card>
            <v-toolbar short dark color="primary">
                <v-card-title>
                    <span v-if="!value">Create transport event</span>
                    <span v-else>Edit transport event</span>
                </v-card-title>
            </v-toolbar>
            <v-card-text>
            <v-select
                label="Model"
                v-model="selectedModel"
                :items="models"
                hint="Edit fields that are relevant to selected model. 'Transport event' model allows editing all possible fields."
                persistent-hint
                class="my-3"
            ></v-select>
            <v-container v-if="readOnly">
                <v-row>
                    Transport event cannot be edited in its current state.
                    <br>Reason: {{ readOnlyReason }}
                </v-row>
            </v-container>
            <v-slide-y-transition group hide-on-leave>
                <LikumaForm
                    v-if="selectedModel === 'Likuma'"
                    ref="form"
                    key=1
                    :isNew="!value"
                    :readOnly="readOnly"
                    :originalTransportEvent="transportEvent"
                ></LikumaForm>
                <KelaForm
                  v-if="selectedModel === 'Kela'"
                  ref="form"
                  key=2
                  :isNew="!value"
                  :readOnly="readOnly"
                  :originalTransportEvent="transportEvent"
                ></KelaForm>
                <TransportEventForm
                    v-else-if="selectedModel === 'Transport event'"
                    ref="form"
                    key=3
                    :originalTransportEvent="transportEvent"
                ></TransportEventForm>
            </v-slide-y-transition>
            </v-card-text>
            <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn
                    v-if="!value"
                    @click="createTransportEvent"
                    color="success"
                    :loading="sending"
                    :disabled="readOnly"
                >
                    Add payment event
                </v-btn>
                <v-btn
                    v-else
                    @click="modifyTransportEvent"
                    :loading="sending"
                    :disabled="readOnly"
                    color="primary"
                >
                    Save changes
                </v-btn>
                <v-btn @click="$emit('close')" class="ml-4">
                    Cancel
                </v-btn>
                <v-btn
                  v-if="!!value"
                  @click="deleteTransportEvent"
                  :loading="sendingDelete"
                  :disabled="readOnly"
                  color="error"
                  class="ml-4"
                >
                  Delete event
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
        <v-snackbar
            v-model="errorSnackbar"
            color="error"
        >
            {{ errorText }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </v-dialog>
</template>

<script>
import { TransportEventStatusMixin } from "../../models/TransportEventStatusTypes";
import TransportEventForm from "./TransportEventForm";
import LikumaForm from "./LikumaForm";
import KelaForm from "./KelaForm";
import moment from "moment";
import _ from "lodash";

export default {
  components: {
    LikumaForm,
    KelaForm,
    TransportEventForm
  },
  mixins: [ TransportEventStatusMixin ],
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      transportEvent: {
        customer: {},
        fare: { vat: 10, extras: [] },
        trip: {}
      },
      models: ["Transport event", "Likuma", "Kela"],
      selectedModel: "Likuma",
      valid: true,
      sending: false,
      sendingDelete: false,
      errorSnackbar: false,
      errorText: ""
    };
  },
  computed: {
    readOnly () {
      return this.value && (!this.transportEvent ||
                this.transportEvent.salesInvoiceStatus !== 1 ||
                (this.transportEvent.validationStatus === this.validationStatusTypes.ReadyForValidation ||
                    this.transportEvent.validationStatus === this.validationStatusTypes.WaitingForInternalValidation ||
                    this.transportEvent.validationStatus === this.validationStatusTypes.WaitingForExternalValidation
                ));
    },
    readOnlyReason () {
      if (!this.transportEvent) return "Transport event does not exist";
      if (this.transportEvent.salesInvoiceStatus !== 1) return "Transport event has been sent to invoicing.";
      if ((this.transportEvent.validationStatus === this.validationStatusTypes.ReadyForValidation ||
                this.transportEvent.validationStatus === this.validationStatusTypes.WaitingForInternalValidation ||
                this.transportEvent.validationStatus === this.validationStatusTypes.WaitingForExternalValidation)) {
        return "Transport event is being validated. Wait for validation to happen.";
      }
      return "Unknown";
    }
  },
  watch: {
    isOpen (val, oldVal) {
      if (this.$refs && this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    value (val) {
      let transportEvent = (!this.value) ? {} : _.cloneDeep(this.value);
      if (!transportEvent.customer) transportEvent.customer = {};
      if (!transportEvent.fare) transportEvent.fare = { vat: 10 };
      if (!transportEvent.fare.extras) transportEvent.fare.extras = [];
      if (!transportEvent.trip) transportEvent.trip = {};
      if (transportEvent.trip.started) transportEvent.trip.started = moment(transportEvent.trip.started).format("YYYY-MM-DDTHH:mm:ss");
      if (transportEvent.trip.ended) transportEvent.trip.ended = moment(transportEvent.trip.ended).format("YYYY-MM-DDTHH:mm:ss");
      this.transportEvent = transportEvent;
    }
  },
  methods: {
    createTransportEvent () {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.sending = true;
      this.transportEvent = this.$refs.form.transportEvent;
      if (!this.transportEvent.validationStatus ||
                this.transportEvent.validationStatus === this.validationStatusTypes.Unknown) {
        this.transportEvent.validationStatus = this.validationStatusTypes.New;
      }
      this.$store.dispatch("invoicing/addTransportEvent", this.transportEvent)
        .then(() => {
          this.$emit("save", this.transportEvent);
        })
        .catch(e => {
          this.errorSnackbar = true;
          this.errorText = "Creating transport event failed. Message from server: " + e.message;
        })
        .finally(() => {
          this.sending = false;
        });
    },
    modifyTransportEvent () {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.sending = true;
      this.transportEvent = this.$refs.form.transportEvent;
      this.$store.dispatch("invoicing/updateTransportEvent", this.transportEvent)
        .then((response) => {
          this.$emit("save", this.transportEvent);
        })
        .catch(e => {
          this.errorSnackbar = true;
          this.errorText = "Saving transport event failed. Message from server: " + e.message;
        })
        .finally(() => {
          this.sending = false;
        });
    },
    deleteTransportEvent () {
      if (!window.confirm("Transport event will be permanently deleted. Are you sure you want to continue?")) {
        return;
      }
      this.sendingDelete = true;
      this.$store.dispatch("invoicing/deleteTransportEvent", this.transportEvent.id)
        .then((response) => {
          this.$emit("delete");
        })
        .catch(e => {
          this.errorSnackbar = true;
          this.errorText = "Deleting transport event failed. Message from server: " + e.message;
        })
        .finally(() => {
          this.sendingDelete = false;
        });
    }
  }
};
</script>

<style>

</style>
