<template>
    <v-card
    class="mx-auto"
    max-width="400"
    v-if="logs"
  >
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">Vehicle log</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
                  <v-btn icon @click="close()">
                    <v-icon color="red lighten-1">mdi-close-circle</v-icon>
                  </v-btn>
                </v-list-item-action>
    </v-list-item>
    <v-data-table
    :headers="headers"
    :items="logs"
    :items-per-page="5"
    class="elevation-1"
  ></v-data-table>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  props: {
    vehicleId: {
      type: String,
      default: function () {
        return "";
      }
    },
    fleetId: {
      type: String,
      default: function () {
        return "";
      }
    },
    logs: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  data () {
    return {
      headers: [
        { text: "Vehicle Id", value: "vehicleId" },
        { text: "Fleet Id", value: "fleetId" },
        { text: "Timestamp", value: "timestamp" },
        { text: "Severity", value: "severity" },
        { text: "Message", value: "message" }]
    };
  },
  methods: {
    formatDateTime: function (datetime) {
      return moment(datetime).format("L LT");
    },
    close: function () {
      this.$emit("close");
    }
  }
};
</script>
