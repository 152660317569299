var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        "expand-on-hover": "",
        "mini-variant-width": "75",
        clipped: "",
        app: "",
        color: "#f0f2f5",
        light: ""
      },
      model: {
        value: _vm.drawer,
        callback: function($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer"
      }
    },
    [
      _c("SideMenuAvatar", { attrs: { username: _vm.username } }),
      _c("v-divider"),
      _c(
        "v-list",
        { attrs: { nav: "", dense: "" } },
        [
          _vm.showHome
            ? _c("NavItem", {
                attrs: { icon: "house", title: "Home", routeName: "Home" }
              })
            : _vm._e(),
          _vm.showValidations
            ? _c("NavItem", {
                attrs: {
                  icon: "playlist_add_check",
                  title: "Validations",
                  routeName: "Validations"
                }
              })
            : _vm._e(),
          _vm.showPricing
            ? _c("NavItem", {
                attrs: { icon: "euro", title: "Pricing", routeName: "Pricing" }
              })
            : _vm._e(),
          _vm.showCustomers
            ? _c("NavItem", {
                attrs: {
                  icon: "people",
                  title: "Customers",
                  routeName: "Customers"
                }
              })
            : _vm._e(),
          _vm.showInvoicing
            ? _c("NavItem", {
                attrs: {
                  icon: "mdi-receipt",
                  title: "Invoicing",
                  routeName: "Invoicing"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }