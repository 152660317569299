var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    { attrs: { "two-line": "" } },
    [
      _c(
        "v-list-item-avatar",
        [_c("v-icon", { attrs: { large: "" } }, [_vm._v("person")])],
        1
      ),
      _c(
        "v-list-item-content",
        [
          _c("v-list-item-title", [_vm._v(_vm._s(_vm.username))]),
          _c("v-list-item-subtitle", [_vm._v("Kirjautunut")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }