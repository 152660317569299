<template>
	<v-app>
        <SideMenu v-if="user" :user="user" ref="sideMenu"></SideMenu>
        <Header v-if="user" v-on:sideMenuToggled="toggleSideMenu"></Header>
        <v-content>
            <v-container v-if="user" fluid>
                <router-view></router-view>
            </v-container>
            <Login
              v-else
              @loggedIn="setUserContext"
            ></Login>
        </v-content>
        <v-footer padless>
            <v-col class="text-center" cols="12">
                {{ new Date().getFullYear() }} - <strong>Router Group</strong>
            </v-col>
        </v-footer>
	</v-app>
</template>

<script>
import SideMenu from "./components/SideMenu/SideMenu";
import Header from "./components/Header/Header";
import Login from "./views/Login";

export default {
  name: "App",
  components: {
    Header,
    SideMenu,
    Login
  },
  data: () => ({
    user: null
  }),
  methods: {
    navigate (site) {
      this.$router.push({ name: site });
    },
    toggleSideMenu () {
      this.$refs.sideMenu.toggle();
    },
    async setUserContext () {
      this.user = await this.$AuthService.getUser();
      let token = await this.$AuthService.getToken(this.$AuthService.applicationConfig.backendScopes);
      this.$appInsights.setAuthenticatedUserContext(token.idToken.objectId);
    }
  }
};
</script>

<style>

.v-application {
   font-family: 'Merriweather', serif !important;
  }

div.v-input.theme--dark .v-input__slot {
	background-color: var(--v-primary-base) !important;
}
.even-item {
	background-color: #fafafa !important;
}

.odd-item {
	background-color: white !important;
}
</style>
