export const LikumaValidationErrors = {
  "A": "Vat error",
  "F": "Certificate unknown, missing",
  "U": "Unknown card, might be customer of different community",
  "C": "Card on black list",
  "N": "No travel right",
  "V": "Decision not valid",
  "M": "Customer moved",
  "D": "Customer dead",
  "R": "Receipt number",
  "X": "Customer does not use card",
  "T": "Duplicate Transaction",
  "H": "Transaction is already invoked",
  "E": "Mandatory information missing",
  "P": "Error in service extra",
  "I": "Handicap extra error",
  "L": "Taking both handicap and service extra",
  "B": "Stair help extra error",
  "S": "Sum too large",
  "G": "Too many kilometers",
  "O": "Wait time too long",
  "Q": "Unkown operator",
  "Y": "Test/training card",
  "Z": "Unknown error",
  _: "Unknown likuma fail reason"
};

export const TranportEventValidationErrorMixin = {
  data () {
    return {
      likumaValidationErrors: LikumaValidationErrors
    };
  },
  filters: {
    validationFailReasonToText: function (value) {
      if (value in LikumaValidationErrors) { return LikumaValidationErrors[value]; }
      return value;
    }
  }
};
