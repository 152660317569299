import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import moment from "moment";
import AuthService from "./msal";
import VueAppInsights from "./plugins/appInsights";
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import vehicleHub from "./plugins/vehicleHub";
import { LogLevel } from "@microsoft/signalr";

Vue.config.productionTip = false;
Vue.prototype.$AuthService = new AuthService();

Vue.filter("formatValidThrough", function (validThrough) {
  let start = moment(String(validThrough.startTime)).format("DD.MM.YYYY");
  let end = moment(String(validThrough.endTime)).format("DD.MM.YYYY");
  return `${start} -> ${end}`;
});

const currencyFormatter = new Intl.NumberFormat("fi-FI", {
  style: "currency",
  currency: "EUR"
});
Vue.filter("formatCurrency", function (price) {
  return currencyFormatter.format(price);
});

Vue.filter("formatPrice", function (price) {
  if (!price) return 0;
  let float = parseFloat(price);
  if (!float) return 0;
  return float.toFixed(2);
});

Vue.filter("formatToLocalTime", function (dateTime) {
  if (!dateTime) return "";
  return moment(dateTime).local();
});

Vue.filter("formatDateTime", function (dateTime) {
  if (!dateTime) return "";
  return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
});

Vue.use(VueAppInsights, {
  id: process.env.VUE_APP_APPINSIGHTSKEY,
  router
});

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLEMAPSAPIKEY,
    libraries: "places,drawing,visualization"
  },
  installComponents: true
});

Vue.component("GmapCluster", GmapCluster);

Vue.use(vehicleHub, {
  hubUrl: process.env.VUE_APP_VEHICLEHUB_URL,
  logLevel: LogLevel.Error,
  enabled: process.env.VUE_APP_VEHICLEHUB_ENABLED
});

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount("#app");
