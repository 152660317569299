<template>
	<v-navigation-drawer
		v-model="drawer"
		expand-on-hover
		mini-variant-width="75"
		clipped
		app
		color="#f0f2f5"
		light
	>
		<SideMenuAvatar :username="username"></SideMenuAvatar>

		<v-divider></v-divider>

		<v-list nav dense>
			<NavItem
				v-if="showHome"
				:icon="'house'"
				:title="'Home'"
				:routeName="'Home'"
			></NavItem>
			<NavItem
				v-if="showValidations"
				:icon="'playlist_add_check'"
				:title="'Validations'"
				:routeName="'Validations'"
			></NavItem>
			<NavItem
				v-if="showPricing"
				:icon="'euro'"
				:title="'Pricing'"
				:routeName="'Pricing'"
			></NavItem>
			<NavItem
				v-if="showCustomers"
				:icon="'people'"
				:title="'Customers'"
				:routeName="'Customers'"
			></NavItem>
			<NavItem
				v-if="showInvoicing"
				:icon="'mdi-receipt'"
				:title="'Invoicing'"
				:routeName="'Invoicing'"
			></NavItem>
			<!--
			<NavItem
				:icon="'mdi-tune'"
				:title="'Invoicing Rules'"
				:routeName="'InvoicingRules'"
			></NavItem>
			-->
		</v-list>
	</v-navigation-drawer>
</template>

<script>
import NavItem from "./NavItem";
import SideMenuAvatar from "./SideMenuAvatar";

export default {
  components: {
    NavItem,
    SideMenuAvatar
  },
  data () {
    return {
      drawer: null,
      showHome: true,
      showCustomers: true,
      showValidations: true,
      showPricing: true,
      showInvoicing: true
    };
  },
  props: {
    user: Object
  },
  watch: {
    user: {
      handler (val) {
        console.log(val);
        if (val && val.email && val.email.endsWith("@taksikuutio.fi")) {
          this.showHome = true;
          this.showInvoicing = true;
          this.showCustomers = false;
          this.showValidations = false;
          this.showPricing = false;
        } else {
          this.showHome = true;
          this.showInvoicing = true;
          this.showCustomers = true;
          this.showValidations = true;
          this.showPricing = true;
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    username () {
      return this.user.username;
    }
  },
  methods: {
    toggle () {
      this.drawer = !this.drawer;
    }
  }
};
</script>

<style scoped>
.v-list-item {
	cursor: pointer;
}
.v-navigation-drawer--mini-variant .v-list-item {
	-webkit-box-pack: left;
	-ms-flex-pack: left;
	justify-content: left;
}
</style>
