var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.logs
    ? _c(
        "v-card",
        { staticClass: "mx-auto", attrs: { "max-width": "400" } },
        [
          _c(
            "v-list-item",
            { attrs: { "two-line": "" } },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "headline" }, [
                    _vm._v("Vehicle log")
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-action",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "red lighten-1" } }, [
                        _vm._v("mdi-close-circle")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              headers: _vm.headers,
              items: _vm.logs,
              "items-per-page": 5
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }