<template>
    <v-form
        ref="form"
        v-if="transportEvent"
        v-model="valid">
        <v-subheader class="mt-3">Likuma trip info</v-subheader>
        <v-row>
            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="transportEvent.orderId"
                    label="Booking id"
                    :disabled="readOnly || !isNew"
                    :rules="[ requiredRule, digitRule, maxLengthRule(12) ]"
                    counter=12
                    required
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.likumaTripId"
                    label="Trip id"
                    :disabled="readOnly"
                    :rules="[ requiredRule, digitRule, maxLengthRule(10) ]"
                    counter=10
                    required
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.customer.cardNumber"
                    label="Card number"
                    :disabled="readOnly"
                    :rules="[ requiredRule, maxLengthRule(20) ]"
                    counter=20
                    required
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.likumaCertificateId"
                    label="Certificate id"
                    :disabled="readOnly"
                    :rules="[ requiredRule, digitRule, maxLengthRule(6) ]"
                    counter=6
                    required
                ></v-text-field>
                <v-select
                    v-model="transportEvent.trip.welfareTripType"
                    label="Decision id"
                    :disabled="readOnly"
                    :items="decisionIds"
                    :rules="[ requiredRule ]"
                    required
                ></v-select>
                <v-select
                    v-model="transportEvent.trip.tripType"
                    label="Trip type"
                    :disabled="readOnly"
                    :items="tripTypes"
                    :rules="[ requiredRule ]"
                    required
                ></v-select>
                <v-text-field
                    v-model="transportEvent.trip.started"
                    label="Trip start timestamp"
                    :disabled="readOnly"
                    :rules="[ requiredRule, dateTimeRule ]"
                    hint="yyyy-MM-dd HH:mm:ss"
                    placeholder="yyyy-MM-dd HH:mm:ss"
                    required
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.trip.ended"
                    label="Trip end timestamp"
                    :disabled="readOnly"
                    :rules="[ requiredRule, dateTimeRule ]"
                    hint="yyyy-MM-dd HH:mm:ss"
                    placeholder="yyyy-MM-dd HH:mm:ss"
                    required
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.fare.duration"
                    label="Trip time"
                    :disabled="readOnly"
                    :rules="[ requiredRule, digitRule, maxLengthRule(3) ]"
                    counter=3
                    required
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="transportEvent.fare.distance"
                    label="Trip distance"
                    :disabled="readOnly"
                    :rules="[ requiredRule, digitRule, maxLengthRule(6) ]"
                    counter=6
                    required
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.fare.waitingTime"
                    label="Waiting time"
                    :disabled="readOnly"
                    :rules="[ requiredRule, digitRule, maxLengthRule(4) ]"
                    counter=4
                    required
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.trip.carNumber"
                    label="Vehicle id"
                    :disabled="readOnly"
                    :rules="[ requiredRule, maxLengthRule(8) ]"
                    counter=8
                    required
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.fare.taximeterSerialNumber"
                    label="Meter id"
                    :disabled="readOnly"
                    :rules="[ requiredRule, maxLengthRule(14) ]"
                    counter=14
                    required
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.trip.driverId"
                    label="Driver id"
                    :disabled="readOnly"
                    :rules="[ maxLengthRule(12) ]"
                    counter=12
                ></v-text-field>
                <v-select
                    v-model="transportEvent.trip.carPool"
                    label="Car pool"
                    :disabled="readOnly"
                    :items="carPoolValues"
                ></v-select>
                <v-text-field
                    v-model="transportEvent.trip.startingPoint"
                    label="Start address"
                    :disabled="readOnly"
                    :rules="[ maxLengthRule(100) ]"
                    counter=100
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.trip.destination"
                    label="End address"
                    :disabled="readOnly"
                    :rules="[ maxLengthRule(100) ]"
                    counter=100
                ></v-text-field>
            </v-col>
        </v-row>
        <v-subheader class="mt-3">Likuma charge</v-subheader>
        <v-row>
            <v-col cols="12" sm="6">
                 <v-text-field
                    v-model="transportEvent.fare.receiptNumber"
                    label="Receipt number"
                    :disabled="readOnly"
                    :rules="[ requiredRule, digitRule, maxLengthRule(8) ]"
                    counter=8
                    required
                ></v-text-field>
                <v-select
                    v-model="transportEvent.fare.vatPercent"
                    :items="[10.0, 24.0]"
                    :rules="[ requiredRule ]"
                    @input="calculateSums"
                    label="Vat percent"
                    :disabled="readOnly"
                    required
                >
                    <template slot="selection" slot-scope="{ item }">
                        {{ item }} %
                    </template>
                    <template slot="item" slot-scope="{ item }">
                        {{ item }} %
                    </template>
                </v-select>
                <v-text-field
                    v-model="transportEvent.fare.cost"
                    label="Total amount"
                    @input="calculateSums"
                    :disabled="readOnly"
                    type="number"
                    :rules="[ requiredRule, decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
                    counter=9
                    required
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.fare.discountAmount"
                    label="Discount amount"
                    :disabled="readOnly"
                    type="number"
                    :rules="[ decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
                    counter=9
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.fare.reimbursement"
                    label="Total charge"
                    disabled
                    type="number"
                    :rules="[ requiredRule, decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
                    counter=9
                    required
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.fare.reimbursementVat"
                    label="Total charge vat"
                    disabled
                    type="number"
                    :rules="[ decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
                    counter=9
                ></v-text-field>
            </v-col>
            <v-col cols=12 sm=6>
                <v-text-field
                    v-model="transportEvent.fare.deductible"
                    label="Total deductible"
                    :disabled="readOnly"
                    @input="calculateSums"
                    type="number"
                    :rules="[ requiredRule, decimalRule, maxLengthWithoutDecimalSeparatorRule(6) ]"
                    counter=7
                    required
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.fare.deductibleVat"
                    label="Excess vat"
                    disabled
                    type="number"
                    :rules="[ decimalRule, maxLengthWithoutDecimalSeparatorRule(6) ]"
                    counter=7
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.fare.waitingCost"
                    label="Total waiting"
                    @input="calculateSums"
                    :disabled="readOnly"
                    type="number"
                    :rules="[ decimalRule, maxLengthWithoutDecimalSeparatorRule(6) ]"
                    counter=7
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.fare.waitingVat"
                    label="Waiting vat"
                    disabled
                    type="number"
                    :rules="[ decimalRule, maxLengthWithoutDecimalSeparatorRule(6) ]"
                    counter=7
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.fare.highTariff"
                    label="High tariff"
                    :disabled="readOnly"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-subheader>Extra charges (0-5)</v-subheader>
        <FeeChipGroup
            ref="feeChipGroup"
            :fees="transportEvent.fare.extras"
            :allowedFeeTypes="extraChargeTypes"
            :maxAmount=5
            :readOnly="readOnly"
            :useLikumaTexts=true
            @update="updateFees"
        ></FeeChipGroup>
        <v-row>
        <v-textarea
            :value="transportEvent.comments"
            label="Comments (optional)"
            :disabled="readOnly"
            outlined
            rows=3
            class="mt-3 mx-2"
            ></v-textarea>
        </v-row>
        <v-row>
        <v-textarea
            :value="transportEvent.purchaseInvoiceMessage"
            label="Info for purchase invoice (optional)"
            :disabled="readOnly"
            outlined
            rows=3
            class="mt-3 mx-2"
            ></v-textarea>
        </v-row>
        <v-switch
            v-model="toBeValidated"
            label="Set to be validated"
            :disabled="readOnly"
            hint="Event cannot be modified during the validation process"
            persistent-hint
            class="mt-0"
        ></v-switch>
    </v-form>
</template>

<script>
import FeeChipGroup from "./FeeChipGroup";
import { FeeTypeMixin, FeeType } from "../../models/FeeTypes";
import { ValidationStatusType } from "../../models/TransportEventStatusTypes";
import InputRuleMixin from "../Inputs/InputRuleMixin";
import _ from "lodash";

export default {
  components: { FeeChipGroup },
  mixins: [ FeeTypeMixin, InputRuleMixin ],
  props: {
    originalTransportEvent: Object,
    isNew: Boolean,
    readOnly: Boolean
  },
  data () {
    return {
      transportEvent: _.cloneDeep(this.originalTransportEvent),
      valid: true,
      decisionIds: [ "VPL", "SHL" ],
      tripTypes: [ "MT01", "MT02" ],
      carPoolValues: [
        { value: "0", text: "No" },
        { value: "1", text: "Yes" }
        // { value: 2, text: "Yes, deductible not charged" } // Ei käytössä pääkaupunkiseudulla
      ],
      extraChargeTypes: [
        FeeType.AirportFee,
        FeeType.ServiceFee,
        FeeType.AidFee,
        FeeType.HandicapFee
      ],
      toBeValidated: false
    };
  },
  watch: {
    originalTransportEvent (val) {
      this.toBeValidated = false;
      this.transportEvent = (!val) ? {} : _.cloneDeep(val);
      if (!this.transportEvent.fare || !this.transportEvent.fare.extras) {
        this.transportEvent = {
          fare: {
            extras: []
          }
        };
      }
    },
    toBeValidated (val) {
      if (val) {
        this.transportEvent.validationStatus = ValidationStatusType.ReadyForValidation;
      } else {
        this.transportEvent.validationStatus = this.originalTransportEvent.validationStatus;
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.form.validate();
    },
    resetValidation () {
      this.$refs.form.resetValidation();
    },
    updateFees (fees) {
      this.transportEvent.fare.extras = fees;
      this.$refs.feeChipGroup.closeFeeDialog();
    },
    calculateSums () {
      let fare = this.transportEvent.fare;
      fare.reimbursement = (fare.cost - fare.deductible).toFixed(2);
      fare.reimbursementVat = this.calculateVat(fare.reimbursement, fare.vatPercent);
      fare.deductibleVat = this.calculateVat(fare.deductible, fare.vatPercent);
      fare.waitingVat = this.calculateVat(fare.waitingCost, fare.vatPercent);
    },
    calculateVat (sumIncludingVat, vatPercent) {
      let vat = sumIncludingVat - (sumIncludingVat / ((vatPercent / 100.0) + 1));
      return vat.toFixed(2);
    }
  }
};
</script>

<style>

</style>
