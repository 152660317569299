<template>
  <v-dialog v-model="isOpen" persistent max-width="800">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ isNew ? 'Add new rule' : 'Edit rule' }}
        </span>
      </v-card-title>
      <v-card-text style="color: black;">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <v-container>
            <v-row>
              <v-text-field
                v-model="rule.name"
                :rules="
                [
                  v => !!v || 'Name is required',
                  v => !v || v.length < 100 || 'Name can not be longer than 100 characters'
                ]"
                counter="100"
                label="Name"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-subheader style="color: black; font-weight: bold;">
                Conditions
              </v-subheader>
            </v-row>
            <v-row
              v-for="(condition, i) in rule.conditions"
              :key="'c' + i"
              class="mb-2"
              align="center">
              <v-icon
                color="primary"
                @click.stop="openConditionDialog(condition, i)"
                small
                class="mr-1"
              >
                mdi-pencil
              </v-icon>
              {{ condition.value }} {{ condition.comparator | comparatorToSymbol }} {{ condition.target }}
            </v-row>
            <v-row>
              <v-btn
                text
                color="primary"
                @click="openNewConditionDialog()"
                class="add-new-btn"
              >
                <v-icon left>mdi-plus</v-icon>
                Add new condition
              </v-btn>
            </v-row>
            <v-row>
              <v-subheader style="color: black; font-weight: bold;">
                Actions
              </v-subheader>
            </v-row>
            <v-row
              v-for="(action, i) in rule.actions"
              :key="'a' + i"
              class="mb-2"
              align="center">
              <v-icon
                color="primary"
                @click.stop="openActionDialog(condition, i)"
                small
                class="mr-1"
              >
                mdi-pencil
              </v-icon>
              {{ action | actionToText }}
            </v-row>
            <v-row>
              <v-btn
                text
                color="primary"
                @click="openNewActionDialog()"
                class="add-new-btn"
              >
                <v-icon left>mdi-plus</v-icon>
                Add new action
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn v-if="isNew"
          :disabled="!valid"
          @click="createRule"
          :loading="isUpdating"
          color="success">
          Add rule
        </v-btn>
        <v-btn v-else
          :disabled="!valid"
          @click="saveRule"
          color="primary">
          Save changes
        </v-btn>
        <v-btn @click="$emit('close')" class="ml-4">
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <ConditionDialog
      :isOpen="conditionDialog"
      :originalCondition="selectedCondition"
      :isNew="newCondition"
      @create="addCondition"
      @save="modifySelectedCondition"
      @close="closeConditionDialog"
    ></ConditionDialog>
    <ActionDialog
      :isOpen="actionDialog"
      :originalAction="selectedAction"
      :isNew="newAction"
      @create="addAction"
      @save="modifySelectedAction"
      @close="closeActionDialog"
    ></ActionDialog>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { RulesMixin } from "@/models/Rules";
import ConditionDialog from "./ConditionDialog";
import ActionDialog from "./ActionDialog";

export default {
  props: {
    originalRule: {
      type: Object,
      default: () => {}
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: true
    },
    isUpdating: {
      type: Boolean,
      default: false
    }
  },
  components: { ConditionDialog, ActionDialog },
  mixins: [ RulesMixin ],
  data () {
    return {
      valid: true,
      rule: {
        conditions: [],
        actions: []
      },
      conditionDialog: false,
      selectedCondition: {},
      selectedConditionIndex: -1,
      newCondition: true,
      actionDialog: false,
      selectedAction: {},
      selectedActionIndex: -1,
      newAction: true
    };
  },
  watch: {
    isOpen: function (val, oldVal) {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    originalRule: function (val, oldVal) {
      let rule = _.cloneDeep(val);
      if (!rule.conditions) rule.conditions = [];
      if (!rule.actions) rule.actions = [];
      this.rule = rule;
    }
  },
  methods: {
    saveRule () {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit("save", this.rule);
    },
    createRule () {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit("create", this.rule);
    },
    openConditionDialog (condition, index) {
      this.selectedCondition = condition;
      this.selectedConditionIndex = index;
      this.newCondition = false;
      this.conditionDialog = true;
    },
    openNewConditionDialog () {
      this.selectedCondition = {};
      this.selectedConditionIndex = -1;
      this.newCondition = true;
      this.conditionDialog = true;
    },
    closeConditionDialog () {
      this.selectedCondition = {};
      this.conditionDialog = false;
    },
    addCondition (condition) {
      this.rule.conditions.push(condition);
      this.conditionDialog = false;
    },
    modifySelectedCondition (condition) {
      this.modifyCondition(this.selectedConditionIndex, condition);
    },
    modifyCondition (index, condition) {
      this.rule.conditions[index] = condition;
      this.conditionDialog = false;
    },
    closeRuleDialog () {
      this.selectedRule = {};
      this.ruleDialog = false;
    },
    openActionDialog (action, index) {
      this.selectedAction = action;
      this.selectedActionIndex = index;
      this.newAction = false;
      this.actionDialog = true;
    },
    openNewActionDialog () {
      this.selectedAction = {};
      this.selectedActionIndex = -1;
      this.newAction = true;
      this.actionDialog = true;
    },
    closeActionDialog () {
      this.selectedAction = {};
      this.actionDialog = false;
    },
    addAction (action) {
      this.rule.actions.push(action);
      this.actionDialog = false;
    },
    modifySelectedAction (action) {
      this.modifyAction(this.selectedActionIndex, action);
    },
    modifyAction (index, action) {
      this.rule.actions[index] = action;
      this.actionDialog = false;
    }
  }
};
</script>

<style scoped>
.v-subheader {
  padding: 0;
  font-size: 1.2em;
}

.v-btn:not(.v-btn--round).v-size--default.add-new-btn {
  padding: 0;
}
</style>
