export const DayType = {
  WorkingDay: "WorkingDay",
  Saturday: "Saturday",
  WorkingDayEve: "WorkingDayEve",
  Sunday: "Sunday",
  PublicHoliday: "PublicHoliday",
  SaturdayOrWorkingDayEve: "Saturday, WorkingDayEve"
};

export function DayTypeToString (dayType) {
  switch (dayType) {
  case DayType.WorkingDay:
    return "Arkipäivänä";
  case DayType.WorkingDayEve:
    return "Arkiaattona";
  case DayType.Sunday:
    return "Sunnuntaina";
  case DayType.SaturdayOrWorkingDayEve:
    return "Lauantaina tai arkiaattona";
  case DayType.Saturday:
    return "Lauantaina";
  case DayType.PublicHoliday:
    return "Yleisinä vapaapäivinä";
  }
};
