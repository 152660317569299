var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [_c("h1", [_vm._v("Invoicing")]), _c("TransportEventTable")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }