<template>
    <v-form
        ref="form"
        v-model="valid">
        TBA
        <!--
        <v-subheader class="mt-3">Payment info</v-subheader>
        <v-row>
            <v-col
                cols="12"
                sm="6">
                <v-text-field
                    v-model="transportEvent.fare.receiptNumber"
                    label="Receipt number"
                    :rules="[
                        v => !!v || 'Receipt number cannot be empty',
                        v => /^\d+$/.test(v) || 'Receipt number must contain only digits'
                    ]"
                    required
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.orderId"
                    label="Order Id"
                    :rules="[
                        v => !!v || 'Order id cannot be empty',
                    ]"
                    required
                ></v-text-field>
                <v-text-field
                    :disabled="true"
                    placeholder="Mitäs tähän pistetään"
                    label="Toimittaja"
                ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6">
                <v-select
                    v-model="transportEvent.fare.currency"
                    :items="['€']"
                    label="Currency"
                    :rules="[v => !!v || 'Currency is required']"
                    required
                >
                </v-select>
                <v-select
                        v-model="transportEvent.fare.vat"
                        :items="[10.0, 24.0]"
                        :rules="[v => !!v || 'VAT is required']"
                        label="VAT"
                        required
                >
                    <template slot="selection" slot-scope="{ item }">
                        {{ item }} %
                    </template>
                    <template slot="item" slot-scope="{ item }">
                        {{ item }} %
                    </template>
                </v-select>
                <v-text-field
                    v-model="transportEvent.fare.cost"
                    label="Cost"
                    :rules="[
                        v => !!v || 'Cost cannot be empty',
                        v => /^\d+((.|,)\d+)?$/.test(v) || 'Cost is not valid decimal value'
                    ]"
                    required
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.trip.started"
                    label="Trip started"
                    type="datetime-local"
                    :rules="[
                        v => !!v || 'Start time cannot be empty',
                    ]"
                    required
                ></v-text-field>
                <v-text-field
                    v-model="transportEvent.trip.ended"
                    label="Trip ended"
                    type="datetime-local"
                    :rules="[
                        v => !!v || 'End time cannot be empty',
                    ]"
                    required
                ></v-text-field>
            </v-col>
        </v-row>
        <v-subheader>Customer info</v-subheader>
        <v-row>
            <v-col cols="12">
            <v-text-field
                v-model="transportEvent.customer.cardNumber"
                label="Card number"
                :rules="[
                    v => !!v || 'Card number cannot be empty',
                    v => /^\d+$/.test(v) || 'Card number must contain only digits'
                ]"
                required
            ></v-text-field>
            <v-text-field
                v-model="transportEvent.customer.name"
                label="Customer name"
                :rules="[
                    v => !!v || 'Customer name cannot be empty',
                ]"
                required
            ></v-text-field>
            <v-text-field
                v-model="transportEvent.customer.costCentreId"
                label="Cost centre id"
                :rules="[
                    v => !!v || 'Cost centre id cannot be empty',
                ]"
                required
            ></v-text-field>
            <v-text-field
                v-model="transportEvent.customer.cardHolder"
                label="Card holder"
                :rules="[
                    v => !!v || 'Card holder cannot be empty',
                ]"
                required
            ></v-text-field>
            <v-text-field
                v-model="transportEvent.customer.cardProductId"
                label="Card product id"
                :rules="[
                    v => !!v || 'Card product id cannot be empty',
                ]"
                required
            ></v-text-field>
            <v-text-field
                v-model="transportEvent.customer.invoicingLabel"
                label="Invoicing label"
                required
            ></v-text-field>
            </v-col>
        </v-row>
        -->
    </v-form>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    originalTransportEvent: Object
  },
  data () {
    return {
      transportEvent: {},
      valid: true
    };
  },
  watch: {
    originalTransportEvent (val) {
      this.transportEvent = (!val) ? {} : _.cloneDeep(val);
    }
  },
  methods: {
    validate () {
      return false;
      // return this.$refs.form.validate();
    },
    resetValidation () {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<style>

</style>
