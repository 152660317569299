<template>
  <v-form v-model="searchParamsValid" class="mx-3 mt-2">
    <v-row align="center" class="mt-3">
      <v-col cols=12 md=4>
        <v-select
          v-model="searchParamProperty"
          :items="searchParamProperties"
          label="Hae käyttäen"
        ></v-select>
        </v-col>
        <v-col cols=12 md=8>
          <v-slide-x-transition group hide-on-leave>
            <v-text-field v-if="searchParamProperty === 'OrderId'" key=1
              v-model="orderId"
              label="Tilaustunnus"
            ></v-text-field>
            <v-text-field v-if="searchParamProperty === 'ReceiptNumber'" key=2
              v-model="receiptNumber"
              label="Kuittinumero"
            ></v-text-field>
          </v-slide-x-transition>
        </v-col>
    </v-row>
  </v-form>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    searchParams: Object
  },
  data () {
    return {
      searchParamProperties: [
        { value: "OrderId", text: "Tilaustunnus" },
        { value: "ReceiptNumber", text: "Kuittinumero" }
      ],
      searchParamProperty: "OrderId",
      orderId: _.cloneDeep(this.searchParams.orderId),
      receiptNumber: _.cloneDeep(this.searchParams.receiptNumber),
      searchParamsValid: true
    };
  },
  watch: {
    searchParams (val) {
      if (!val) {
        this.orderId = null;
        this.receiptNumber = null;
      } else {
        this.orderId = _.cloneDeep(val.orderId);
        this.receiptNumber = _.cloneDeep(val.receiptNumber);
      }
    }
  },
  methods: {
    getSearchParameters () {
      switch (this.searchParamProperty) {
      case "OrderId":
        return { orderId: this.orderId };
      case "ReceiptNumber":
        return { receiptNumber: this.receiptNumber };
      }
      return {};
    }
  }
};
</script>

<style>

</style>
