<template>
  <v-dialog v-model="isOpen" persistent max-width="800">
    <v-card>
      <v-card-title>
        <span class="headline">Calculate isochrone</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="isochrone.isochroneType"
                  :items="isochroneTypes"
                  label="Type"
                ></v-select>
              </v-col>
              <v-col cols="6">
              <v-text-field v-model="isochrone.unitValue" :label="unitValueLabel" placeholder="Value" type="number"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="isochrone.origin.latitude" label="Origin" placeholder="Lat" type="number"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="isochrone.origin.longitude" label="" placeholder="Long" type="number"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="isochrone.requestedTime" label="" placeholder="Long" type="datetime-local"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn @click="getRoute" :loading="isUpdating" color="success">Calculate</v-btn>
        <v-btn @click="close" :loading="isUpdating">Close</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

const mapToIsochroneRequest = (isochrone) => {
  return {
    Location: {
      Point: {
        Latitude: isochrone.origin.latitude,
        Longitude: isochrone.origin.longitude
      }
    },
    UnitValue: isochrone.unitValue,
    IsochroneType: isochrone.isochroneType,
    RequestedTime: isochrone.requestedTime
  };
};

export default {
  props: {
    isUpdating: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean,
      default: false
    } },
  data () {
    return {
      isochroneTypes: [
        { text: "Time", value: 2 },
        { text: "Distance", value: 1 }
      ],
      isochrone: {
        origin: {
          latitude: "60.326692",
          longitude: "24.840489"
        },
        unitValue: 5,
        isochroneType: 2,
        requestedTime: moment().format("YYYY-MM-DDTHH:mm")
      }
    };
  },
  computed: {
    unitValueLabel: function () {
      return this.isochrone.isochroneType === 2 ? "Time in seconds" : "Distance in meters";
    }
  },
  methods: {
    getRoute () {
      this.$emit("getIsochrone", mapToIsochroneRequest(this.isochrone));
      this.$emit("close");
    },
    close () {
      this.$emit("close");
    }
  }
};
</script>

<style>
.v-subheader {
  padding: 0;
  font-size: 1.2em;
}
</style>
