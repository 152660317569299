<template>
    <v-container fluid>
        <v-row align="center" justify="center">
            <img
                class="hidden-sm-and-up"
                style="max-width:80vw"
                src="@/assets/logo.png"
                alt="Router Group logo"
            />
            <img class="hidden-xs-only"
                style="max-height:50vh;"
                src="@/assets/logo_full.png"
                alt="Router Group logo"
            />
        </v-row>
        <v-row align="center" justify="center">
            <h1>Management Portal</h1>
        </v-row>
        <v-row align="center" justify="center" class="mt-10">
            <v-btn large color="primary" @click="login">click here to login</v-btn>
        </v-row>
        <v-row align="center" justify="center" class="mt-5">
            <p>Please enable pop-ups if permission is asked by browser. Pop-up is required for login.</p>
        </v-row>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
export default {
  data () {
    return {
      loading: false
    };
  },
  methods: {
    async login () {
      this.loading = true;
      await this.$AuthService.loginPopup().finally(() => { this.loading = false; });

      if (!await this.$AuthService.isAuthenticated()) return;

      this.$emit("loggedIn");
    }
  },
  async created () {
    if (!await this.$AuthService.isAuthenticated()) { return; }

    this.$emit("loggedIn");
  }
};
</script>

<style>

</style>
