export const PriceRuleType = {
  DayTypeTimeRange: "DayTypeTimeRange",
  CarType: "CarType",
  PassengerCount: "PassengerCount",
  FixedRoute: "FixedRoute",
  PreBooked: "PreBooked"
};

export function PriceRuleTypeToString (priceRuleType) {
  switch (priceRuleType) {
  case PriceRuleType.DayTypeTimeRange:
    return "Day type & time range";
  case PriceRuleType.CarType:
    return "Car type";
  case PriceRuleType.PassengerCount:
    return "Passenger count";
  case PriceRuleType.FixedRoute:
    return "Fixed route";
  case PriceRuleType.PreBooked:
    return "Pre-booked";
  }
};
