var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("h1", [_vm._v("Invoicing Rules")]),
      _c(
        "v-skeleton-loader",
        {
          attrs: {
            loading: _vm.loading,
            "transition-group": "scale-transition",
            type: "table"
          }
        },
        [
          _c("InvoicingRulePanels", {
            ref: "panels",
            attrs: { rules: _vm.invoicingRules },
            on: { update: _vm.updateInvoicingRules }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }