<template>
    <v-card>
        <v-snackbar
            v-model="snackbar"
            :color="snackbarColor"
            >
            {{ snackbarText }}
            <v-btn text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-card-title>
            Validations
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                label="Search"
                append-icon="search"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table :expanded="expandedItems" show-expand single-expand item-key="id" :headers="headers" :items="items" :search="search">
            <template v-slot:top>
                <div class="pl-5">
                    <ValidationDialog v-on:createValidation="newValidation"></ValidationDialog>
                </div>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-tooltip bottom :key="rule.id" v-for="(rule, i) in item.rules">
                        <template v-slot:activator="{ on }">
                            <v-chip
                                close
                                v-on="on"
                                @click:close="removeRule(item, rule, i)"
                                :color="i % 2 == 0 ? 'light-blue darken-1' : 'light-blue darken-3'"
                                class="white--text mr-2"
                            >{{ rule.ruleType }}</v-chip>
                        </template>
                        <span>{{ rule.name }}</span>
                    </v-tooltip>
                    <v-dialog v-model="ruleDialog" max-width="800px">
                        <template v-slot:activator="{ on }">
                            <v-chip color="success" v-on="on">
                                <v-icon>mdi-plus</v-icon>Add rule
                            </v-chip>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="headline">Add new rule{{ notOrRule === undefined ? '' : notOrRule === 0 ? 's (OR)' : 's (NOT)' }}</span>
                                <v-spacer></v-spacer>
                                <v-text-field v-model="ruleCollection.name" v-if="notOrRule >= 0" label="Collection name"></v-text-field>
                                <v-spacer></v-spacer>
                                <v-btn-toggle v-model="notOrRule">
                                    <v-btn>OR</v-btn>
                                    <v-btn>NOT</v-btn>
                                </v-btn-toggle>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-text-field
                                        v-model="currentRule.ruleName"
                                        label="Rule name"
                                        required
                                    >
                                    </v-text-field>
                                    <v-select :items="availableRules" v-if="currentRule.ruleName" v-model="currentRule.selectedRuleType" label="Select rule"></v-select>
                                    <v-row>
                                        <template v-if="currentRule.selectedRuleType == 'City'">
                                            <v-col>
                                                <CitySelector
                                                    v-on:citiesUpdated="cities => currentRule.data = cities">
                                                </CitySelector>
                                            </v-col>
                                        </template>
                                        <template v-else-if="currentRule.selectedRuleType == 'PaymentType'">
                                            <v-col>
                                                <PaymentTypeSelector
                                                    v-on:paymentUpdated="payment => currentRule.data = payment">
                                                </PaymentTypeSelector>
                                            </v-col>
                                        </template>
                                        <template v-else-if="currentRule.selectedRuleType == 'TimeRange'">
                                            <v-col>
                                                <TimeRangeSelector
                                                    v-on:timeRangeUpdated="timeRange => currentRule.data = timeRange">
                                                </TimeRangeSelector>
                                            </v-col>
                                        </template>
                                        <template v-else-if="currentRule.selectedRuleType == 'DayTypeTimeRange'">
                                            <v-col>
                                                <DayTypeTimeRangeSelector
                                                    v-on:dayTypeTimeRangeUpdated="dayTypeTimeRange => currentRule.data = dayTypeTimeRange">
                                                </DayTypeTimeRangeSelector>
                                            </v-col>
                                        </template>
                                        <template v-else-if="currentRule.selectedRuleType == 'CarType'">
                                            <v-col>
                                                <CarTypeSelector
                                                    v-on:carTypeUpdated="carType => currentRule.data = carType">
                                                </CarTypeSelector>
                                            </v-col>
                                        </template>
                                        <template v-else-if="currentRule.selectedRuleType == 'Location'">
                                            <v-col>
                                                <LocationSelector
                                                    v-on:locationInfoUpdated="locationInfo => currentRule.data = locationInfo">
                                                </LocationSelector>
                                            </v-col>
                                        </template>
                                        <template v-else-if="currentRule.selectedRuleType == 'Geofence'">
                                            <v-col>
                                                <GeoJsonSelector
                                                    v-on:geoJsonLoaded="geoJson => currentRule.data = geoJson">
                                                </GeoJsonSelector>
                                            </v-col>
                                        </template>
                                    </v-row>
                                </v-container>
                                <v-tooltip right v-if="notOrRule >= 0 && currentRule.selectedRuleType">
                                    <template v-slot:activator="{ on }">
                                        <v-btn @click="appendRule" v-on="on" small class="mx-2" fab dark color="success">
                                            <v-icon dark>mdi-plus</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Add rule to collection</span>
                                </v-tooltip>
                                <v-divider class="pb-4" v-if="ruleCollection.items.length"></v-divider>
                                <v-chip-group column>
                                    <v-chip
                                    close
                                    label
                                    @click:close="ruleCollection.items.splice(i, 1)"
                                    :color="rule.color"
                                    v-for="(rule, i) in ruleCollection.items" :key="i">
                                        {{ rule.ruleType }}Rule ({{rule.name}})
                                    </v-chip>
                            </v-chip-group>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                                <v-btn color="blue darken-1" text @click="save(item)" :disabled="disableSave()">Save</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </td>
            </template>
            <template v-slot:item.status="{ item }" >
                <v-select
                    style="max-width: 250px"
                    :items="availableStatuses"
                    v-model="item.status"
                    @change="validationStatusChanged($event, item)"
                >
                    <template v-slot:prepend>
                        <v-icon :color="item.status == 'Active' ? 'success' : item.status == 'Experimental' ? 'purple' : 'warning'">
                            {{ item.status == "Active" ? "mdi-checkbox-marked-circle" : item.status == "Experimental" ? "mdi-test-tube" : "mdi-block-helper" }}</v-icon>
                    </template>
                    <template v-slot:selection>
                        <div :class="item.status == 'Active' ? 'success--text' : item.status == 'Experimental' ? 'purple--text' : 'warning--text'">
                            {{ item.status }}
                        </div>
                    </template>
                </v-select>
            </template>
            <template v-slot:item.action="{ item }">
                <ValidationTestDialog v-on:testRequested="testValidation" v-if="item.originalStatus == 'Experimental'" :validation="item"></ValidationTestDialog>
                <v-icon large color="primary" v-if="item._modified" @click="updateValidation(item)">save</v-icon>
            </template>
            <template v-slot:item.validThrough="{ item }">
                {{ item.validThrough | formatValidThrough }}
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import { RepositoryFactory } from "../data/repositories/repositoryFactory";

import CitySelector from "../components/Rules/CitySelector";
import PaymentTypeSelector from "../components/Rules/PaymentTypeSelector";
import TimeRangeSelector from "../components/Rules/TimeRangeSelector";
import DayTypeTimeRangeSelector from "../components/Rules/DayTypeTimeRangeSelector";
import CarTypeSelector from "../components/Rules/CarTypeSelector";
import LocationSelector from "../components/Rules/LocationSelector";
import GeoJsonSelector from "../components/Rules/GeoJsonSelector";
import RuleService from "../services/ruleService";
import ValidationDialog from "../components/Dialogs/ValidationDialog";
import ValidationTestDialog from "../components/Dialogs/ValidationTestDialog";

const ValidationsRepository = RepositoryFactory.get("validations");

export default {
  components: {
    CitySelector,
    PaymentTypeSelector,
    TimeRangeSelector,
    DayTypeTimeRangeSelector,
    CarTypeSelector,
    LocationSelector,
    GeoJsonSelector,
    ValidationDialog,
    ValidationTestDialog
  },
  data () {
    return {
      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
      currentRule: {},
      ruleCollection: {
        name: "",
        items: []
      },
      expandedItems: [],
      notOrRule: undefined,
      availableRules: ["DayTypeTimeRange", "TimeRange", "Prebooked", "PaymentType", "Location", "Geofence", "City", "CarType"],
      availableStatuses: ["Active", "Experimental", "Inactive"],
      ruleDialog: false,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      search: "",
      colors: ["red", "pink", "purple", "indigo", "blue", "cyan", "teal", "green", "orange", "brown"],
      headers: [
        { text: "Name", value: "name" },
        { text: "Operator", value: "operatorId" },
        // { text: "Customer", value: "customerId" }, OTETAAN POIS TOISTASEKSI.
        // { text: "Vendor", value: "vendorId" }, OTETAAN POIS TOISTASEKSI
        { text: "ValidThrough", value: "validThrough" },
        { text: "Status", value: "status" },
        { text: "Type", value: "validationType" },
        { text: "Actions", value: "action", sortable: false }
      ],
      items: [],
      validations: []
    };
  },
  watch: {
    ruleDialog (val) {
      val || this.close();
    }
  },
  methods: {
    async validationStatusChanged (newStatus, validation) {
      if (newStatus !== validation.originalStatus) {
        validation._modified = true;
      }
    },
    async newValidation (validationObj) {
      this.items.push(validationObj);
      try {
        await ValidationsRepository.create(validationObj);
        this.showNotification("success", "Added new validation!");
      } catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        this.showNotification("error", "Failed to add validation. " + errorObject.statusText);
        this.items.pop();
      }
    },
    disableSave () {
      if (this.notOrRule >= 0) {
        if (!this.ruleCollection.items.length) {
          return true;
        }

        if (this.ruleCollection.name.length) {
          return false;
        }

        return true;
      }

      if (!this.currentRule.selectedRuleType) {
        return true;
      }

      return false;
    },
    randomColor () {
      var keys = Object.keys(this.colors);
      return this.colors[keys[keys.length * Math.random() << 0]];
    },
    removeRule (validation, rule, ruleIndex) {
      const validationIndex = this.items.indexOf(validation);
      confirm(`Are you sure you want to remove ${rule.name} from ${validation.name}?`) &&
                this.items[validationIndex].rules.splice(ruleIndex, 1);
      validation._modified = true;
    },
    appendRule () {
      this.currentRule.color = `${this.randomColor()} lighten-3`;
      this.ruleCollection.items.push({
        name: this.currentRule.ruleName,
        ruleType: this.currentRule.selectedRuleType,
        value: this.currentRule.data,
        color: this.currentRule.color
      });
      this.currentRule = {};
    },
    close () {
      this.currentRule = {};
      this.notOrRule = undefined;
      this.ruleCollection = {
        name: "",
        items: []
      };
      this.ruleDialog = false;
    },
    async testValidation (validation, order) {
      let result;

      try {
        result = await ValidationsRepository.test(validation.id, order);
      } catch (e) {
        // eslint-disable-next-line
                console.log(e);
      }

      if (result.status === 200) {
        this.showNotification("success", `Validation (${validation.name}) tested successfully!`);
      } else {
        this.showNotification("red", "Error occured while trying to test validation. See logs.");
      }
    },
    async updateValidation (validation) {
      let _validation = RuleService.mapRules(validation);
      let result;

      try {
        result = await ValidationsRepository.update(validation.id, _validation);
      } catch (e) {
        // eslint-disable-next-line
                console.log(e);
        // this.showNotification("red", "Error occured while trying to update validation. " + e.);
      }

      if (result.status === 200) {
        validation._modified = false;
        this.expandedItems = [];
        this.showNotification("success", `Updated Validation (${validation.name}) successfully!`);
        await this.getValidations();
      } else {
        this.showNotification("red", "Error occured while trying to update validation.");
      }
    },
    statusChanged (item) {
    },
    showNotification (color, text) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    },
    async save (validation) {
      validation._modified = true;

      if (this.notOrRule >= 0) {
        validation.rules.unshift({
          name: this.ruleCollection.name,
          ruleType: this.notOrRule === 0 ? "OrRule" : "NotRule",
          value: this.ruleCollection.items });
        this.ruleCollection = {
          name: "",
          items: []
        };
        this.ruleDialog = false;
        return;
      }

      validation.rules.unshift({
        name: this.currentRule.ruleName,
        ruleType: this.currentRule.selectedRuleType,
        value: this.currentRule.data });

      this.currentRule = {};
      this.ruleDialog = false;
    },
    deleteItem (item) {
      const index = this.items.indexOf(item);
      confirm("Are you sure you want to remove this validation?") &&
                this.items.splice(index, 1);
    },
    changeStatus (validation) {
      switch (validation.status) {
      case "Active" : validation.status = "Experimental"; break;
      case "Experimental" : validation.status = "Inactive"; break;
      case "Inactive" : validation.status = "Active"; break;
      }

      validation._modified = true;
    },
    async getValidations () {
      let { data: validations } = await ValidationsRepository.find();
      this.addOriginalStatus(validations);
      this.items = validations;
    },
    addOriginalStatus (validations) {
      for (let validation of validations) {
        validation.originalStatus = validation.status;
      }
    }
  },
  created () {
    this.getValidations();
  }
};
</script>

<style scoped>
</style>
