<template>
  <v-dialog v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on }">
            <v-btn color="primary" v-on="on">Add User</v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Add new User</span>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-text-field :error-messages="nameErrors" :disabled="validated" outlined required v-model="$v.name.$model" value="asd" label="Name"></v-text-field>
                    <v-text-field :error-messages="emailErrors" :disabled="validated" outlined required v-model="$v.email.$model" placeholder="matti@gmail.com" label="Email"></v-text-field>
                    <v-text-field :error-messages="phoneErrors" :disabled="validated" outlined required v-model="$v.phone.$model" placeholder="358501234567" label="Phone"></v-text-field>
                    <v-btn :disabled="this.$v.$invalid || this.validated" color="success" class="mr-4" @click="validate">Validate / Confirm</v-btn>
                    <v-btn color="red" :disabled="!this.validated" text @click="cancel">Cancel</v-btn>
                    <v-btn color="primary" v-if="validated" class="float-right" @click="create">Create</v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    name: { required, minLength: minLength(4) },
    email: { required, email },
    phone: {
      required,
      validPhone (phone) {
        return /^(358)\d{5,11}$/.test(phone);
      }
    }
  },
  data: () => ({
    dialog: false,
    validated: false,
    name: "",
    email: "",
    phone: ""
  }),
  methods: {
    cancel () {
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.phone = "";
      this.validated = false;
    },
    validate () {
      this.validated = true;
    },
    create () {
      this.$emit("userCreated", {
        name: this.name,
        email: this.email,
        phone: this.phone
      });
      this.cancel();
      this.dialog = false;
    }
  },
  computed: {
    nameErrors () {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required.");
      !this.$v.name.minLength && errors.push("Name must be atleast 4 characters long.");
      return errors;
    },
    emailErrors () {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    phoneErrors () {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.validPhone && errors.push("Must be valid phone number");
      !this.$v.phone.required && errors.push("Phone number is required");
      return errors;
    }
  }
};
</script>

<style>

</style>
