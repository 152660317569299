var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.car
    ? _c(
        "v-card",
        { staticClass: "mx-auto", attrs: { "max-width": "400" } },
        [
          _c(
            "v-list-item",
            { attrs: { "two-line": "" } },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "headline" }, [
                    _vm._v("Vehicle details")
                  ]),
                  _c("v-list-item-subtitle", [
                    _vm._v(
                      _vm._s(_vm.car.CarNumber) + ", " + _vm._s(_vm.car.Company)
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-action",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "red lighten-1" } }, [
                        _vm._v("mdi-close-circle")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-icon",
                [_c("v-icon", [_vm._v("mdi-calendar-clock")])],
                1
              ),
              _c("v-list-item-subtitle", [
                _vm._v(_vm._s(_vm.formatDateTime(_vm.car.LastUpdated)))
              ])
            ],
            1
          ),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { title: "Driver Id" } }, [
                    _vm._v("mdi-account")
                  ])
                ],
                1
              ),
              _c("v-list-item-subtitle", [_vm._v(_vm._s(_vm.car.DriverId))])
            ],
            1
          ),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { title: "State" } }, [
                    _vm._v("mdi-state-machine")
                  ])
                ],
                1
              ),
              _c("v-list-item-subtitle", [_vm._v(_vm._s(_vm.car.State))])
            ],
            1
          ),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { title: "Area" } }, [
                    _vm._v("mdi-vector-polygon")
                  ])
                ],
                1
              ),
              _c("v-list-item-subtitle", [_vm._v(_vm._s(_vm.car.Area))])
            ],
            1
          ),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { title: "Speed" } }, [
                    _vm._v("mdi-send")
                  ])
                ],
                1
              ),
              _c("v-list-item-subtitle", [
                _vm._v(_vm._s(_vm.car.Speed) + " km/h")
              ])
            ],
            1
          ),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { title: "Location" } }, [
                    _vm._v("mdi-map-marker")
                  ])
                ],
                1
              ),
              _c("v-list-item-subtitle", [
                _vm._v(
                  _vm._s(Number(_vm.car.Latitude).toFixed(3)) +
                    ", " +
                    _vm._s(Number(_vm.car.Longitude).toFixed(3))
                )
              ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }