/* eslint-disable handle-callback-err */
import * as Msal from "@azure/msal-browser";
import config from "../config";

export default class AuthService {
  constructor () {
    this.applicationConfig = {
      backendScopes: {
        // TODO: Actual scopes
        scopes: [config.clientId]
      },
      graphApiScopes: {
        scopes: ["https://graph.microsoft.com/User.Read"]
      }
    };

    if (window.location.host.startsWith("localhost")) {
      config.redirectUri = "http://localhost:8080";
    } else {
      config.redirectUri = `https://${window.location.host}`;
    }

    const msalConfig = {
      auth: {
        clientId: config.clientId,
        authority: config.authority,
        redirectUri: config.redirectUri
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
      }
    };

    this.app = new Msal.PublicClientApplication(msalConfig);
  }

  // Core Functionality
  async loginPopup () {
    try {
      return await this.app.loginPopup(this.applicationConfig.backendScopes);
    } catch (err) {
      throw Error(err);
    }
  }

  async getUser () {
    const token = await this.getToken(this.applicationConfig.backendScopes);

    if (token == null) {
      this.app._user = {};
      return {};
    }

    const user = await this.getUserInfo();
    this.app._user = user;
    return this.app._user;
  }

  loginRedirect () {
    this.app.loginRedirect(this.applicationConfig.backendScopes);
  }

  async isAuthenticated () {
    try {
      await this.app.acquireTokenSilent(this.applicationConfig.backendScopes);
      return true;
    } catch {
      return false;
    }
  }

  logout () {
    this.app._user = null;
    this.app.logout();
  }

  async getToken (scopes) {
    try {
      return await this.app.acquireTokenSilent(scopes);
    } catch (err) {
      try {
        return await this.app.acquireTokenPopup(scopes);
      } catch (err) {
        throw Error(err);
      }
    }
  }

  async getUserInfo () {
    const token = await this.getToken(this.applicationConfig.graphApiScopes);

    if (!token) return {};

    return {
      username: token.account.name,
      email: token.account.userName
    };
  }
}
