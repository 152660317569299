var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c("v-file-input", {
            attrs: { label: "Upload GeoJSON File" },
            on: { change: _vm.fileAdded }
          })
        ],
        1
      ),
      _vm.geoData.features
        ? _c(
            "v-row",
            [
              _c(
                "v-chip-group",
                { attrs: { column: "" } },
                _vm._l(_vm.geoData.features, function(feature) {
                  return _c(
                    "v-chip",
                    {
                      key: feature.properties.id,
                      attrs: {
                        label: "",
                        color: _vm.randomColor() + " lighten-3"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(feature.properties.name) +
                          " (" +
                          _vm._s(feature.properties.id) +
                          ") "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }