var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customers
    ? _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _vm._v(" Customers "),
              _c("v-spacer"),
              _c("v-text-field", {
                attrs: { "single-line": "", "hide-details": "" }
              })
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              items: _vm.customers,
              headers: _vm.headers,
              "item-key": "customerId"
            },
            on: { "click:row": _vm.openCustomer },
            scopedSlots: _vm._u(
              [
                {
                  key: "top",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "pl-5" },
                        [
                          _c("CustomerDialog", {
                            on: { customerCreated: _vm.createCustomer }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1660641077
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }