export const CarType = {
  Sedan: "Sedan",
  Combi: "Combi",
  Minibus: "Minibus"
};

export function CarTypeToString (carType) {
  switch (carType) {
  case CarType.Sedan:
    return "Sedan";
  case CarType.Combi:
    return "Combi";
  case CarType.Minibus:
    return "Minibus";
  }
};
