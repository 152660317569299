<template>
  <v-dialog v-model="isOpen" persistent max-width="800">
        <v-card>
            <v-card-title>
               <span class="headline">
                   {{ isNewPrice ? 'Add new price' : 'Edit price' }}
                </span>
            </v-card-title>
            <v-card-text>
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation>
                    <v-container>
                        <v-row>
                            <v-text-field
                                v-model="price.unitPriceInfo.name"
                                :rules="
                                [
                                    v => !!v || 'Name is required',
                                    v => !v || v.length < 100 || 'Name can not be longer than 100 characters'
                                ]"
                                counter="100"
                                label="Name"
                            ></v-text-field>
                        </v-row>
                        <v-row>
                            <v-col class="pl-0">
                                <v-text-field
                                    v-model="price.unitPriceInfo.vendorUnitPrice"
                                    :rules="[v => !!v || 'Vendor price is required']"
                                    label="Vendor price"
                                    type="number"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col class="pl-0">
                                <v-text-field
                                    v-model="price.unitPriceInfo.customerUnitPrice"
                                    :rules="[v => !!v || 'Customer price is required']"
                                    label="Customer price"
                                    type="number"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col class="px-0">
                                <v-select
                                v-model="price.unitPriceInfo.vatPercent"
                                :items="[10.0, 24.0]"
                                :rules="[v => !!v || 'VAT is required']"
                                label="VAT"
                                required
                            >
                                <template slot="selection" slot-scope="{ item }">
                                    {{ item }} %
                                </template>
                                <template slot="item" slot-scope="{ item }">
                                    {{ item }} %
                                </template>
                            </v-select>
                            </v-col>
                        </v-row>
                        <v-row><v-subheader>Rules</v-subheader></v-row>
                        <v-row>
                            <v-switch
                                v-model="isDefault"
                                label="Is default price"
                            ></v-switch>
                        </v-row>
                        <v-slide-y-transition>
                        <RuleChipGroup
                            ref="ruleChipGroup"
                            v-if="!isDefault"
                            :rules="price.rules.filter(r => r.ruleType !== 'Default')"
                            :allowedRuleTypes="allowedRuleTypes"
                            @update="updateRules"
                        ></RuleChipGroup>
                        </v-slide-y-transition>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn v-if="isNewPrice"
                    :disabled="!valid"
                    @click="createPrice"
                    :loading="isUpdating"
                    color="success">
                    Add price
                </v-btn>
                <v-btn v-else
                    :disabled="!valid"
                    @click="savePrice"
                    color="primary">
                    Save changes
                </v-btn>
                <v-btn @click="$emit('close')" class="ml-4">
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
  </v-dialog>
</template>

<script>
import RuleChipGroup from "./RuleChipGroup";
import _ from "lodash";

export default {
  components: {
    RuleChipGroup
  },
  props: {
    originalPrice: {
      type: Object,
      default: () => {}
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    isNewPrice: {
      type: Boolean,
      default: true
    },
    allowedRuleTypes: Array,
    isUpdating: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      valid: true,
      isDefault: false,
      price: {
        unitPriceInfo: {},
        rules: []
      }
    };
  },
  computed: {
  },
  watch: {
    isOpen: function (val, oldVal) {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    originalPrice: function (val, oldVal) {
      let price = _.cloneDeep(val);
      if (!price.unitPriceInfo) price.unitPriceInfo = {};
      if (!price.rules) price.rules = [];
      if (!price.unitPriceInfo.vatPercent) price.unitPriceInfo.vatPercent = 10.0;
      this.price = price;
      this.isDefault = this.price.rules &&
                this.price.rules.length === 1 &&
                this.price.rules[0].ruleType === "Default";
    }
  },
  methods: {
    savePrice () {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.isDefault) {
        this.price.rules = [ { ruleType: "Default" } ];
      } else if (this.price.rules.length < 1) {
        alert("Price must have at least one rule if it is not determined as default price!");
        return;
      }
      this.$emit("save", this.price);
    },
    createPrice () {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.isDefault) {
        this.price.rules = [ { ruleType: "Default" } ];
      } else if (this.price.rules.length < 1) {
        alert("Price must have at least one rule if it is not determined as default price!");
        return;
      }
      this.$emit("create", this.price);
    },
    updateRules (rules) {
      this.price.rules = rules;
      this.$refs.ruleChipGroup.closeRuleDialog();
    }
  }
};
</script>

<style>
.v-subheader {
    padding: 0;
    font-size: 1.2em;
}
</style>
