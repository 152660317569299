<template>
  <v-container fluid>
        <h1>Invoicing</h1>
        <TransportEventTable></TransportEventTable>
  </v-container>
</template>

<script>
import TransportEventTable from "../components/Invoicing/TransportEventTable";

export default {
  components: { TransportEventTable }
};
</script>

<style scoped>
h1 {
    margin-bottom: 1rem;
}
</style>
