var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mapbox-map",
    {
      attrs: {
        "access-token":
          "pk.eyJ1IjoidHNhYXJpIiwiYSI6ImNrYmoweTNzNjBrcjYyeG1iYWM5aXhtcHQifQ.294Rd_t6UM7gh1ssW30mfQ",
        "map-style": "mapbox://styles/mapbox/streets-v11",
        center: _vm.center,
        zoom: _vm.zoom
      }
    },
    [
      _vm._l(_vm.markers, function(m, index) {
        return _c(
          "mapbox-marker",
          { key: "m" + index, attrs: { "lng-lat": m.position } },
          [_c("p", { staticClass: "custom-marker" }, [_vm._v("Hello world!")])]
        )
      }),
      _vm._l(_vm.vehicles, function(v, index) {
        return _c(
          "mapbox-marker",
          {
            key: "v" + index,
            attrs: { "lng-lat": _vm.vehicleToMapLocation(v) }
          },
          [
            _c("v-icon", { attrs: { color: _vm.getVehicleStatusColor(v) } }, [
              _vm._v("mdi-taxi")
            ])
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }