var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "800" },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [
              _vm._v(" Add new price catalog ")
            ])
          ]),
          _c(
            "v-card-text",
            { staticClass: "pb-0" },
            [
              _c("v-switch", {
                attrs: {
                  label:
                    "Copy existing price catalog (including prices and rules)"
                },
                model: {
                  value: _vm.useExisting,
                  callback: function($$v) {
                    _vm.useExisting = $$v
                  },
                  expression: "useExisting"
                }
              }),
              _c(
                "v-slide-y-transition",
                [
                  _c("v-combobox", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.useExisting,
                        expression: "useExisting"
                      }
                    ],
                    attrs: {
                      items: _vm.priceCatalogs,
                      "item-text": "name",
                      "item-value": "id",
                      label: "Price catalog"
                    },
                    on: { change: _vm.changePriceCatalog },
                    model: {
                      value: _vm.selectedPriceCatalog,
                      callback: function($$v) {
                        _vm.selectedPriceCatalog = $$v
                      },
                      expression: "selectedPriceCatalog"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: [
                                function(v) {
                                  return !!v || "Name is required"
                                },
                                function(v) {
                                  return (
                                    !v ||
                                    v.length < 100 ||
                                    "Name can not be longer than 100 characters"
                                  )
                                }
                              ],
                              counter: "100",
                              label: "Name",
                              required: ""
                            },
                            model: {
                              value: _vm.newPriceCatalog.name,
                              callback: function($$v) {
                                _vm.$set(_vm.newPriceCatalog, "name", $$v)
                              },
                              expression: "newPriceCatalog.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: _vm.useExisting,
                              label: "OperatorId"
                            },
                            model: {
                              value: _vm.newPriceCatalog.operatorId,
                              callback: function($$v) {
                                _vm.$set(_vm.newPriceCatalog, "operatorId", $$v)
                              },
                              expression: "newPriceCatalog.operatorId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: _vm.useExisting,
                              label: "CustomerId"
                            },
                            model: {
                              value: _vm.newPriceCatalog.customerId,
                              callback: function($$v) {
                                _vm.$set(_vm.newPriceCatalog, "customerId", $$v)
                              },
                              expression: "newPriceCatalog.customerId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: _vm.useExisting,
                              label: "VendorId"
                            },
                            model: {
                              value: _vm.newPriceCatalog.vendorId,
                              callback: function($$v) {
                                _vm.$set(_vm.newPriceCatalog, "vendorId", $$v)
                              },
                              expression: "newPriceCatalog.vendorId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: _vm.useExisting,
                              label: "ProductId"
                            },
                            model: {
                              value: _vm.newPriceCatalog.productId,
                              callback: function($$v) {
                                _vm.$set(_vm.newPriceCatalog, "productId", $$v)
                              },
                              expression: "newPriceCatalog.productId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [_c("v-subheader", [_vm._v("Valid through")])],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Start time",
                                  type: "datetime-local",
                                  rules: [
                                    function(v) {
                                      return !!v || "Start time cannot be empty"
                                    }
                                  ],
                                  required: ""
                                },
                                model: {
                                  value:
                                    _vm.newPriceCatalog.validThrough.startTime,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.newPriceCatalog.validThrough,
                                      "startTime",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "newPriceCatalog.validThrough.startTime"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "End time",
                                  type: "datetime-local",
                                  rules: [
                                    function(v) {
                                      return !!v || "End time cannot be empty"
                                    }
                                  ],
                                  required: ""
                                },
                                model: {
                                  value:
                                    _vm.newPriceCatalog.validThrough.endTime,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.newPriceCatalog.validThrough,
                                      "endTime",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "newPriceCatalog.validThrough.endTime"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-slide-y-transition",
                        [
                          _c(
                            "v-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.useExisting,
                                  expression: "useExisting"
                                }
                              ]
                            },
                            [
                              _c("v-switch", {
                                attrs: {
                                  label:
                                    "Set previous price catalog to end when this one starts"
                                },
                                model: {
                                  value: _vm.setOldToEnd,
                                  callback: function($$v) {
                                    _vm.setOldToEnd = $$v
                                  },
                                  expression: "setOldToEnd"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-4" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: !_vm.valid,
                    loading: _vm.isUpdating,
                    color: "success"
                  },
                  on: { click: _vm.createNewPriceCatalog }
                },
                [_vm._v(" Add price catalog ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  attrs: { disabled: _vm.isUpdating },
                  on: { click: _vm.closeDialog }
                },
                [_vm._v(" Cancel ")]
              ),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }