<template>
  <v-form v-model="searchParamsValid" class="mx-3 mt-2">
    <v-row align="center" class="mt-3">
      <v-col cols=12 md=9 class="pl-0 py-0">
        <v-select
          v-model="updatedSearchParams.validationStates"
          :items="Object.values(validationStatusTypes)"
          label="Validation status"
          multiple
          clearable
        >
          <template slot="selection" slot-scope="{ item, index }">
            <v-chip v-if="index === 0 || index === 1">
            {{ item | validationStatusToText }}
            </v-chip>
            <span
            v-if="index === 2"
            class="grey--text caption"
            >(+{{ updatedSearchParams.validationStates.length - 2 }} others)</span>
          </template>
          <template slot="item" slot-scope="{ item }">
            {{ item | validationStatusToText }}
          </template>
        </v-select>
      </v-col>
      <v-col cols=12 md=3 class="pl-0 py-0">
        <v-btn
          @click="setFailedValidations"
          outlined
          small
          class="pt-0"
        >
          Search failed validations
        </v-btn>
      </v-col>
    </v-row>
    <v-slide-y-transition>
      <v-row v-if="updatedSearchParams.validationStates.includes(validationStatusTypes.ExternalValidationFailed)">
        <v-col cols=12 md=9 class="pl-0 py-0">
          <v-select
            v-model="updatedSearchParams.validationFailReasonCodes"
            :items="Object.keys(likumaValidationErrors)"
            label="Likuma error types"
            multiple
            clearable
          >
            <template slot="selection" slot-scope="{ item }">
              <v-chip>
                {{ item }} - {{ likumaValidationErrors[item] }}
              </v-chip>
            </template>
            <template slot="item" slot-scope="{ item }">
              {{ item }} - {{ likumaValidationErrors[item] }}
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-slide-y-transition>
    <v-row align="center">
      <v-col cols=12 sm=6 class="pl-0 py-0">
        <v-select
          v-model="updatedSearchParams.salesInvoiceStates"
          :items="Object.values(salesInvoiceStates)"
          label="Sales invoice status"
          :disabled="!!updatedSearchParams.salesInvoiceNumbers"
          multiple
          clearable
        >
          <template slot="selection" slot-scope="{ item, index }">
            <v-chip v-if="index === 0 || index === 1">
            {{ item | salesInvoiceStateToText }}
            </v-chip>
            <span
            v-if="index === 2"
            class="grey--text caption"
            >(+{{ updatedSearchParams.salesInvoiceStates.length - 2 }} others)</span>
          </template>
          <template slot="item" slot-scope="{ item }">
            {{ item | salesInvoiceStateToText }}
          </template>
        </v-select>
      </v-col>
      <v-col cols=12 sm=1 class="py-0">
        <v-subheader>OR</v-subheader>
      </v-col>
      <v-col cols=12 sm=5 class="pl-0 py-0">
        <v-text-field
          v-model="updatedSearchParams.salesInvoiceNumbers"
          label="Sales invoice number"
          @change="updatedSearchParams.salesInvoiceStates = []"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols=12 sm=6 class="pl-0 py-0">
        <v-select
          v-model="updatedSearchParams.purchaseInvoiceStates"
          :items="Object.values(purchaseInvoiceStates)"
          label="Purchase invoice status"
          :disabled="!!updatedSearchParams.purchaseInvoiceNumbers"
          multiple
          clearable
        >
          <template slot="selection" slot-scope="{ item, index }">
            <v-chip v-if="index === 0 || index === 1">
            {{ item | purchaseInvoiceStateToText }}
            </v-chip>
            <span
            v-if="index === 2"
            class="grey--text caption"
            >(+{{ updatedSearchParams.purchaseInvoiceStates.length - 2 }} others)</span>
          </template>
          <template slot="item" slot-scope="{ item }">
            {{ item | purchaseInvoiceStateToText }}
          </template>
        </v-select>
      </v-col>
      <v-col cols=12 sm=1 class="py-0">
        <v-subheader>OR</v-subheader>
      </v-col>
      <v-col cols=12 sm=5 class="pl-0 py-0">
        <v-text-field
          v-model="updatedSearchParams.purchaseInvoiceNumbers"
          label="Purchase invoice number"
          @change="updatedSearchParams.purchaseInvoiceStates = []"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col class="pl-0 py-0" cols=12 sm=4>
        <v-text-field
          v-model="updatedSearchParams.carNumber"
          label="Vehicle id"
        ></v-text-field>
      </v-col>
      <v-col class="pl-0 py-0" cols=12 sm=4>
        <v-text-field
          v-model="updatedSearchParams.taximeterSerialNumber"
          label="Meter id"
        ></v-text-field>
      </v-col>
      <v-col class="pl-0 py-0" cols=12 sm=4>
        <v-text-field
          v-model="updatedSearchParams.cardNumber"
          label="Card number"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols=12 md=2 class="pl-0 py-0">Modified</v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
          v-model="updatedSearchParams.modifiedFrom"
          label="Modified from"
          type="datetime-local"
        ></v-text-field>
      </v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
        v-model="updatedSearchParams.modifiedTo"
        label="Modified to"
        type="datetime-local"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols=12 md=2 class="pl-0 py-0">Trip started</v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
          v-model="updatedSearchParams.tripStartedFrom"
          label="Trip started from"
          type="datetime-local"
        ></v-text-field>
      </v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
          v-model="updatedSearchParams.tripStartedTo"
          label="Trip started to"
          type="datetime-local"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols=12 md=2 class="pl-0 py-0">Trip ended</v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
          v-model="updatedSearchParams.tripEndedFrom"
          label="Trip ended from"
          type="datetime-local"
        ></v-text-field>
      </v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
          v-model="updatedSearchParams.tripEndedTo"
          label="Trip ended to"
          type="datetime-local"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>

    </v-row>
    </v-form>
</template>

<script>
import _ from "lodash";
import { TransportEventStatusMixin } from "@/models/TransportEventStatusTypes";
import { TranportEventValidationErrorMixin } from "@/models/ValidationErrors";
import { TransportEventInvoicingMixin } from "@/models/TransportEventInvoicingStates";

export default {
  props: {
    searchParams: Object
  },
  mixins: [ TransportEventStatusMixin, TranportEventValidationErrorMixin, TransportEventInvoicingMixin ],
  data () {
    return {
      updatedSearchParams: this.formatSearchParams(this.searchParams),
      searchParamsValid: true
    };
  },
  watch: {
    searchParams (val) {
      this.updatedSearchParams = this.formatSearchParams(this.searchParams);
    }
  },
  methods: {
    formatSearchParams (searchParams) {
      let params = {};
      if (!searchParams) return params;
      if (searchParams.validationStates) {
        params.validationStates = _.cloneDeep(searchParams.validationStates);
      } else {
        params.validationStates = [];
      }
      if (searchParams.validationFailReasonCodes) {
        params.validationFailReasonCodes = _.cloneDeep(searchParams.validationFailReasonCodes);
      }
      if (searchParams.salesInvoiceStates) {
        params.salesInvoiceStates = _.cloneDeep(searchParams.salesInvoiceStates);
      } else {
        params.salesInvoiceStates = [];
      }
      if (searchParams.salesInvoiceNumbers) {
        params.salesInvoiceNumbers = _.cloneDeep(searchParams.salesInvoiceNumbers);
      }
      if (searchParams.purchaseInvoiceStates) {
        params.purchaseInvoiceStates = _.cloneDeep(searchParams.purchaseInvoiceStates);
      } else {
        params.purchaseInvoiceStates = [];
      }
      if (searchParams.purchaseInvoiceNumbers) {
        params.purchaseInvoiceNumbers = _.cloneDeep(searchParams.purchaseInvoiceNumbers);
      }
      if (searchParams.carNumber) {
        params.carNumber = _.cloneDeep(searchParams.carNumber);
      }
      if (searchParams.cardNumber) {
        params.cardNumber = _.cloneDeep(searchParams.cardNumber);
      }
      if (searchParams.taximeterSerialNumber) {
        params.taximeterSerialNumber = _.cloneDeep(searchParams.taximeterSerialNumber);
      }
      if (searchParams.modifiedFrom) {
        params.modifiedFrom = _.cloneDeep(searchParams.modifiedFrom);
      }
      if (searchParams.modifiedTo) {
        params.modifiedTo = _.cloneDeep(searchParams.modifiedTo);
      }
      if (searchParams.tripStartedFrom) {
        params.tripStartedFrom = _.cloneDeep(searchParams.tripStartedFrom);
      }
      if (searchParams.tripStartedTo) {
        params.tripStartedTo = _.cloneDeep(searchParams.tripStartedTo);
      }
      if (searchParams.tripEndedFrom) {
        params.tripEndedFrom = _.cloneDeep(searchParams.tripEndedFrom);
      }
      if (searchParams.tripEndedTo) {
        params.tripEndedTo = _.cloneDeep(searchParams.tripEndedTo);
      }
      return params;
    },
    setFailedValidations () {
      this.updatedSearchParams.validationStates = this.validationStatusFailTypes;
    },
    getSearchParameters () {
      if (!this.updatedSearchParams.validationStates.includes(this.validationStatusTypes.ExternalValidationFailed)) {
        delete this.updatedSearchParams.validationFailReasonCodes;
      }
      return this.updatedSearchParams;
    }
  }
};
</script>

<style>

</style>
