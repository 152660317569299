<template>
    <v-chip-group column dark>
        <template v-for="(fee, n) in fees">
            <v-tooltip :key="n" top>
                <template v-slot:activator="{ on }">
                    <v-chip
                        :key="n"
                        @click.stop="openFeeDialog(fee, n)"
                        close
                        @click:close="deleteFee(n)"
                        :disabled="readOnly"
                        close-icon="mdi-delete"
                        color="primary"
                        v-on="on"
                    >
                    <span v-if="useLikumaTexts">
                        {{ fee.type | feeTypeToLikumaText }}
                    </span>
                    <span v-else>
                        {{ fee.type | feeTypeToText }}
                    </span>
                    </v-chip>
                </template>
                <span>{{ fee | feeToToolTipText }}</span>
            </v-tooltip>
        </template>
        <v-chip
            v-if="!readOnly && (fees.length < maxAmount)"
            color="success"
            @click.stop="openNewFeeDialog"
        >
            Add new fee
            <v-icon right>mdi-plus</v-icon>
        </v-chip>
        <FeeDialog
            :isOpen="feeDialog"
            :originalFee="selectedFee"
            :isNewFee="newFee"
            :allowedFeeTypes="allowedFeeTypes"
            :useLikumaTexts="useLikumaTexts"
            @create="addFee"
            @save="modifyFee"
            @close="closeFeeDialog"
        ></FeeDialog>
    </v-chip-group>
</template>

<script>
import FeeDialog from "./FeeDialog";
import { FeeTypeMixin } from "../../models/FeeTypes";
import _ from "lodash";

export default {
  components: {
    FeeDialog
  },
  mixins: [FeeTypeMixin],
  props: {
    fees: {
      type: Array,
      default: () => []
    },
    allowedFeeTypes: Array,
    maxAmount: Number,
    readOnly: Boolean,
    useLikumaTexts: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      feeDialog: false,
      selectedFee: {},
      selectedFeeIndex: -1,
      newFee: true
    };
  },
  methods: {
    openNewFeeDialog () {
      this.selectedFee = {};
      this.newFee = true;
      this.feeDialog = true;
    },
    openFeeDialog (fee, index) {
      this.selectedFee = fee;
      this.selectedFeeIndex = index;
      this.newFee = false;
      this.feeDialog = true;
    },
    closeFeeDialog () {
      this.selectedFee = {};
      this.feeDialog = false;
    },
    addFee (fee) {
      let newFees = _.cloneDeep(this.fees);
      newFees.push(fee);
      this.$emit("update", newFees);
    },
    deleteFee (index) {
      let newFees = _.cloneDeep(this.fees);
      newFees.splice(index, 1);
      this.$emit("update", newFees);
    },
    modifyFee (fee) {
      let newFees = _.cloneDeep(this.fees);
      this.$set(newFees, this.selectedFeeIndex, fee);
      this.$emit("update", newFees);
    }
  }
};
</script>

<style>

</style>
