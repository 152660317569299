<template>
    <v-card class="mb-5">
        <v-container fluid class="mx-2">
            <v-row>
                <v-col>
                    <b>Price catalog:</b><br>
                    <b>{{ priceCatalog.name }}</b>
                    <v-icon color="primary" dense class="ml-2" @click.stop="openEditDialog">mdi-pencil</v-icon>
                </v-col>
                <v-col>
                    Valid through:<br>
                    {{ priceCatalog.validThrough | formatValidThrough }}
                </v-col>
            </v-row>
            <v-row>
                <v-col cols=6 sm=3>OperatorId:<br>{{ priceCatalog.operatorId }}</v-col>
                <v-col cols=6 sm=3>CustomerId:<br>{{ priceCatalog.customerId }}</v-col>
                <v-col cols=6 sm=3>VendorId:<br>{{ priceCatalog.vendorId }}</v-col>
                <v-col cols=6 sm=3>ProductId:<br>{{ priceCatalog.productId }}</v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="editDialog" persistent max-width="800">
            <v-card>
                <v-card-title>
                    <span class="headline">Edit price catalog</span>
                </v-card-title>
                <v-card-text>
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation>
                        <v-container>
                            <v-row>
                                <v-text-field
                                    v-model="newPriceCatalogInfo.name"
                                    :rules="[
                                    v => !!v || 'Name is required',
                                    v => !v || v.length < 100 || 'Name can not be longer than 50 characters'
                                    ]"
                                    counter="100"
                                    label="Name"
                                    required
                                ></v-text-field>
                            </v-row>
                            <v-row>
                                <v-text-field
                                    v-model="newPriceCatalogInfo.operatorId"
                                    label="OperatorId"
                                ></v-text-field>
                            </v-row>
                            <v-row>
                                <v-text-field
                                    v-model="newPriceCatalogInfo.customerId"
                                    label="CustomerId"
                                ></v-text-field>
                            </v-row>
                            <v-row>
                                <v-text-field
                                    v-model="newPriceCatalogInfo.vendorId"
                                    label="VendorId"
                                ></v-text-field>
                            </v-row>
                            <v-row>
                                <v-text-field
                                    v-model="newPriceCatalogInfo.productId"
                                    label="ProductId"
                                ></v-text-field>
                            </v-row>
                            <v-row><v-subheader>Valid through</v-subheader></v-row>
                            <v-row>
                                <v-col class="pl-0">
                                    <v-text-field
                                        v-model="newPriceCatalogInfo.validThrough.startTime"
                                        label="Start time"
                                        type="datetime-local"
                                        :rules="[
                                            v => !!v || 'Start time cannot be empty',
                                        ]"
                                    required
                                    ></v-text-field>
                                </v-col>
                                <v-col class="pl-0">
                                    <v-text-field
                                        v-model="newPriceCatalogInfo.validThrough.endTime"
                                        label="End time"
                                        type="datetime-local"
                                        :rules="[
                                            v => !!v || 'End time cannot be empty',
                                        ]"
                                    required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pb-4">
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="!valid"
                        :loading="isUpdating"
                        @click="saveCatalogInfo"
                        color="primary">
                        Save changes
                    </v-btn>
                    <v-btn
                        :disabled="isUpdating"
                        @click="closeDialog"
                        class="ml-4">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import moment from "moment";

export default {
  props: {
    priceCatalog: null
  },
  data () {
    return {
      editDialog: false,
      valid: true,
      isUpdating: false
    };
  },
  computed: {
    newPriceCatalogInfo () {
      return {
        name: this.priceCatalog.name,
        operatorId: this.priceCatalog.operatorId,
        customerId: this.priceCatalog.customerId,
        vendorId: this.priceCatalog.vendorId,
        productId: this.priceCatalog.productId,
        validThrough: {
          startTime: moment(this.priceCatalog.validThrough.startTime).format("YYYY-MM-DDTHH:mm:ss"),
          endTime: moment(this.priceCatalog.validThrough.endTime).format("YYYY-MM-DDTHH:mm:ss")
        }
      };
    }
  },
  methods: {
    openEditDialog () {
      this.editDialog = true;
    },
    saveCatalogInfo () {
      this.isUpdating = true;
      this.$emit("catalog-info-updated", this.newPriceCatalogInfo);
    },
    closeDialog () {
      this.isUpdating = false;
      this.editDialog = false;
    }
  }
};
</script>

<style>

</style>
