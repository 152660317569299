import { geoService } from "./repository";

const resource = "v1";

export default {
  calculateRoute (request, clientName) {
    return geoService.post(`${resource}/geo/calculateroute${clientName ? `?clientName=${clientName}` : ""}`, request);
  },
  calculateIsochrone (request, clientName) {
    return geoService.post(`${resource}/geo/calculateisochrone${clientName ? `?clientName=${clientName}` : ""}`, request);
  },
  geoCodeAddress (request, clientName) {
    return geoService.post(`${resource}/geo/geocodeaddress${clientName ? `?clientName=${clientName}` : ""}`, request);
  },
  getStationGeoJson () {
    return geoService.get(`${resource}/area/stations`);
  },
  getZoneGeoJson () {
    return geoService.get(`${resource}/area/zones`);
  }
};
