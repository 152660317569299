import { RepositoryFactory } from "@/data/repositories/repositoryFactory";
import moment from "moment";

const InvoicingRepository = RepositoryFactory.get("invoicing");

// initial state
const state = {
  searchedTransportEvents: [],
  searchParams: null,
  searchTotalCount: null,
  fetchedTimestamp: null,
  failReasons: null,
  salesInvoices: null,
  tripStatusesByDay: null,
  tripStatusesByTimeRange: null
};

// getters
const getters = {};

// actions
const actions = {
  async searchTransportEvents ({ commit }, { searchParams, top = 100, forceUpdate = false }) {
    if (!forceUpdate &&
            moment(state.fetchedTimeStamp).diff(moment(), "minutes") < 2 &&
            JSON.stringify(state.searchParams) === JSON.stringify(searchParams)) {
      return;
    }
    await InvoicingRepository.find(searchParams, top)
      .then(response => {
        commit("SET_TRANSPORT_EVENTS", response.data.results);
        commit("SET_SEARCH_TOTAL_COUNT", response.data.totalCount);
        commit("SET_SEARCH_PARAMS", JSON.parse(JSON.stringify(searchParams)));
        commit("SET_FETCHED_TIMESTAMP", moment());
      });
  },
  async loadMoreTransportEvents ({ commit }, { top = 100 }) {
    await InvoicingRepository.find(
      state.searchParams,
      top,
      Math.floor(state.searchedTransportEvents.length / top) + 1)
      .then(response => {
        commit("ADD_SEARCHED_TRANSPORT_EVENTS", response.data.results);
      });
  },
  async addTransportEvent ({ commit }, transportEvent) {
    await InvoicingRepository.create(transportEvent)
      .then(() => {
        commit("ADD_TRANSPORT_EVENT", transportEvent);
      });
  },
  async updateTransportEvent ({ commit }, transportEvent) {
    await InvoicingRepository.update(transportEvent)
      .then(() => {
        commit("UPDATE_TRANSPORT_EVENT", transportEvent);
      });
  },
  async deleteTransportEvent ({ commit }, id) {
    await InvoicingRepository.delete(id)
      .then(() => {
        commit("REMOVE_TRANSPORT_EVENT", id);
      });
  },
  async getFailReasons ({ commit }) {
    await InvoicingRepository.getFailReasons()
      .then((response) => {
        commit("SET_FAIL_REASONS", response.data);
      });
  },
  async getSalesInvoices ({ commit }) {
    await InvoicingRepository.getSalesInvoices()
      .then((response) => {
        commit("SET_SALES_INVOICES", response.data);
      });
  },
  async getPurchaseInvoices ({ commit }) {
    await InvoicingRepository.getPurchaseInvoices()
      .then((response) => {
        commit("SET_PURCHASE_INVOICES", response.data);
      });
  },
  async getTripStatusesByDay ({ commit }, { start, end }) {
    await InvoicingRepository.getTripStatusesByDay(start, end)
      .then((response) => {
        commit("SET_TRIP_STATUSES_BY_DAY", response.data);
      });
  },
  async getTripStatusesByTimeRange ({ commit }, { start, end }) {
    await InvoicingRepository.getTripStatusesByTimeRange(start, end)
      .then((response) => {
        commit("SET_TRIP_STATUSES_BY_TIME_RANGE", response.data);
      });
  }
};

// mutations
const mutations = {
  SET_TRANSPORT_EVENTS (state, transportEvents) {
    state.searchedTransportEvents = transportEvents;
  },
  ADD_SEARCHED_TRANSPORT_EVENTS (state, transportEvents) {
    state.searchedTransportEvents = state.searchedTransportEvents.concat(transportEvents);
  },
  SET_SEARCH_TOTAL_COUNT (state, searchTotalCount) {
    state.searchTotalCount = searchTotalCount;
  },
  SET_SEARCH_PARAMS (state, searchParams) {
    state.searchParams = searchParams;
  },
  SET_FETCHED_TIMESTAMP (state, timestamp) {
    state.fetchedTimestamp = timestamp;
  },
  ADD_TRANSPORT_EVENT (state, transportEvent) {
    state.searchedTransportEvents.push(transportEvent);
  },
  UPDATE_TRANSPORT_EVENT (state, transportEvent) {
    state.searchedTransportEvents = [
      ...state.searchedTransportEvents.map(
        item => item.id !== transportEvent.id ? item : { ...item, ...transportEvent })
    ];
  },
  REMOVE_TRANSPORT_EVENT (state, id) {
    let index = state.searchedTransportEvents.findIndex(te => te.id === id);
    state.searchedTransportEvents.splice(index, 1);
  },
  SET_FAIL_REASONS (state, failReasons) {
    state.failReasons = failReasons;
  },
  SET_SALES_INVOICES (state, salesInvoices) {
    state.salesInvoices = salesInvoices;
  },
  SET_PURCHASE_INVOICES (state, purchaseInvoices) {
    state.purchaseInvoices = purchaseInvoices;
  },
  SET_TRIP_STATUSES_BY_DAY (state, tripStatusesByDay) {
    state.tripStatusesByDay = tripStatusesByDay;
  },
  SET_TRIP_STATUSES_BY_TIME_RANGE (state, tripStatusesByTimeRange) {
    state.tripStatusesByTimeRange = tripStatusesByTimeRange;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
