var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.transportEvent
    ? _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c("v-subheader", { staticClass: "mt-3" }, [
            _vm._v("Kela matkatiedot")
          ]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Tilaustunnus",
                      disabled: _vm.readOnly || !_vm.isNew,
                      rules: [
                        _vm.requiredRule,
                        _vm.digitRule,
                        _vm.maxLengthRule(12)
                      ],
                      counter: "12",
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.orderId,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent, "orderId", $$v)
                      },
                      expression: "transportEvent.orderId"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Erätunnus",
                      disabled: _vm.readOnly || !_vm.isNew,
                      rules: [
                        _vm.requiredRule,
                        _vm.digitRule,
                        _vm.maxLengthRule(12)
                      ],
                      counter: "12",
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.batchNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent, "batchNumber", $$v)
                      },
                      expression: "transportEvent.batchNumber"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Tilaustunnus manuaalinen",
                      disabled: _vm.readOnly || !_vm.isNew,
                      rules: [
                        _vm.requiredRule,
                        _vm.digitRule,
                        _vm.maxLengthRule(12)
                      ],
                      counter: "12",
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.manualOrderId,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent, "manualOrderId", $$v)
                      },
                      expression: "transportEvent.manualOrderId"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Korttinumero",
                      disabled: _vm.readOnly,
                      rules: [_vm.requiredRule, _vm.maxLengthRule(20)],
                      counter: "20",
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.customer.cardNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.customer, "cardNumber", $$v)
                      },
                      expression: "transportEvent.customer.cardNumber"
                    }
                  }),
                  _c("v-select", {
                    attrs: {
                      label: "Tilausvälityskeskus",
                      disabled: _vm.readOnly,
                      items: _vm.decisionIds,
                      rules: [_vm.requiredRule],
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.trip.operatorId,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "operatorId", $$v)
                      },
                      expression: "transportEvent.trip.operatorId"
                    }
                  }),
                  _c("v-select", {
                    attrs: {
                      label: "Varmennusnumero",
                      disabled: _vm.readOnly,
                      items: _vm.tripTypes,
                      rules: [_vm.requiredRule],
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.fare.confirmationNumber,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.transportEvent.fare,
                          "confirmationNumber",
                          $$v
                        )
                      },
                      expression: "transportEvent.fare.confirmationNumber"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Alkuaika",
                      disabled: _vm.readOnly,
                      rules: [_vm.requiredRule, _vm.dateTimeRule],
                      hint: "yyyy-MM-dd HH:mm:ss",
                      placeholder: "yyyy-MM-dd HH:mm:ss",
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.trip.started,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "started", $$v)
                      },
                      expression: "transportEvent.trip.started"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Loppuaika",
                      disabled: _vm.readOnly,
                      rules: [_vm.requiredRule, _vm.dateTimeRule],
                      hint: "yyyy-MM-dd HH:mm:ss",
                      placeholder: "yyyy-MM-dd HH:mm:ss",
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.trip.ended,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "ended", $$v)
                      },
                      expression: "transportEvent.trip.ended"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Kokonaismetri",
                      disabled: _vm.readOnly,
                      rules: [
                        _vm.requiredRule,
                        _vm.digitRule,
                        _vm.maxLengthRule(6)
                      ],
                      counter: "6",
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.fare.distance,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "distance", $$v)
                      },
                      expression: "transportEvent.fare.distance"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Odotus",
                      disabled: _vm.readOnly,
                      rules: [
                        _vm.requiredRule,
                        _vm.digitRule,
                        _vm.maxLengthRule(4)
                      ],
                      counter: "4",
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.fare.waitingTime,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "waitingTime", $$v)
                      },
                      expression: "transportEvent.fare.waitingTime"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Autonumero",
                      disabled: _vm.readOnly,
                      rules: [_vm.requiredRule, _vm.maxLengthRule(8)],
                      counter: "8",
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.trip.carNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "carNumber", $$v)
                      },
                      expression: "transportEvent.trip.carNumber"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Taksamittarin sarjanumero",
                      disabled: _vm.readOnly,
                      rules: [_vm.requiredRule, _vm.maxLengthRule(14)],
                      counter: "14",
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.fare.taximeterSerialNumber,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.transportEvent.fare,
                          "taximeterSerialNumber",
                          $$v
                        )
                      },
                      expression: "transportEvent.fare.taximeterSerialNumber"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Kuljettaja Id",
                      disabled: _vm.readOnly,
                      rules: [_vm.maxLengthRule(12)],
                      counter: "12"
                    },
                    model: {
                      value: _vm.transportEvent.trip.driverId,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "driverId", $$v)
                      },
                      expression: "transportEvent.trip.driverId"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Palvelutuottaja",
                      disabled: _vm.readOnly,
                      rules: [_vm.maxLengthRule(12)],
                      counter: "12"
                    },
                    model: {
                      value: _vm.transportEvent.serviceProvider,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent, "serviceProvider", $$v)
                      },
                      expression: "transportEvent.serviceProvider"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Lähtöosoite",
                      disabled: _vm.readOnly,
                      rules: [_vm.maxLengthRule(100)],
                      counter: "100"
                    },
                    model: {
                      value: _vm.transportEvent.trip.startingPoint,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "startingPoint", $$v)
                      },
                      expression: "transportEvent.trip.startingPoint"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Määränpää",
                      disabled: _vm.readOnly,
                      rules: [_vm.maxLengthRule(100)],
                      counter: "100"
                    },
                    model: {
                      value: _vm.transportEvent.trip.destination,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "destination", $$v)
                      },
                      expression: "transportEvent.trip.destination"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-subheader", { staticClass: "mt-3" }, [
            _vm._v("Kela maksutiedot")
          ]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Kuittinumero",
                      disabled: _vm.readOnly,
                      rules: [
                        _vm.requiredRule,
                        _vm.digitRule,
                        _vm.maxLengthRule(8)
                      ],
                      counter: "8",
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.fare.receiptNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "receiptNumber", $$v)
                      },
                      expression: "transportEvent.fare.receiptNumber"
                    }
                  }),
                  _c("v-select", {
                    attrs: {
                      items: [10.0, 24.0],
                      rules: [_vm.requiredRule],
                      label: "ALV",
                      disabled: _vm.readOnly,
                      required: ""
                    },
                    on: { input: _vm.calculateSums },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selection",
                          fn: function(ref) {
                            var item = ref.item
                            return [_vm._v(" " + _vm._s(item) + " % ")]
                          }
                        },
                        {
                          key: "item",
                          fn: function(ref) {
                            var item = ref.item
                            return [_vm._v(" " + _vm._s(item) + " % ")]
                          }
                        }
                      ],
                      null,
                      false,
                      3334045436
                    ),
                    model: {
                      value: _vm.transportEvent.fare.vatPercent,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "vatPercent", $$v)
                      },
                      expression: "transportEvent.fare.vatPercent"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Kustannus",
                      disabled: _vm.readOnly,
                      type: "number",
                      rules: [
                        _vm.requiredRule,
                        _vm.decimalRule,
                        _vm.maxLengthWithoutDecimalSeparatorRule(8)
                      ],
                      counter: "9",
                      required: ""
                    },
                    on: { input: _vm.calculateSums },
                    model: {
                      value: _vm.transportEvent.fare.cost,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "cost", $$v)
                      },
                      expression: "transportEvent.fare.cost"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Korvaus",
                      disabled: "",
                      type: "number",
                      rules: [
                        _vm.requiredRule,
                        _vm.decimalRule,
                        _vm.maxLengthWithoutDecimalSeparatorRule(8)
                      ],
                      counter: "9",
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.fare.reimbursement,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "reimbursement", $$v)
                      },
                      expression: "transportEvent.fare.reimbursement"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Korvaus ALV",
                      disabled: "",
                      type: "number",
                      rules: [
                        _vm.decimalRule,
                        _vm.maxLengthWithoutDecimalSeparatorRule(8)
                      ],
                      counter: "9"
                    },
                    model: {
                      value: _vm.transportEvent.fare.reimbursementVat,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.transportEvent.fare,
                          "reimbursementVat",
                          $$v
                        )
                      },
                      expression: "transportEvent.fare.reimbursementVat"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Muutos",
                      disabled: _vm.readOnly,
                      type: "number",
                      rules: [
                        _vm.decimalRule,
                        _vm.maxLengthWithoutDecimalSeparatorRule(8)
                      ],
                      counter: "9"
                    },
                    model: {
                      value: _vm.transportEvent.fare.discountAmount,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "discountAmount", $$v)
                      },
                      expression: "transportEvent.fare.discountAmount"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Omavastuu",
                      disabled: _vm.readOnly,
                      type: "number",
                      rules: [
                        _vm.requiredRule,
                        _vm.decimalRule,
                        _vm.maxLengthWithoutDecimalSeparatorRule(6)
                      ],
                      counter: "7",
                      required: ""
                    },
                    on: { input: _vm.calculateSums },
                    model: {
                      value: _vm.transportEvent.fare.deductible,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "deductible", $$v)
                      },
                      expression: "transportEvent.fare.deductible"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Omavastuu ALV",
                      disabled: "",
                      type: "number",
                      rules: [
                        _vm.decimalRule,
                        _vm.maxLengthWithoutDecimalSeparatorRule(6)
                      ],
                      counter: "7"
                    },
                    model: {
                      value: _vm.transportEvent.fare.deductibleVat,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "deductibleVat", $$v)
                      },
                      expression: "transportEvent.fare.deductibleVat"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Odotus",
                      disabled: _vm.readOnly,
                      type: "number",
                      rules: [
                        _vm.decimalRule,
                        _vm.maxLengthWithoutDecimalSeparatorRule(6)
                      ],
                      counter: "7"
                    },
                    on: { input: _vm.calculateSums },
                    model: {
                      value: _vm.transportEvent.fare.waitingCost,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "waitingCost", $$v)
                      },
                      expression: "transportEvent.fare.waitingCost"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Odotus ALV",
                      disabled: "",
                      type: "number",
                      rules: [
                        _vm.decimalRule,
                        _vm.maxLengthWithoutDecimalSeparatorRule(6)
                      ],
                      counter: "7"
                    },
                    model: {
                      value: _vm.transportEvent.fare.waitingVat,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "waitingVat", $$v)
                      },
                      expression: "transportEvent.fare.waitingVat"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-subheader", [_vm._v("Taksat")]),
          _c("FeeChipGroup", {
            ref: "feeChipGroup",
            attrs: {
              fees: _vm.transportEvent.fare.fees,
              allowedFeeTypes: _vm.extraChargeTypes,
              maxAmount: 5,
              readOnly: _vm.readOnly,
              useLikumaTexts: true
            },
            on: { update: _vm.updateFees }
          }),
          _c("v-subheader", [_vm._v("Lisät")]),
          _c("FeeChipGroup", {
            ref: "feeChipGroup",
            attrs: {
              fees: _vm.transportEvent.fare.extras,
              allowedFeeTypes: _vm.extraChargeTypes,
              maxAmount: 5,
              readOnly: _vm.readOnly,
              useLikumaTexts: true
            },
            on: { update: _vm.updateFees }
          }),
          _c(
            "v-row",
            [
              _c("v-textarea", {
                staticClass: "mt-3 mx-2",
                attrs: {
                  value: _vm.transportEvent.Comments,
                  label: "Kommentit",
                  outlined: "",
                  rows: "3"
                }
              })
            ],
            1
          ),
          _c("v-switch", {
            staticClass: "mt-0",
            attrs: {
              label: "Merkitse lähetettäväksi Kelalle",
              disabled: _vm.readOnly,
              hint:
                "Tapahtumaa ei voi muokata ennen kuin Kelalta on tullut vastaus",
              "persistent-hint": ""
            },
            model: {
              value: _vm.toBeValidated,
              callback: function($$v) {
                _vm.toBeValidated = $$v
              },
              expression: "toBeValidated"
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }