<template>
  <v-card>
    <div class="elevation-1 mb-1 pb-2">
      <v-card-title>
        Transport Events
        <v-btn
          small
          @click="openTransportEventDialog(null)"
          color="primary"
          class="ml-3"
        >
          <v-icon left>mdi-plus</v-icon>
          Add new
        </v-btn>
      </v-card-title>
      <v-card-actions>

        <v-btn
        small
        @click="searchDialog = !searchDialog"
        class="mx-2">
          Change search criteria
        </v-btn>
        <v-dialog v-model="columnDialog" max-width="1000">
          <template v-slot:activator="{ on }">
            <v-btn small v-on="on" class="ml-2">
              Toggle columns
            </v-btn>
          </template>
          <v-card>
            <v-toolbar short dark color="primary">
              <v-btn icon dark @click="columnDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>
                Select showable columns
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn text dark @click="hideAllColumns">
                Hide all columns
              </v-btn>
              <v-btn text dark @click="showAllColumns">
                Show all columns
              </v-btn>
            </v-toolbar>
            <v-layout wrap align-center class="mx-3">
              <v-flex v-for="(header, n) in headers" :key="n" xs6 md3>
                <v-switch v-model="header.show" :label="header.text"></v-switch>
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-text-field v-model="filter" append-icon="mdi-magnify" label="Filter" single-line hide-details
          class="pt-0 mt-0 mr-2"></v-text-field>
      </v-card-actions>
    </div>
    <v-skeleton-loader :loading="loading" :transition-group="'scale-transition'" type="table">
      <v-data-table
        :headers="showableHeaders"
        :items="transportEvents"
        item-key="orderId"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :search="filter"
        :loading="loadingPage"
        :page.sync="page"
        :items-per-page.sync="itemsPerPage"
        :footer-props="{ pagination, itemsPerPageOptions: itemsPerPageOptions}"
        @click:row="openTransportEventDialog"
        @update:options="loadMoreTransportEvents"
      >
        <template v-slot:item.trip.started="{ value }">
          <span> {{ value | formatDateTime }}</span>
        </template>
        <template v-slot:item.trip.ended="{ value }">
          <span> {{ value | formatDateTime }}</span>
        </template>
        <template v-slot:item.modified="{ value }">
          <span> {{ value | formatToLocalTime | formatDateTime }}</span>
        </template>
        <template v-slot:item.sentToExternal="{ value }">
          <span> {{ value | formatToLocalTime | formatDateTime }}</span>
        </template>
        <template v-slot:item.confirmationFromExternal="{ value }">
          <span> {{ value | formatToLocalTime | formatDateTime }}</span>
        </template>
        <template v-slot:item.fare.cost="{ value }">
          <span> {{ value | formatPrice }}</span>
        </template>
        <template v-slot:item.validationStatus="{ value, item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" :color="value | validationStatusToColor">{{ value | validationStatusToIcon }}</v-icon>
            </template>
            <span>{{ value | validationStatusToText }}</span>
            <span v-if="item.validationFailReason">
              <br>Validation fail reason: {{ item.validationFailReason }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.eventStatus="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" :color="value | transportStatusToColor">{{ value | transportStatusToIcon }}</v-icon>
            </template>
            <span>{{ value | transportStatusToText }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.invoicingStatus="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" :color="value | statusToColor">{{ value | statusToIcon }}</v-icon>
            </template>
            <span>{{ value | statusToToolTipText }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.settlementStatus="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" :color="value | statusToColor">{{ value | statusToIcon }}</v-icon>
            </template>
            <span>{{ value | statusToToolTipText }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.fare.extras="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                {{ (!value ? 0 : value.reduce((a, b) => ({ price: a.price + b.price }), {price: 0.00}).price) | formatPrice }}
              </span>
            </template>
            <ul class="extras-list">
              <li>Pre-booking Extra:
                <span>{{ (!value ? 0 : value.filter(extra => extra.type === feeTypes.PrebookFee ).reduce((a, b) => ({ price: a.price + b.price }), {price: 0.00}).price) | formatPrice }}</span>
              </li>
              <li>Goods Extra:
                <span>{{ (!value ? 0 : value.filter(extra => extra.type === feeTypes.GoodsFee ).reduce((a, b) => ({ price: a.price + b.price }), {price: 0.00}).price) | formatPrice }}</span>
              </li>
              <li>Airport Extra:
                <span>{{ (!value ? 0 : value.filter(extra => extra.type === feeTypes.AirportFee ).reduce((a, b) => ({ price: a.price + b.price }), {price: 0.00}).price) | formatPrice }}</span>
              </li>
              <li>Service Extra:
                <span>{{ (!value ? 0 : value.filter(extra => extra.type === feeTypes.ServiceFee ).reduce((a, b) => ({ price: a.price + b.price }), {price: 0.00}).price) | formatPrice }}</span>
              </li>
              <li>Aid Extra:
                <span>{{ (!value ? 0 : value.filter(extra => extra.type === feeTypes.AidFee ).reduce((a, b) => ({ price: a.price + b.price }), {price: 0.00}).price) | formatPrice }}</span>
              </li>
              <li>Handicap Extra:
                <span>{{ (!value ? 0 : value.filter(extra => extra.type === feeTypes.HandicapFee ).reduce((a, b) => ({ price: a.price + b.price }), {price: 0.00}).price) | formatPrice }}</span>
              </li>
              <li>Other Extra:
                <span>{{ (!value ? 0 : value.filter(extra => extra.type === feeTypes.Unknown ).reduce((a, b) => ({ price: a.price + b.price }), {price: 0.00}).price) | formatPrice }}</span>
              </li>
            </ul>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-skeleton-loader>
    <TransportEventSearchDialog
      :show="searchDialog"
      :params="searchParams"
      @search="searchTransportEvents"
      @close="searchDialog = false"
    ></TransportEventSearchDialog>
    <TransportEventDialog
      :isOpen="transportEventDialog"
      :value="selectedTransportEvent"
      @save="transportEventSaved"
      @delete="transportEventDeleted"
      @close="closeTransportEventDialog"
    ></TransportEventDialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
import TransportEventDialog from "./TransportEventDialog";
import TransportEventSearchDialog from "./TransportEventSearchDialog";
import { TransportEventStatusMixin } from "@/models/TransportEventStatusTypes";
import { FeeTypeMixin } from "@/models/FeeTypes";
import InputRuleMixin from "@/components/Inputs/InputRuleMixin";
import moment from "moment";

export default {
  components: { TransportEventDialog, TransportEventSearchDialog },
  mixins: [ TransportEventStatusMixin, FeeTypeMixin, InputRuleMixin ],
  data () {
    return {
      loading: true,
      filter: "",
      columnDialog: false,
      searchDialog: false,
      headers: [
        { text: "Order Id", value: "orderId", show: true },
        // { text: "Event Status", value: "eventStatus", show: false },
        { text: "Validation Status", value: "validationStatus", show: true },
        { text: "Modified", value: "modified", show: true, width: 110 },
        { text: "Modifier", value: "modifier", show: false },
        // { text: "Invoicing Status", value: "invoicingStatus", show: false },
        // { text: "Settlement Status", value: "settlementStatus", show: false },
        { text: "Customer Id", value: "customer.customerId", show: false },
        { text: "Customer Name", value: "customer.name", show: false },
        { text: "Receipt Number", value: "fare.receiptNumber", show: true },
        { text: "Card Number", value: "customer.cardNumber", show: true },
        { text: "Car Number", value: "trip.carNumber", show: true },
        { text: "Cost", value: "fare.cost", show: true },
        { text: "Vat Percent", value: "fare.vatPercent", show: true },
        { text: "Trip Started", value: "trip.started", show: true, width: 110 },
        { text: "Trip Ended", value: "trip.ended", show: true, width: 110 },
        // { text: "Sent To External System", value: "sentToExternal", show: false, width: 110 },
        // { text: "Confirmation From External System", value: "confirmationFromExternal", show: false, width: 110 },
        { text: "Card Product Id", value: "customer.cardProductId", show: false },
        { text: "Card Product Name", value: "customer.cardProductName", show: false },
        { text: "Cardholder", value: "customer.cardHolder", show: false },
        { text: "Passenger Name", value: "trip.passengerName", show: false },
        { text: "Invoicing Date", value: "invoicingDate", show: false },
        { text: "Settlement Date", value: "settlementDate", show: false },
        { text: "Invoicing Label", value: "customer.invoicingLabel", show: false },
        { text: "Cost Centre Id", value: "customer.costCentreId", show: false },
        { text: "Cost Centre Name", value: "customer.costCentreName", show: false },
        { text: "Welfare Trip Type", value: "trip.welfareTripType", show: true },
        { text: "Trip Type", value: "trip.tripType", show: true },
        { text: "Route Number", value: "trip.routeNumber", show: false },
        { text: "Extras", value: "fare.extras", show: true },
        { text: "Discount Amount", value: "fare.discountAmount", show: true },
        { text: "Deductible", value: "fare.deductible", show: true },
        { text: "Deductible VAT", value: "fare.deductibleVat", show: true },
        { text: "Reimbursement", value: "fare.reimbursement", show: true },
        { text: "Reimbursement VAT", value: "fare.reimbursementVat", show: true },
        { text: "Waiting Cost", value: "fare.waitingCost", show: true },
        { text: "Waiting VAT", value: "fare.waitingVat", show: true },
        { text: "Starting Point", value: "trip.startingPoint", show: true },
        { text: "Destination", value: "trip.destination", show: true },
        { text: "Order Info", value: "orderInfo", show: false },
        { text: "Distance", value: "fare.distance", show: true },
        { text: "Duration", value: "fare.duration", show: true },
        { text: "Slow Drive", value: "fare.slowDrive", show: false },
        { text: "Waiting Time", value: "fare.waitingTime", show: true },
        { text: "Driver Id", value: "trip.driverId", show: true },
        { text: "Shift Number", value: "trip.shiftNumber", show: false },
        { text: "Taximeter Serial Number", value: "fare.taximeterSerialNumber", show: true },
        { text: "Car Pool", value: "trip.carPool", show: true },
        { text: "PaymentType", value: "fare.paymentType", show: false },
        { text: "HighTariff", value: "fare.highTariff", show: true },
        { text: "Likuma TripId", value: "likumaTripId", show: true },
        { text: "Likuma CertificateId", value: "likumaCertificateId", show: true }
      ],
      sortBy: "modified",
      sortDesc: true,
      page: 1,
      itemsPerPage: 10,
      loadingPage: false,
      searchParams: {
        validationStates: [],
        modifiedFrom: moment().subtract(14, "days").format()
      },
      itemsPerPageOptions: [5, 10, 20, 50, 100],
      transportEventDialog: false,
      selectedTransportEvent: null,
      snackbar: false,
      snackbarText: "",
      snackbarColor: ""
    };
  },
  computed: {
    transportEvents () {
      return this.$store.state.invoicing.searchedTransportEvents;
    },
    searchTotalCount () {
      return this.$store.state.invoicing.searchTotalCount;
    },
    showableHeaders () {
      return this.headers.filter(h => h.show);
    },
    pagination () {
      return {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        pageStart: ((this.page - 1) * this.itemsPerPage),
        pageStop: (this.page * this.itemsPerPage),
        pageCount: Math.ceil(this.searchTotalCount / this.itemsPerPage),
        itemsLength: this.searchTotalCount
      };
    }
  },
  methods: {
    async getTransportEvents (searchParams = null) {
      this.loading = true;
      this.$store.dispatch("invoicing/searchTransportEvents", { searchParams, top: 100 })
        .catch(error => {
          this.snackbar = true;
          this.snackbarColor = "error";
          this.snackbarText = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async loadMoreTransportEvents () {
      if (!this.transportEvents) return;
      if (this.transportEvents.length === this.searchTotalCount) return;
      if ((this.page + 1) * this.itemsPerPage < this.transportEvents.length) {
        return;
      }
      this.loadingPage = true;
      this.$store.dispatch("invoicing/loadMoreTransportEvents", {
        top: 100
      })
        .catch(error => {
          this.snackbar = true;
          this.snackbarColor = "error";
          this.snackbarText = error;
        })
        .finally(() => {
          this.loadingPage = false;
        });
    },
    openTransportEventDialog (transportEvent) {
      this.selectedTransportEvent = transportEvent;
      this.transportEventDialog = true;
    },
    transportEventSaved (transportEvent) {
      this.snackbarText = "Transport event was successfully saved.";
      this.snackbarColor = "success";
      this.snackbar = true;
      this.closeTransportEventDialog();
    },
    transportEventDeleted () {
      this.snackbarText = "Transport event was successfully deleted.";
      this.snackbarColor = "success";
      this.snackbar = true;
      this.closeTransportEventDialog();
    },
    closeTransportEventDialog () {
      this.selectedTransportEvent = null;
      this.transportEventDialog = false;
    },
    hideAllColumns () {
      for (let i = 0; i < this.headers.length; i++) {
        this.headers[i].show = false;
      }
    },
    showAllColumns () {
      for (let i = 0; i < this.headers.length; i++) {
        this.headers[i].show = true;
      }
    },
    searchTransportEvents (searchParams = null) {
      if (searchParams) this.searchParams = searchParams;
      this.getTransportEvents(this.searchParams, 100);
      this.searchDialog = false;
    }
  },
  filters: {
    statusToColor: function (value) {
      switch (value) {
      case 1:
      case 2:
        return "";
      case 3: return "success";
      default: return "error";
      };
    },
    statusToIcon: function (value) {
      switch (value) {
      case 1:
      case 2:
        return "mdi-av-timer";
      case 3: return "mdi-check-circle-outline";
      default: return "mdi-alert";
      };
    },
    statusToToolTipText: function (value) {
      switch (value) {
      case 1:
      case 2:
        return "Waiting";
      case 3: return "No errors";
      default: return "Contains error(s)";
      };
    }
  },
  created () {
    this.searchTransportEvents(this.$route.query);
  }
};
</script>

<style scoped>
.v-text-field_search {
    padding-top: 0;
    margin-top: 0;
}
.extras-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.extras-list > li > span:last-of-type {
    float: right;
    margin-left:10px
}
</style>
