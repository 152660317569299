var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customer
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("h1", [_vm._v(_vm._s(_vm.customer.name))]),
          _c(
            "v-card",
            { staticClass: "d-flex mb-6", attrs: { flat: "", tile: "" } },
            [
              _c(
                "v-card",
                { staticClass: "pa-2", attrs: { outlined: "", tile: "" } },
                [
                  _c("strong", [_vm._v("Id:")]),
                  _vm._v(" " + _vm._s(_vm.customer.customerIdentifier) + " ")
                ]
              ),
              _c("v-card", { staticClass: "pa-2", attrs: { outlined: "" } }, [
                _c("strong", [_vm._v("Operator:")]),
                _vm._v(" " + _vm._s(_vm.customer.operatorIdentifier) + " ")
              ]),
              _vm.customer.attributes
                ? _vm._l(Object.keys(_vm.customer.attributes), function(key) {
                    return _c(
                      "v-card",
                      {
                        key: key,
                        staticClass: "pa-2",
                        attrs: { outlined: "" }
                      },
                      [
                        _c("strong", [_vm._v(_vm._s(key) + ":")]),
                        _vm._v(" " + _vm._s(_vm.customer.attributes[key]) + " ")
                      ]
                    )
                  })
                : _vm._e()
            ],
            2
          ),
          _vm.users
            ? _c("v-data-table", {
                attrs: { items: _vm.users, headers: _vm.headers },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "top",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "pl-5" },
                            [
                              _c("UserDialog", {
                                on: { userCreated: _vm.createUser }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "item.validThrough",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("formatValidThrough")(item.validThrough)
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1180912561
                )
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }