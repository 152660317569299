import ValidationsRepository from "./validationsRepository";
import PricingRepository from "./pricingRepository";
import PaymentRepository from "./paymentRepository";
import InvoicingRepository from "./invoicingRepository";
import GeoRepository from "./geoRepository";

const repositories = {
  validations: ValidationsRepository,
  pricing: PricingRepository,
  payment: PaymentRepository,
  invoicing: InvoicingRepository,
  geo: GeoRepository
};

export const RepositoryFactory = {
  get: name => repositories[name]
};
