
import moment from "moment";

let requiredRule = v => (!!v || v === 0) || "Value is required";
let digitRule = v => /^\d+$/.test(v) || "Value must contain only digits";
let decimalRule = v => /^\d+((.|,)\d+)?$/.test(v) || "Value is not valid decimal value";
let dateTimeRule = v => isValidDateTime(v) || "Value is not valid datetime";

let isValidDateTime = (value) => moment(value).isValid();

export default {
  data () {
    return {
      requiredRule: requiredRule,
      digitRule: digitRule,
      decimalRule: decimalRule,
      dateTimeRule: dateTimeRule
    };
  },
  methods: {
    maxLengthRule (maxLength) {
      return v => (v || "").toString().length <= maxLength ||
                `Value cannot be longer than ${maxLength} characters`;
    },
    maxLengthWithoutDecimalSeparatorRule (maxLength) {
      return v => (v || "").toString().replace(".", "").replace(",", "").length <= maxLength ||
                `Value cannot be longer than ${maxLength} characters without decimal point`;
    },
    valueInRangeRule (min, max) {
      return v => (!isNaN(v) && v <= max && v >= min) ||
                `Value must be in range from ${min} to ${max}`;
    }
  }
};
