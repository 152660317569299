<template>
    <v-chip-group column dark>
        <template v-for="(rule, n) in rules">
            <v-tooltip :key="n" top>
                <template v-slot:activator="{ on }">
                    <v-chip
                        :key="n"
                        @click.stop="openRuleDialog(rule, n)"
                        close
                        @click:close="deleteRule(n)"
                        close-icon="mdi-delete"
                        color="primary"
                        v-on="on"
                    >
                        {{rule.name || formatRuleTypeToString(rule.ruleType) }}
                    </v-chip>
                </template>
                <span>{{formatRuleToToolTip(rule)}}</span>
            </v-tooltip>
        </template>
        <v-chip
            color="success"
            @click.stop="openNewRuleDialog"
        >
            Add new rule
            <v-icon right>mdi-plus</v-icon>
        </v-chip>
        <RuleDialog
            :isOpen="ruleDialog"
            :originalRule="selectedRule"
            :isNewRule="newRule"
            :allowedRuleTypes="allowedRuleTypes"
            :isUpdating="isUpdating"
            @create="addRule"
            @save="modifyRule"
            @close="closeRuleDialog"
        ></RuleDialog>
    </v-chip-group>
</template>

<script>
import RuleDialog from "./RuleDialog";
import RuleFormatterMixin from "./RuleFormatterMixin";
import _ from "lodash";

export default {
  components: {
    RuleDialog
  },
  mixins: [RuleFormatterMixin],
  props: {
    rules: {
      type: Array,
      default: () => []
    },
    allowedRuleTypes: Array,
    isUpdating: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ruleDialog: false,
      selectedRule: {},
      selectedRuleIndex: -1,
      newRule: true
    };
  },
  methods: {
    openNewRuleDialog () {
      this.selectedRule = {};
      this.newRule = true;
      this.ruleDialog = true;
    },
    openRuleDialog (rule, index) {
      this.selectedRule = rule;
      this.selectedRuleIndex = index;
      this.newRule = false;
      this.ruleDialog = true;
    },
    closeRuleDialog () {
      this.selectedRule = {};
      this.ruleDialog = false;
    },
    addRule (rule) {
      let newRules = _.cloneDeep(this.rules);
      newRules.push(rule);
      this.$emit("update", newRules);
    },
    deleteRule (index) {
      let newRules = _.cloneDeep(this.rules);
      newRules.splice(index, 1);
      this.$emit("update", newRules);
    },
    modifyRule (rule) {
      let newRules = _.cloneDeep(this.rules);
      this.$set(newRules, this.selectedRuleIndex, rule);
      this.$emit("update", newRules);
    }
  }
};
</script>

<style>

</style>
