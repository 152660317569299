var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "1000" },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { short: "", dark: "", color: "primary" } },
            [
              _c("v-card-title", [
                !_vm.value
                  ? _c("span", [_vm._v("Create transport event")])
                  : _c("span", [_vm._v("Edit transport event")])
              ])
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-select", {
                staticClass: "my-3",
                attrs: {
                  label: "Model",
                  items: _vm.models,
                  hint:
                    "Edit fields that are relevant to selected model. 'Transport event' model allows editing all possible fields.",
                  "persistent-hint": ""
                },
                model: {
                  value: _vm.selectedModel,
                  callback: function($$v) {
                    _vm.selectedModel = $$v
                  },
                  expression: "selectedModel"
                }
              }),
              _vm.readOnly
                ? _c(
                    "v-container",
                    [
                      _c("v-row", [
                        _vm._v(
                          " Transport event cannot be edited in its current state. "
                        ),
                        _c("br"),
                        _vm._v("Reason: " + _vm._s(_vm.readOnlyReason) + " ")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-slide-y-transition",
                { attrs: { group: "", "hide-on-leave": "" } },
                [
                  _vm.selectedModel === "Likuma"
                    ? _c("LikumaForm", {
                        key: "1",
                        ref: "form",
                        attrs: {
                          isNew: !_vm.value,
                          readOnly: _vm.readOnly,
                          originalTransportEvent: _vm.transportEvent
                        }
                      })
                    : _vm._e(),
                  _vm.selectedModel === "Kela"
                    ? _c("KelaForm", {
                        key: "2",
                        ref: "form",
                        attrs: {
                          isNew: !_vm.value,
                          readOnly: _vm.readOnly,
                          originalTransportEvent: _vm.transportEvent
                        }
                      })
                    : _vm.selectedModel === "Transport event"
                    ? _c("TransportEventForm", {
                        key: "3",
                        ref: "form",
                        attrs: { originalTransportEvent: _vm.transportEvent }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-4" },
            [
              _c("v-spacer"),
              !_vm.value
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "success",
                        loading: _vm.sending,
                        disabled: _vm.readOnly
                      },
                      on: { click: _vm.createTransportEvent }
                    },
                    [_vm._v(" Add payment event ")]
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.sending,
                        disabled: _vm.readOnly,
                        color: "primary"
                      },
                      on: { click: _vm.modifyTransportEvent }
                    },
                    [_vm._v(" Save changes ")]
                  ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              !!_vm.value
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        loading: _vm.sendingDelete,
                        disabled: _vm.readOnly,
                        color: "error"
                      },
                      on: { click: _vm.deleteTransportEvent }
                    },
                    [_vm._v(" Delete event ")]
                  )
                : _vm._e(),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "error" },
          model: {
            value: _vm.errorSnackbar,
            callback: function($$v) {
              _vm.errorSnackbar = $$v
            },
            expression: "errorSnackbar"
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.errorText) + " "),
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function($event) {
                  _vm.snackbar = false
                }
              }
            },
            [_vm._v(" Close ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }