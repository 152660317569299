var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbarColor },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function($event) {
                  _vm.snackbar = false
                }
              }
            },
            [_vm._v("Close")]
          )
        ],
        1
      ),
      _c(
        "v-card-title",
        [
          _vm._v(" Validations "),
          _c("v-spacer"),
          _c("v-text-field", {
            attrs: {
              label: "Search",
              "append-icon": "search",
              "single-line": "",
              "hide-details": ""
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          expanded: _vm.expandedItems,
          "show-expand": "",
          "single-expand": "",
          "item-key": "id",
          headers: _vm.headers,
          items: _vm.items,
          search: _vm.search
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "pl-5" },
                  [
                    _c("ValidationDialog", {
                      on: { createValidation: _vm.newValidation }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "expanded-item",
            fn: function(ref) {
              var headers = ref.headers
              var item = ref.item
              return [
                _c(
                  "td",
                  { attrs: { colspan: headers.length } },
                  [
                    _vm._l(item.rules, function(rule, i) {
                      return _c(
                        "v-tooltip",
                        {
                          key: rule.id,
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-chip",
                                      _vm._g(
                                        {
                                          staticClass: "white--text mr-2",
                                          attrs: {
                                            close: "",
                                            color:
                                              i % 2 == 0
                                                ? "light-blue darken-1"
                                                : "light-blue darken-3"
                                          },
                                          on: {
                                            "click:close": function($event) {
                                              return _vm.removeRule(
                                                item,
                                                rule,
                                                i
                                              )
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [_vm._v(_vm._s(rule.ruleType))]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_c("span", [_vm._v(_vm._s(rule.name))])]
                      )
                    }),
                    _c(
                      "v-dialog",
                      {
                        attrs: { "max-width": "800px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-chip",
                                    _vm._g({ attrs: { color: "success" } }, on),
                                    [
                                      _c("v-icon", [_vm._v("mdi-plus")]),
                                      _vm._v("Add rule ")
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.ruleDialog,
                          callback: function($$v) {
                            _vm.ruleDialog = $$v
                          },
                          expression: "ruleDialog"
                        }
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-card-title",
                              [
                                _c("span", { staticClass: "headline" }, [
                                  _vm._v(
                                    "Add new rule" +
                                      _vm._s(
                                        _vm.notOrRule === undefined
                                          ? ""
                                          : _vm.notOrRule === 0
                                          ? "s (OR)"
                                          : "s (NOT)"
                                      )
                                  )
                                ]),
                                _c("v-spacer"),
                                _vm.notOrRule >= 0
                                  ? _c("v-text-field", {
                                      attrs: { label: "Collection name" },
                                      model: {
                                        value: _vm.ruleCollection.name,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ruleCollection,
                                            "name",
                                            $$v
                                          )
                                        },
                                        expression: "ruleCollection.name"
                                      }
                                    })
                                  : _vm._e(),
                                _c("v-spacer"),
                                _c(
                                  "v-btn-toggle",
                                  {
                                    model: {
                                      value: _vm.notOrRule,
                                      callback: function($$v) {
                                        _vm.notOrRule = $$v
                                      },
                                      expression: "notOrRule"
                                    }
                                  },
                                  [
                                    _c("v-btn", [_vm._v("OR")]),
                                    _c("v-btn", [_vm._v("NOT")])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-container",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Rule name",
                                        required: ""
                                      },
                                      model: {
                                        value: _vm.currentRule.ruleName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.currentRule,
                                            "ruleName",
                                            $$v
                                          )
                                        },
                                        expression: "currentRule.ruleName"
                                      }
                                    }),
                                    _vm.currentRule.ruleName
                                      ? _c("v-select", {
                                          attrs: {
                                            items: _vm.availableRules,
                                            label: "Select rule"
                                          },
                                          model: {
                                            value:
                                              _vm.currentRule.selectedRuleType,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.currentRule,
                                                "selectedRuleType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "currentRule.selectedRuleType"
                                          }
                                        })
                                      : _vm._e(),
                                    _c(
                                      "v-row",
                                      [
                                        _vm.currentRule.selectedRuleType ==
                                        "City"
                                          ? [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("CitySelector", {
                                                    on: {
                                                      citiesUpdated: function(
                                                        cities
                                                      ) {
                                                        return (_vm.currentRule.data = cities)
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm.currentRule.selectedRuleType ==
                                            "PaymentType"
                                          ? [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("PaymentTypeSelector", {
                                                    on: {
                                                      paymentUpdated: function(
                                                        payment
                                                      ) {
                                                        return (_vm.currentRule.data = payment)
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm.currentRule.selectedRuleType ==
                                            "TimeRange"
                                          ? [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("TimeRangeSelector", {
                                                    on: {
                                                      timeRangeUpdated: function(
                                                        timeRange
                                                      ) {
                                                        return (_vm.currentRule.data = timeRange)
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm.currentRule.selectedRuleType ==
                                            "DayTypeTimeRange"
                                          ? [
                                              _c(
                                                "v-col",
                                                [
                                                  _c(
                                                    "DayTypeTimeRangeSelector",
                                                    {
                                                      on: {
                                                        dayTypeTimeRangeUpdated: function(
                                                          dayTypeTimeRange
                                                        ) {
                                                          return (_vm.currentRule.data = dayTypeTimeRange)
                                                        }
                                                      }
                                                    }
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm.currentRule.selectedRuleType ==
                                            "CarType"
                                          ? [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("CarTypeSelector", {
                                                    on: {
                                                      carTypeUpdated: function(
                                                        carType
                                                      ) {
                                                        return (_vm.currentRule.data = carType)
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm.currentRule.selectedRuleType ==
                                            "Location"
                                          ? [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("LocationSelector", {
                                                    on: {
                                                      locationInfoUpdated: function(
                                                        locationInfo
                                                      ) {
                                                        return (_vm.currentRule.data = locationInfo)
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm.currentRule.selectedRuleType ==
                                            "Geofence"
                                          ? [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("GeoJsonSelector", {
                                                    on: {
                                                      geoJsonLoaded: function(
                                                        geoJson
                                                      ) {
                                                        return (_vm.currentRule.data = geoJson)
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                ),
                                _vm.notOrRule >= 0 &&
                                _vm.currentRule.selectedRuleType
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { right: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        staticClass: "mx-2",
                                                        attrs: {
                                                          small: "",
                                                          fab: "",
                                                          dark: "",
                                                          color: "success"
                                                        },
                                                        on: {
                                                          click: _vm.appendRule
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { dark: "" } },
                                                        [_vm._v("mdi-plus")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("span", [
                                          _vm._v("Add rule to collection")
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.ruleCollection.items.length
                                  ? _c("v-divider", { staticClass: "pb-4" })
                                  : _vm._e(),
                                _c(
                                  "v-chip-group",
                                  { attrs: { column: "" } },
                                  _vm._l(_vm.ruleCollection.items, function(
                                    rule,
                                    i
                                  ) {
                                    return _c(
                                      "v-chip",
                                      {
                                        key: i,
                                        attrs: {
                                          close: "",
                                          label: "",
                                          color: rule.color
                                        },
                                        on: {
                                          "click:close": function($event) {
                                            return _vm.ruleCollection.items.splice(
                                              i,
                                              1
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(rule.ruleType) +
                                            "Rule (" +
                                            _vm._s(rule.name) +
                                            ") "
                                        )
                                      ]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "blue darken-1", text: "" },
                                    on: { click: _vm.close }
                                  },
                                  [_vm._v("Cancel")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "blue darken-1",
                                      text: "",
                                      disabled: _vm.disableSave()
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.save(item)
                                      }
                                    }
                                  },
                                  [_vm._v("Save")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ]
            }
          },
          {
            key: "item.status",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("v-select", {
                  staticStyle: { "max-width": "250px" },
                  attrs: { items: _vm.availableStatuses },
                  on: {
                    change: function($event) {
                      return _vm.validationStatusChanged($event, item)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "prepend",
                        fn: function() {
                          return [
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  color:
                                    item.status == "Active"
                                      ? "success"
                                      : item.status == "Experimental"
                                      ? "purple"
                                      : "warning"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.status == "Active"
                                        ? "mdi-checkbox-marked-circle"
                                        : item.status == "Experimental"
                                        ? "mdi-test-tube"
                                        : "mdi-block-helper"
                                    )
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "selection",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              {
                                class:
                                  item.status == "Active"
                                    ? "success--text"
                                    : item.status == "Experimental"
                                    ? "purple--text"
                                    : "warning--text"
                              },
                              [_vm._v(" " + _vm._s(item.status) + " ")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: item.status,
                    callback: function($$v) {
                      _vm.$set(item, "status", $$v)
                    },
                    expression: "item.status"
                  }
                })
              ]
            }
          },
          {
            key: "item.action",
            fn: function(ref) {
              var item = ref.item
              return [
                item.originalStatus == "Experimental"
                  ? _c("ValidationTestDialog", {
                      attrs: { validation: item },
                      on: { testRequested: _vm.testValidation }
                    })
                  : _vm._e(),
                item._modified
                  ? _c(
                      "v-icon",
                      {
                        attrs: { large: "", color: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.updateValidation(item)
                          }
                        }
                      },
                      [_vm._v("save")]
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.validThrough",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("formatValidThrough")(item.validThrough)) +
                    " "
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }