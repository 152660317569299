var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: "blue-grey lighten-5" } },
    [
      _c(
        "v-row",
        {
          staticClass: "py-2 px-6",
          attrs: { justify: "space-between", align: "center" }
        },
        [
          _c("span", { staticClass: "price-panel-header" }, [
            _vm._v(_vm._s(_vm.header))
          ]),
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "primary" },
              on: {
                click: function($event) {
                  return _vm.openNewPriceDialog()
                }
              }
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-plus")]),
              _vm._v(" Add new price ")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-expansion-panels",
        { attrs: { multiple: "", accordion: "" } },
        _vm._l(_vm.prices, function(price, i) {
          return _c("PricePanelItem", {
            key: i,
            ref: "pricePanelItem",
            refInFor: true,
            attrs: {
              price: price,
              allowedRuleTypes: _vm.allowedRuleTypes,
              isUpdating: _vm.isUpdating
            },
            on: {
              modify: function($event) {
                return _vm.openPriceDialog(price, i)
              },
              delete: function($event) {
                return _vm.deletePrice(i)
              },
              update: function($event) {
                var i$1 = arguments.length,
                  argsArray = Array(i$1)
                while (i$1--) argsArray[i$1] = arguments[i$1]
                return _vm.modifyPrice.apply(void 0, [i].concat(argsArray))
              }
            }
          })
        }),
        1
      ),
      _c("PriceDialog", {
        attrs: {
          isOpen: _vm.priceDialog,
          originalPrice: _vm.selectedPrice,
          isNewPrice: _vm.newPrice,
          allowedRuleTypes: _vm.allowedRuleTypes,
          isUpdating: _vm.isUpdating
        },
        on: {
          create: _vm.addPrice,
          save: _vm.modifySelectedPriceIndex,
          close: _vm.closePriceDialog
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }