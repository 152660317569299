<template>
    <v-dialog v-model="isOpen" persistent max-width="800">
        <v-card>
            <v-card-title>
                <span class="headline">
                    {{ isNewFee ? 'Add new Fee' : 'Edit Fee' }}
                </span>
            </v-card-title>
            <v-card-text>
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col class="pl-0">
                                <v-select
                                v-model="selectedFeeType"
                                :items="allowedFeeTypes"
                                :rules="[v => !!v || 'Fee type is required']"
                                label="Fee type"
                                required
                            >
                                <template slot="selection" slot-scope="{ item }">
                                    <span v-if="useLikumaTexts">
                                        {{ item | feeTypeToLikumaText }}
                                    </span>
                                    <span v-else>
                                        {{ item | feeTypeToText }}
                                    </span>
                                </template>
                                <template slot="item" slot-scope="{ item }">
                                    <span v-if="useLikumaTexts">
                                        {{ item | feeTypeToLikumaText }}
                                    </span>
                                    <span v-else>
                                        {{ item | feeTypeToText }}
                                    </span>
                                </template>
                            </v-select>
                            </v-col>
                            <v-col class="pl-0">
                                <v-text-field
                                    v-model="fee.price"
                                    label="Price"
                                    type="number"
                                    :rules="[
                                        v => !!v || 'Price cannot be empty',
                                        v => /^\d+((.|,)\d+)?$/.test(v) || 'Price is not valid decimal value',
                                        v => !v || v.toString().replace('.', '').replace(',', '').length <= 8 || 'Price cannot be longer than 8 characters without decimal point'
                                    ]"
                                    counter=9
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col class="pl-0">
                                <v-text-field
                                    v-model="fee.vat"
                                    label="Vat"
                                    type="number"
                                    :rules="[
                                        v => /^\d+((.|,)\d+)?$/.test(v) || 'Vat is not valid decimal value',
                                        v => !v || v.toString().replace('.', '').replace(',', '').length <= 8 || 'Vat cannot be longer than 8 characters without decimal point'
                                    ]"
                                    counter=9
                                ></v-text-field>
                            </v-col>
                            <v-col>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn v-if="isNewFee"
                    :disabled="!valid"
                    @click="createFee"
                    color="success">
                    Add fee
                </v-btn>
                <v-btn v-else
                    :disabled="!valid"
                    @click="saveFee"
                    color="primary">
                    Save changes
                </v-btn>
                <v-btn @click="$emit('close')" class="ml-4">
                    Cancel
                  </v-btn>
				<v-spacer></v-spacer>
			</v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { FeeTypeMixin } from "../../models/FeeTypes";
import _ from "lodash";

export default {
  mixins: [FeeTypeMixin],
  props: {
    originalFee: {
      type: Object,
      default: () => {}
    },
    allowedFeeTypes: Array,
    isNewFee: {
      type: Boolean,
      default: true
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    useLikumaTexts: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fee: {},
      valid: true,
      selectedFeeType: this.originalFee.type
    };
  },
  watch: {
    originalFee: function (newVal, oldVal) {
      this.fee = _.cloneDeep(this.originalFee);
      this.selectedFeeType = this.fee.type;
    },
    isOpen: function (val, oldVal) {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    selectedFeeType: function (val, oldVal) {
      this.fee.type = val;
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    }
  },
  methods: {
    saveFee () {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit("save", this.fee);
    },
    createFee () {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit("create", this.fee);
    }
  }
};
</script>

<style scoped>
</style>
