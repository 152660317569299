<template>
    <v-container fluid v-if="customer">
        <h1>{{customer.name}}</h1>
        <v-card class="d-flex mb-6" flat tile>
            <v-card class="pa-2" outlined tile>
                <strong>Id:</strong> {{customer.customerIdentifier}}
            </v-card>
            <v-card class="pa-2" outlined>
                <strong>Operator:</strong> {{customer.operatorIdentifier}}
            </v-card>
            <template v-if="customer.attributes">
                <v-card class="pa-2" outlined v-for="key in Object.keys(customer.attributes)" :key=key>
                    <strong>{{key}}:</strong> {{customer.attributes[key]}}
                </v-card>
            </template>
        </v-card>
        <v-data-table v-if="users" :items="users" :headers="headers">
            <template v-slot:top>
                <div class="pl-5">
                    <UserDialog v-on:userCreated="createUser"></UserDialog>
                </div>
            </template>
            <template v-slot:item.validThrough="{ item }">
                {{ item.validThrough | formatValidThrough }}
            </template>
        </v-data-table>
  </v-container>
</template>

<script>
import { RepositoryFactory } from "../data/repositories/repositoryFactory";
import UserDialog from "../components/Dialogs/UserDialog";

const PaymentRepository = RepositoryFactory.get("payment");

export default {
  components: {
    UserDialog
  },
  data: () => ({
    customerId: null,
    customer: null,
    newUser: {},
    users: [],
    headers: [
      { text: "Name", value: "name" },
      { text: "Email", value: "email" },
      { text: "Phone", value: "phone" },
      { text: "ValidThrough", value: "validThrough" }
    ]
  }),
  methods: {
    async createUser (userObj) {
      userObj.customerId = this.customerId;
      await PaymentRepository.createUser(userObj);
      await this.fetchUsers();
    },
    async fetchUsers () {
      const { data: users } = await PaymentRepository.getUsersByCustomerId(this.customerId);
      this.users = users;
    }
  },
  async created () {
    this.customerId = this.$route.params["id"];
    const { data: customer } = await PaymentRepository.getCustomer(this.customerId);
    this.customer = customer;
    await this.fetchUsers();
  }
};
</script>

<style>

</style>
