var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600px" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c("v-btn", _vm._g({ attrs: { color: "primary" } }, on), [
                _vm._v("Add User")
              ])
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [_vm._v("Add new User")])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                [
                  _c("v-text-field", {
                    attrs: {
                      "error-messages": _vm.nameErrors,
                      disabled: _vm.validated,
                      outlined: "",
                      required: "",
                      value: "asd",
                      label: "Name"
                    },
                    model: {
                      value: _vm.$v.name.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.name, "$model", $$v)
                      },
                      expression: "$v.name.$model"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      "error-messages": _vm.emailErrors,
                      disabled: _vm.validated,
                      outlined: "",
                      required: "",
                      placeholder: "matti@gmail.com",
                      label: "Email"
                    },
                    model: {
                      value: _vm.$v.email.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.email, "$model", $$v)
                      },
                      expression: "$v.email.$model"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      "error-messages": _vm.phoneErrors,
                      disabled: _vm.validated,
                      outlined: "",
                      required: "",
                      placeholder: "358501234567",
                      label: "Phone"
                    },
                    model: {
                      value: _vm.$v.phone.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.phone, "$model", $$v)
                      },
                      expression: "$v.phone.$model"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: {
                        disabled: this.$v.$invalid || this.validated,
                        color: "success"
                      },
                      on: { click: _vm.validate }
                    },
                    [_vm._v("Validate / Confirm")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "red",
                        disabled: !this.validated,
                        text: ""
                      },
                      on: { click: _vm.cancel }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm.validated
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: { color: "primary" },
                          on: { click: _vm.create }
                        },
                        [_vm._v("Create")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }