<template>
	<v-list-item @click="navigate()">
		<v-list-item-icon>
			<v-icon large>{{ icon }}</v-icon>
		</v-list-item-icon>
		<v-list-item-content>
			<v-list-item-title class="subtitle-1">{{ title }}</v-list-item-title>
		</v-list-item-content>
	</v-list-item>
</template>

<script>
export default {
  data () {
    return {};
  },
  props: {
    icon: String,
    title: String,
    routeName: String
  },
  methods: {
    navigate () {
      this.$router.push({ name: this.routeName });
    }
  }
};
</script>

<style scoped>
.v-list-item {
	padding-left: 1rem;
}
</style>
