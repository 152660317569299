var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-btn",
            {
              staticClass: "ma-2",
              attrs: { color: "primary" },
              on: {
                click: function($event) {
                  _vm.showJoinFleet = true
                }
              }
            },
            [_vm._v("Join Fleet Group")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "ma-2",
              attrs: { color: "primary" },
              on: {
                click: function($event) {
                  _vm.showJoinVehicleLog = true
                }
              }
            },
            [_vm._v("Join Vehicle Log")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "ma-2",
              attrs: { color: "error" },
              on: {
                click: function($event) {
                  _vm.vehicles = []
                }
              }
            },
            [_vm._v("Clear vehicles")]
          )
        ],
        1
      ),
      _c("JoinFleetDialog", {
        attrs: { isOpen: _vm.showJoinFleet },
        on: {
          joinFleet: _vm.joinFleet,
          close: function($event) {
            _vm.showJoinFleet = false
          }
        }
      }),
      _c("JoinVehicleLogDialog", {
        attrs: { isOpen: _vm.showJoinVehicleLog },
        on: {
          joinVehicleLog: _vm.joinVehicleLog,
          close: function($event) {
            _vm.showJoinVehicleLog = false
          }
        }
      }),
      _c(
        "v-row",
        { staticStyle: { "min-height": "600px" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "8" } },
            [
              _c("MapBox", {
                attrs: {
                  center: _vm.mapCenter,
                  vehicles: _vm.vehicles ? _vm.vehicles : [],
                  showZones: _vm.showZones,
                  showStations: _vm.showStations
                },
                on: { vehicleClicked: _vm.onVehicleClicked }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "v-tabs",
                {
                  attrs: {
                    "fixed-tabs": "",
                    "background-color": "indigo",
                    dark: ""
                  }
                },
                [
                  _c("v-tab", [_vm._v(" Joined Fleets ")]),
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-list",
                        { attrs: { "two-line": "", subheader: "" } },
                        _vm._l(_vm.fleets, function(fleet) {
                          return _c(
                            "v-list-item",
                            { key: fleet },
                            [
                              _c(
                                "v-list-item-avatar",
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "blue white--text" },
                                    [_vm._v(_vm._s(_vm.fleetIcon(fleet)))]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", {
                                    domProps: { textContent: _vm._s(fleet) }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-action",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.leaveFleet(fleet)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "red lighten-1" } },
                                        [_vm._v("mdi-close-circle")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-tab", [_vm._v(" Vehicle Details ")]),
                  _c(
                    "v-tab-item",
                    [
                      _c("VehicleDetails", {
                        attrs: { car: _vm.selectedVehicle },
                        on: { close: _vm.closeDetails }
                      })
                    ],
                    1
                  ),
                  _c("v-tab", [_vm._v(" Vehicle Log ")]),
                  _c(
                    "v-tab-item",
                    [
                      _c("VehicleLogs", {
                        attrs: { logs: _vm.logs },
                        on: { close: _vm.closeLogs }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }