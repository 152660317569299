var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    { attrs: { app: "", "clipped-left": "", color: "black", dark: "" } },
    [
      _c("v-app-bar-nav-icon", {
        staticClass: "navigation-toggle hidden-lg-and-up",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.$emit("sideMenuToggled")
          }
        }
      }),
      _c("v-spacer"),
      _c(
        "v-toolbar-title",
        { staticClass: "ml-0 pl-3", staticStyle: { "max-width": "800px" } },
        [
          _c("img", {
            staticClass: "site-logo hidden-xs-only",
            attrs: {
              src: require("@/assets/logo.png"),
              alt: "Router Group logo"
            }
          }),
          _c("a", { attrs: { rel: "author", href: "/" } }, [
            _c("span", { staticClass: "site-title" }, [
              _vm._v(_vm._s(_vm.header))
            ])
          ])
        ]
      ),
      _c("v-spacer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }