<template>
  <GmapMap
      :center="center"
      :zoom="zoom"
      map-type-id="roadmap"
      ref="map"
      >
      <GmapMarker
          v-for="(m, index) in markers"
          :key="'m' + index"
          :position="m.position"
      />
      <GmapPolyline
          :key="'pl' + index"
          v-for="(p, index) in polylines"
          :path="p.path"
      />
       <GmapMarker
          v-for="(l, index) in locations"
          :key="'l' + index"
          :position="locationToMapLocation(l)"
      />
      <GmapCluster :grid-size="gridSize" v-if="cluster">
        <GmapCustomMarker
            v-for="(c, index) in cars"
            :key="'c' + index"
            :marker="carToMapLocation(c)"
            @click.native="onCarClicked(c)"
        >
        <v-icon :color="getCarStatusColor(c)">mdi-taxi</v-icon>
        </GmapCustomMarker>
      </GmapCluster>
      <GmapPolygon
          :key="'pg' + index"
          v-for="(p, index) in polygons"
          :paths="p.paths"
      />
      <GmapPolyline
          :key="'r' + index"
          v-for="(r, index) in routes"
          :path="routeToPolyline(r)"
          :options="{strokeColor: routeColor}"
      />
      <GmapPolygon
          :key="'i' + index"
          v-for="(i, index) in isochrones"
          :paths="isochroneToPolygon(i)"
          :options="{strokeColor: isochroneColor}"
      />
  </GmapMap>
</template>

<script>
import { RepositoryFactory } from "../../data/repositories/repositoryFactory";
import GmapCustomMarker from "vue2-gmap-custom-marker";

const GeoRepository = RepositoryFactory.get("geo");

export default {
  components: {
    GmapCustomMarker
  },
  props: {
    showZones: {
      type: Boolean,
      default: false
    },
    showStations: {
      type: Boolean,
      default: false
    },
    cluster: {
      type: Boolean,
      default: true
    },
    gridSize: {
      type: Number,
      default: 40
    },
    zoom: {
      type: Number,
      default: 9
    },
    center: {
      type: Object,
      default: () => {
        return { lat: 60.326692, lng: 24.840489 };
      }
    },
    markers: {
      type: Array,
      default: () => {
        return [];
      }
    },
    polylines: {
      type: Array,
      default: () => {
        return [];
      }
    },
    polygons: {
      type: Array,
      default: () => {
        return [];
      }
    },
    routes: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isochrones: {
      type: Array,
      default: () => {
        return [];
      }
    },
    locations: {
      type: Array,
      default: () => {
        return [];
      }
    },
    cars: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data () {
    return {
      routeColor: "#008080",
      isochroneColor: "#800080",
      zoneLayer: null,
      stationLayer: null
    };
  },
  watch: {
    showZones: function (show) {
      this.toggleZones(show);
    },
    showStations: function (show) {
      this.toggleStations(show);
    }
  },
  methods: {
    onCarClicked: function (car) {
      this.$emit("carClicked", car);
    },
    routeToPolyline: route => {
      return route.points.map(p => {
        return {
          lat: p.latitude,
          lng: p.longitude };
      });
    },
    isochroneToPolygon: isochrone => {
      return isochrone.points.map(p => {
        return {
          lat: p.latitude,
          lng: p.longitude };
      });
    },
    locationToMapLocation: location => {
      return {
        lat: location.point.latitude,
        lng: location.point.longitude };
    },
    carToMapLocation: car => {
      return {
        lat: car.Latitude,
        lng: car.Longitude };
    },
    getCarStatusColor: car => {
      switch (car.State) {
      case "Free":
        return "green";
      case "Occupied":
        return "yellow";
      case "NoOffers":
        return "red";
      case "SoonFree":
        return "blue";
      default: return "black";
      }
    },
    toggleZones: function (show) {
      let vm = this;
      if (show && !vm.zoneLayer) {
        GeoRepository.getZoneGeoJson().then((response) => {
          this.$refs.map.$mapPromise.then((map) => {
          // eslint-disable-next-line no-undef
            vm.zoneLayer = new google.maps.Data({ map: map });
            vm.zoneLayer.addGeoJson(JSON.parse(response.data));
            vm.zoneLayer.setStyle({
              strokeColor: "grey",
              strokeWeight: 1,
              fillOpacity: 0.0
            });
          });
        });
      } else if (show) {
        this.$refs.map.$mapPromise.then((map) => {
          vm.zoneLayer.setMap(map);
        });
      } else {
        this.$refs.map.$mapPromise.then((map) => {
          vm.zoneLayer.setMap();
        });
      }
    },
    toggleStations: function (show) {
      let vm = this;
      if (show && !vm.stationLayer) {
        GeoRepository.getStationGeoJson().then((response) => {
          this.$refs.map.$mapPromise.then((map) => {
          // eslint-disable-next-line no-undef
            vm.stationLayer = new google.maps.Data({ map: map });
            vm.stationLayer.addGeoJson(JSON.parse(response.data));
            vm.stationLayer.setStyle({
              strokeColor: "yellow",
              strokeWeight: 1,
              fillOpacity: 0.0
            });
          });
        });
      } else if (show) {
        this.$refs.map.$mapPromise.then((map) => {
          vm.stationLayer.setMap(map);
        });
      } else {
        this.$refs.map.$mapPromise.then((map) => {
          vm.stationLayer.setMap();
        });
      }
    }
  },
  created () {
  },
  mounted () {
    if (this.showZones) {
      this.toggleZones(true);
    }

    if (this.showStations) {
      this.toggleStations(true);
    }
  }
};
</script>

<style>
  .vue-map-container,
  .vue-map-container .vue-map {
      width: 100%;
      height: 100%;
  }
</style>
