import { invoicingService } from "./repository";
import qs from "qs";
import AuthService from "../../msal";

const authService = new AuthService();

const resource = "/v1";

export default {
  find (params, top, page = 1) {
    return invoicingService.get(`${resource}/transportevents`, {
      params: {
        ...params,
        top,
        page
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, { indices: false });
      } });
  },
  async create (transportEvent) {
    const { username } = await authService.getUserInfo();

    transportEvent.modifier = username;

    return invoicingService.post(`${resource}/transportevents`, transportEvent);
  },
  async update (transportEvent) {
    const { username } = await authService.getUserInfo();

    transportEvent.modifier = username;

    return invoicingService.put(`${resource}/transportevents/${transportEvent.id}`, transportEvent);
  },
  async delete (id) {
    return invoicingService.delete(`${resource}/transportevents/${id}`);
  },
  async getFailReasons () {
    return invoicingService.get("/statistics/transporteventfailreasons");
  },
  async getSalesInvoices () {
    return invoicingService.get("/statistics/transporteventsalesinvoices");
  },
  async getPurchaseInvoices () {
    return invoicingService.get("/statistics/transporteventpurchaseinvoices");
  },
  async getTripStatusesByDay (start, end) {
    return invoicingService.get("/statistics/tripstatusesbyday", {
      params: {
        start, end
      }
    });
  },
  async getTripStatusesByTimeRange (start, end) {
    return invoicingService.get("/statistics/tripstatusesbytimerange", {
      params: {
        start, end
      }
    });
  }

};
