var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      staticClass: "mx-3 mt-2",
      model: {
        value: _vm.searchParamsValid,
        callback: function($$v) {
          _vm.searchParamsValid = $$v
        },
        expression: "searchParamsValid"
      }
    },
    [
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", md: "9" } },
            [
              _c("v-select", {
                attrs: {
                  items: Object.values(_vm.validationStatusTypes),
                  label: "Validation status",
                  multiple: "",
                  clearable: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      var index = ref.index
                      return [
                        index === 0 || index === 1
                          ? _c("v-chip", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("validationStatusToText")(item)
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e(),
                        index === 2
                          ? _c("span", { staticClass: "grey--text caption" }, [
                              _vm._v(
                                "(+" +
                                  _vm._s(
                                    _vm.updatedSearchParams.validationStates
                                      .length - 2
                                  ) +
                                  " others)"
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("validationStatusToText")(item)) +
                            " "
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.updatedSearchParams.validationStates,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "validationStates", $$v)
                  },
                  expression: "updatedSearchParams.validationStates"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", md: "3" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "pt-0",
                  attrs: { outlined: "", small: "" },
                  on: { click: _vm.setFailedValidations }
                },
                [_vm._v(" Search failed validations ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-slide-y-transition",
        [
          _vm.updatedSearchParams.validationStates.includes(
            _vm.validationStatusTypes.ExternalValidationFailed
          )
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pl-0 py-0",
                      attrs: { cols: "12", md: "9" }
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          items: Object.keys(_vm.likumaValidationErrors),
                          label: "Likuma error types",
                          multiple: "",
                          clearable: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "selection",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("v-chip", [
                                    _vm._v(
                                      " " +
                                        _vm._s(item) +
                                        " - " +
                                        _vm._s(
                                          _vm.likumaValidationErrors[item]
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              }
                            },
                            {
                              key: "item",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(item) +
                                      " - " +
                                      _vm._s(_vm.likumaValidationErrors[item]) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3012897954
                        ),
                        model: {
                          value:
                            _vm.updatedSearchParams.validationFailReasonCodes,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.updatedSearchParams,
                              "validationFailReasonCodes",
                              $$v
                            )
                          },
                          expression:
                            "updatedSearchParams.validationFailReasonCodes"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", sm: "6" } },
            [
              _c("v-select", {
                attrs: {
                  items: Object.values(_vm.salesInvoiceStates),
                  label: "Sales invoice status",
                  disabled: !!_vm.updatedSearchParams.salesInvoiceNumbers,
                  multiple: "",
                  clearable: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      var index = ref.index
                      return [
                        index === 0 || index === 1
                          ? _c("v-chip", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("salesInvoiceStateToText")(item)
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e(),
                        index === 2
                          ? _c("span", { staticClass: "grey--text caption" }, [
                              _vm._v(
                                "(+" +
                                  _vm._s(
                                    _vm.updatedSearchParams.salesInvoiceStates
                                      .length - 2
                                  ) +
                                  " others)"
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("salesInvoiceStateToText")(item)) +
                            " "
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.updatedSearchParams.salesInvoiceStates,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "salesInvoiceStates", $$v)
                  },
                  expression: "updatedSearchParams.salesInvoiceStates"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", sm: "1" } },
            [_c("v-subheader", [_vm._v("OR")])],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", sm: "5" } },
            [
              _c("v-text-field", {
                attrs: { label: "Sales invoice number" },
                on: {
                  change: function($event) {
                    _vm.updatedSearchParams.salesInvoiceStates = []
                  }
                },
                model: {
                  value: _vm.updatedSearchParams.salesInvoiceNumbers,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.updatedSearchParams,
                      "salesInvoiceNumbers",
                      $$v
                    )
                  },
                  expression: "updatedSearchParams.salesInvoiceNumbers"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", sm: "6" } },
            [
              _c("v-select", {
                attrs: {
                  items: Object.values(_vm.purchaseInvoiceStates),
                  label: "Purchase invoice status",
                  disabled: !!_vm.updatedSearchParams.purchaseInvoiceNumbers,
                  multiple: "",
                  clearable: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      var index = ref.index
                      return [
                        index === 0 || index === 1
                          ? _c("v-chip", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("purchaseInvoiceStateToText")(item)
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e(),
                        index === 2
                          ? _c("span", { staticClass: "grey--text caption" }, [
                              _vm._v(
                                "(+" +
                                  _vm._s(
                                    _vm.updatedSearchParams
                                      .purchaseInvoiceStates.length - 2
                                  ) +
                                  " others)"
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("purchaseInvoiceStateToText")(item)) +
                            " "
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.updatedSearchParams.purchaseInvoiceStates,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.updatedSearchParams,
                      "purchaseInvoiceStates",
                      $$v
                    )
                  },
                  expression: "updatedSearchParams.purchaseInvoiceStates"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", sm: "1" } },
            [_c("v-subheader", [_vm._v("OR")])],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", sm: "5" } },
            [
              _c("v-text-field", {
                attrs: { label: "Purchase invoice number" },
                on: {
                  change: function($event) {
                    _vm.updatedSearchParams.purchaseInvoiceStates = []
                  }
                },
                model: {
                  value: _vm.updatedSearchParams.purchaseInvoiceNumbers,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.updatedSearchParams,
                      "purchaseInvoiceNumbers",
                      $$v
                    )
                  },
                  expression: "updatedSearchParams.purchaseInvoiceNumbers"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", sm: "4" } },
            [
              _c("v-text-field", {
                attrs: { label: "Vehicle id" },
                model: {
                  value: _vm.updatedSearchParams.carNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "carNumber", $$v)
                  },
                  expression: "updatedSearchParams.carNumber"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", sm: "4" } },
            [
              _c("v-text-field", {
                attrs: { label: "Meter id" },
                model: {
                  value: _vm.updatedSearchParams.taximeterSerialNumber,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.updatedSearchParams,
                      "taximeterSerialNumber",
                      $$v
                    )
                  },
                  expression: "updatedSearchParams.taximeterSerialNumber"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", sm: "4" } },
            [
              _c("v-text-field", {
                attrs: { label: "Card number" },
                model: {
                  value: _vm.updatedSearchParams.cardNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "cardNumber", $$v)
                  },
                  expression: "updatedSearchParams.cardNumber"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", md: "2" } },
            [_vm._v("Modified")]
          ),
          _c(
            "v-col",
            {
              staticClass: "pl-0 py-0",
              attrs: { cols: "12", sm: "6", md: "3" }
            },
            [
              _c("v-text-field", {
                attrs: { label: "Modified from", type: "datetime-local" },
                model: {
                  value: _vm.updatedSearchParams.modifiedFrom,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "modifiedFrom", $$v)
                  },
                  expression: "updatedSearchParams.modifiedFrom"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pl-0 py-0",
              attrs: { cols: "12", sm: "6", md: "3" }
            },
            [
              _c("v-text-field", {
                attrs: { label: "Modified to", type: "datetime-local" },
                model: {
                  value: _vm.updatedSearchParams.modifiedTo,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "modifiedTo", $$v)
                  },
                  expression: "updatedSearchParams.modifiedTo"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", md: "2" } },
            [_vm._v("Trip started")]
          ),
          _c(
            "v-col",
            {
              staticClass: "pl-0 py-0",
              attrs: { cols: "12", sm: "6", md: "3" }
            },
            [
              _c("v-text-field", {
                attrs: { label: "Trip started from", type: "datetime-local" },
                model: {
                  value: _vm.updatedSearchParams.tripStartedFrom,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "tripStartedFrom", $$v)
                  },
                  expression: "updatedSearchParams.tripStartedFrom"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pl-0 py-0",
              attrs: { cols: "12", sm: "6", md: "3" }
            },
            [
              _c("v-text-field", {
                attrs: { label: "Trip started to", type: "datetime-local" },
                model: {
                  value: _vm.updatedSearchParams.tripStartedTo,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "tripStartedTo", $$v)
                  },
                  expression: "updatedSearchParams.tripStartedTo"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", md: "2" } },
            [_vm._v("Trip ended")]
          ),
          _c(
            "v-col",
            {
              staticClass: "pl-0 py-0",
              attrs: { cols: "12", sm: "6", md: "3" }
            },
            [
              _c("v-text-field", {
                attrs: { label: "Trip ended from", type: "datetime-local" },
                model: {
                  value: _vm.updatedSearchParams.tripEndedFrom,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "tripEndedFrom", $$v)
                  },
                  expression: "updatedSearchParams.tripEndedFrom"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pl-0 py-0",
              attrs: { cols: "12", sm: "6", md: "3" }
            },
            [
              _c("v-text-field", {
                attrs: { label: "Trip ended to", type: "datetime-local" },
                model: {
                  value: _vm.updatedSearchParams.tripEndedTo,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "tripEndedTo", $$v)
                  },
                  expression: "updatedSearchParams.tripEndedTo"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("v-row")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }