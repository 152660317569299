export const FeeType = {
  Unknown: 0,
  BaseFee: 1,
  KilometerFee: 2,
  MinuteFee: 3,
  WaitFee: 4,
  FixedFee: 5,
  PrebookFee: 6,
  GoodsFee: 7,
  AirportFee: 8,
  ServiceFee: 9, // Palvelulisämaksu
  AidFee: 10, // Avustuslisämaksu
  HandicapFee: 11 // Invalisämaksu
};

export const FeeTypeMixin = {
  data () {
    return {
      feeTypes: FeeType
    };
  },
  filters: {
    feeTypeToText: function (value) {
      switch (value) {
      case FeeType.Unknown:
        return "Unknown";
      case FeeType.BaseFee:
        return "Base fee";
      case FeeType.KilometerFee:
        return "Kilometer fee";
      case FeeType.MinuteFee:
        return "Minute fee";
      case FeeType.WaitFee:
        return "Wait fee";
      case FeeType.FixedFee:
        return "Fixed fee";
      case FeeType.PrebookFee:
        return "Pre-book fee";
      case FeeType.GoodsFee:
        return "Goods fee";
      case FeeType.AirportFee:
        return "Airport fee";
      case FeeType.ServiceFee:
        return "Service fee";
      case FeeType.AidFee:
        return "Aid fee";
      case FeeType.HandicapFee:
        return "Handicap fee";
      }
    },
    feeTypeToLikumaText: function (value) {
      switch (value) {
      case FeeType.AirportFee:
        return "L - Airport extra";
      case FeeType.ServiceFee:
        return "S - Service extra";
      case FeeType.AidFee:
        return "P - Stair help extra";
      case FeeType.HandicapFee:
        return "I - Handicap extra";
      default:
        return "Not valid type";
      }
    },
    feeToToolTipText: function (value) {
      let vat = !value.vat ? "" : ", Vat: " + value.vat;
      return "Sum: " + value.price + vat;
    }
  }
};
