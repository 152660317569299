<template>
  <v-card v-if="customers">
    <v-card-title>
      Customers
      <v-spacer></v-spacer>
      <v-text-field
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :items=customers
      :headers=headers
      item-key="customerId"
      @click:row="openCustomer"
    >
    <template v-slot:top>
        <div class="pl-5">
            <CustomerDialog v-on:customerCreated="createCustomer"></CustomerDialog>
        </div>
    </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { RepositoryFactory } from "../data/repositories/repositoryFactory";
import CustomerDialog from "../components/Dialogs/CustomerDialog";
const PaymentRepository = RepositoryFactory.get("payment");

export default {
  components: { CustomerDialog },
  data: () => ({
    customers: [],
    headers: [
      { text: "Name", value: "name" },
      { text: "Operator", value: "operatorIdentifier" },
      { text: "Contact", value: "contact" },
      { text: "Identifier", value: "customerIdentifier" }
    ]
  }),
  methods: {
    openCustomer (customer) {
      this.$router.push({ name: "CustomerDetails", params: { id: customer.customerId } });
    },
    async createCustomer (customerObj) {
      let { data: createdCustomer } = await PaymentRepository.createCustomer(customerObj);
      this.customers.push(createdCustomer);
    }
  },
  async created () {
    const { data: customers } = await PaymentRepository.findCustomers();
    this.customers = customers;
  }
};
</script>

<style scoped>

</style>
