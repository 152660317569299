<template>
  <v-container>
      <v-row>
        <v-file-input @change="fileAdded" label="Upload GeoJSON File"></v-file-input>
      </v-row>
      <v-row v-if="geoData.features">
          <v-chip-group column>
            <v-chip
            label
            :color="`${randomColor()} lighten-3`"
            v-for="feature in geoData.features" :key="feature.properties.id">
                {{ feature.properties.name }} ({{feature.properties.id}})
            </v-chip>
          </v-chip-group>
      </v-row>
  </v-container>
</template>

<script>

export default {
  data: () => ({
    geoData: {},
    colors: ["red", "pink", "purple", "indigo", "blue", "cyan", "teal", "green", "orange", "brown"]
  }),
  methods: {
    fileAdded (fileObj) {
      var reader = new FileReader();
      reader.onload = this.fileLoaded;
      reader.readAsText(fileObj);
    },
    validateFile (geoJson) {
      let parsedGeo = JSON.parse(geoJson);
      if (parsedGeo.length <= 0) { return false; }
      this.geoData = parsedGeo;
      return true;
    },
    fileLoaded (fileEvent) {
      // Validate that its correct type of file.
      let json = fileEvent.target.result;

      if (!this.validateFile(json)) {
        return;
      }

      this.$emit("geoJsonLoaded", this.geoData);
    },
    randomColor () {
      var keys = Object.keys(this.colors);
      return this.colors[keys[keys.length * Math.random() << 0]];
    }
  }
};
</script>

<style>

</style>
