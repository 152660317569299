import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import ValidationsList from "./views/ValidationsList";
import CustomersList from "./views/CustomersList";
import CustomerDetails from "./views/CustomerDetails";
import Telemetry from "./views/Telemetry";
import Geo from "./views/Geo";
import Pricing from "./views/Pricing";
import Invoicing from "./views/Invoicing";
import InvoicingRules from "./views/InvoicingRules";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home
    },
    {
      path: "/validations",
      name: "Validations",
      component: ValidationsList
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing
    },
    {
      path: "/invoicing",
      name: "Invoicing",
      component: Invoicing
    },
    {
      path: "/invoicing/rules",
      name: "InvoicingRules",
      component: InvoicingRules
    },
    {
      path: "/customers",
      name: "Customers",
      component: CustomersList
    },
    {
      path: "/customers/:id",
      name: "CustomerDetails",
      component: CustomerDetails
    },
    {
      path: "/geo",
      name: "GeoService",
      component: Geo
    },
    {
      path: "/telemetry",
      name: "Telemetry",
      component: Telemetry
    }
  ]
});

export default router;

router.beforeEach(async (to, from, next) => {
  let user = await Vue.prototype.$AuthService.getUser();
  if (user && user.email.endsWith("@taksipartners.fi") && to.name !== "Customers") {
    next({ name: "Customers" });
  } else if (user && user.email.endsWith("@taksikuutio.fi") && ["Home", "Invoicing"].indexOf(to.name) < 0) {
    next({ name: "Home" });
  } else {
    next();
  }
});
