export const TransportEventStatusType = {
  Unknown: "Unknown",
  Incomplete: "Incomplete",
  Received: "Received",
  Validated: "Validated",
  Approved: "Approved"
};

export const ValidationStatusType = {
  Unknown: 0,
  New: 1,
  ReadyForValidation: 2,
  WaitingForInternalValidation: 3,
  InternalValidationFailed: 4,
  WaitingForExternalValidation: 5,
  ExternalValidationFailed: 6,
  Approved: 7,
  PartiallyApproved: 8
};

export const TransportEventStatusMixin = {
  data () {
    return {
      transportEventStatusTypes: TransportEventStatusType,
      validationStatusTypes: ValidationStatusType,
      validationStatusFailTypes: [
        ValidationStatusType.InternalValidationFailed,
        ValidationStatusType.ExternalValidationFailed
      ]
    };
  },
  filters: {
    transportStatusToColor: function (value) {
      switch (value) {
      case TransportEventStatusType.Incomplete:
      case TransportEventStatusType.Received:
        return "";
      case TransportEventStatusType.Validated:
      case TransportEventStatusType.Approved:
        return "success";
      default: return "error";
      };
    },
    transportStatusToIcon: function (value) {
      switch (value) {
      case TransportEventStatusType.Incomplete:
      case TransportEventStatusType.Received:
        return "mdi-av-timer";
      case TransportEventStatusType.Validated:
      case TransportEventStatusType.Approved:
        return "mdi-check-circle-outline";
      default: return "mdi-alert";
      };
    },
    transportStatusToText: function (value) {
      switch (value) {
      case TransportEventStatusType.Unknown:
        return "Not determined";
      case TransportEventStatusType.Incomplete:
        return "Waiting for more information";
      case TransportEventStatusType.Received:
        return "Waiting for validation";
      case TransportEventStatusType.Validated:
        return "Validated";
      case TransportEventStatusType.Approved:
        return "Approved";
      };
    },
    validationStatusToColor: function (value) {
      switch (value) {
      case ValidationStatusType.ReadyForValidation:
      case ValidationStatusType.WaitingForInternalValidation:
      case ValidationStatusType.WaitingForExternalValidation:
        return "";
      case ValidationStatusType.New:
        return "primary";
      case ValidationStatusType.Approved:
        return "success";
      case ValidationStatusType.PartiallyApproved:
        return "#ffdd1a";
      default: return "error";
      };
    },
    validationStatusToIcon: function (value) {
      switch (value) {
      case ValidationStatusType.New:
        return "mdi-new-box";
      case ValidationStatusType.ReadyForValidation:
      case ValidationStatusType.WaitingForInternalValidation:
      case ValidationStatusType.WaitingForExternalValidation:
        return "mdi-av-timer";
      case ValidationStatusType.Approved:
      case ValidationStatusType.PartiallyApproved:
        return "mdi-check-circle-outline";
      default: return "mdi-alert";
      };
    },
    validationStatusToText: function (value) {
      switch (value) {
      case ValidationStatusType.Unknown:
        return "Unknown";
      case ValidationStatusType.New:
        return "New, not to be validated yet";
      case ValidationStatusType.ReadyForValidation:
        return "Waiting to be validated";
      case ValidationStatusType.WaitingForInternalValidation:
        return "Waiting for internal validation";
      case ValidationStatusType.WaitingForExternalValidation:
        return "Waiting for external validation";
      case ValidationStatusType.Approved:
        return "Approved";
      case ValidationStatusType.InternalValidationFailed:
        return "Internal validation failed";
      case ValidationStatusType.ExternalValidationFailed:
        return "External validation failed";
      case ValidationStatusType.PartiallyApproved:
        return "Partially approved";
      };
    }
  }
};
