<template>
  <v-dialog v-model="isOpen" persistent max-width="800">
        <v-card>
            <v-card-title>
               <span class="headline">
                    Add new price catalog
                </span>
            </v-card-title>
            <v-card-text class="pb-0">
                <v-switch
                    v-model="useExisting"
                    label="Copy existing price catalog (including prices and rules)"
                ></v-switch>
                <v-slide-y-transition>
                    <v-combobox
                        v-show="useExisting"
                        :items="priceCatalogs"
                        v-model="selectedPriceCatalog"
                        item-text="name"
                        item-value="id"
                        label="Price catalog"
                        @change="changePriceCatalog"
                    ></v-combobox>
                </v-slide-y-transition>
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation>
                    <v-container>
                            <v-row>
                                <v-text-field
                                    v-model="newPriceCatalog.name"
                                    :rules="[
                                    v => !!v || 'Name is required',
                                    v => !v || v.length < 100 || 'Name can not be longer than 100 characters'
                                    ]"
                                    counter="100"
                                    label="Name"
                                    required
                                ></v-text-field>
                            </v-row>
                            <v-row>
                                <v-text-field
                                    :disabled="useExisting"
                                    v-model="newPriceCatalog.operatorId"
                                    label="OperatorId"
                                ></v-text-field>
                            </v-row>
                            <v-row>
                                <v-text-field
                                    :disabled="useExisting"
                                    v-model="newPriceCatalog.customerId"
                                    label="CustomerId"
                                ></v-text-field>
                            </v-row>
                            <v-row>
                                <v-text-field
                                    :disabled="useExisting"
                                    v-model="newPriceCatalog.vendorId"
                                    label="VendorId"
                                ></v-text-field>
                            </v-row>
                            <v-row>
                                <v-text-field
                                    :disabled="useExisting"
                                    v-model="newPriceCatalog.productId"
                                    label="ProductId"
                                ></v-text-field>
                            </v-row>
                            <v-row><v-subheader>Valid through</v-subheader></v-row>
                            <v-row>
                                <v-col class="pl-0">
                                    <v-text-field
                                        v-model="newPriceCatalog.validThrough.startTime"
                                        label="Start time"
                                        type="datetime-local"
                                        :rules="[
                                            v => !!v || 'Start time cannot be empty',
                                        ]"
                                    required
                                    ></v-text-field>
                                </v-col>
                                <v-col class="pl-0">
                                    <v-text-field
                                        v-model="newPriceCatalog.validThrough.endTime"
                                        label="End time"
                                        type="datetime-local"
                                        :rules="[
                                            v => !!v || 'End time cannot be empty',
                                        ]"
                                    required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-slide-y-transition>
                                <v-row v-show="useExisting">
                                    <v-switch
                                        v-model="setOldToEnd"
                                        label="Set previous price catalog to end when this one starts"
                                    ></v-switch>
                                </v-row>
                            </v-slide-y-transition>
                        </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="!valid"
                    :loading="isUpdating"
                    @click="createNewPriceCatalog"
                    color="success">
                    Add price catalog
                </v-btn>
                <v-btn
                    :disabled="isUpdating"
                    @click="closeDialog"
                    class="ml-4">
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import _ from "lodash";

export default {
  props: {
    priceCatalogs: {
      type: Array
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      useExisting: false,
      setOldToEnd: false,
      selectedPriceCatalog: null,
      valid: true,
      isUpdating: false,
      newPriceCatalog: {
        name: "",
        operatorId: "",
        customerId: "",
        vendorId: "",
        productId: "",
        validThrough: {
          startTime: moment().format("YYYY-MM-DDThh:mm"),
          endTime: moment().format("YYYY-MM-DDThh:mm")
        }
      }
    };
  },
  watch: {
    isOpen: function (val, oldVal) {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      this.loading = false;
    }
  },
  methods: {
    changePriceCatalog () {
      this.newPriceCatalog = {
        name: this.selectedPriceCatalog.name,
        operatorId: this.selectedPriceCatalog.operatorId,
        customerId: this.selectedPriceCatalog.customerId,
        vendorId: this.selectedPriceCatalog.vendorId,
        productId: this.selectedPriceCatalog.productId,
        validThrough: {
          startTime: moment(this.selectedPriceCatalog.validThrough.startTime).format("YYYY-MM-DDTHH:mm"),
          endTime: moment(this.selectedPriceCatalog.validThrough.endTime).format("YYYY-MM-DDTHH:mm")
        }
      };
    },
    createNewPriceCatalog () {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.isUpdating = true;
      if (this.useExisting) {
        let newCatalog = _.cloneDeep(this.selectedPriceCatalog);
        newCatalog.name = this.newPriceCatalog.name;
        newCatalog.validThrough = this.newPriceCatalog.validThrough;
        this.$emit("create", newCatalog, this.setOldToEnd ? this.selectedPriceCatalog.id : 0);
      } else {
        let newCatalog = _.cloneDeep(this.newPriceCatalog);
        newCatalog.vendorSubId = "";
        newCatalog.contractId = "";
        newCatalog.status = "Active";
        this.$emit("create", newCatalog);
      }
    },
    closeDialog () {
      this.isUpdating = false;
      this.$emit("close");
    }
  }
};
</script>

<style>

</style>
