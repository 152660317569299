var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800px", "min-height": "800px" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-icon",
                _vm._g({ attrs: { color: "purple", large: "" } }, on),
                [_vm._v("mdi-test-tube")]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "span",
                {
                  staticClass: "headline",
                  staticStyle: { "padding-right": "10px" }
                },
                [_c("strong", [_vm._v(_vm._s(_vm.validation.name))])]
              ),
              _vm._l(_vm.validation.rules, function(rule, i) {
                return _c(
                  "v-tooltip",
                  {
                    key: rule.id,
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-chip",
                                _vm._g(
                                  {
                                    staticClass: "white--text mr-2",
                                    attrs: {
                                      color:
                                        i % 2 == 0
                                          ? "light-blue darken-1"
                                          : "light-blue darken-3"
                                    }
                                  },
                                  on
                                ),
                                [_vm._v(_vm._s(rule.ruleType))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [_c("span", [_vm._v(_vm._s(rule.name))])]
                )
              })
            ],
            2
          ),
          _c("v-divider", { staticClass: "pb-10" }),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                [
                  !_vm.validationResult.validated
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.testValidation }
                        },
                        [_vm._v("Test Validation")]
                      )
                    : _vm._e(),
                  _vm.validationResult.validated
                    ? [
                        _c(
                          "h1",
                          [
                            _vm._v("Valid: "),
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  large: "",
                                  color: _vm.validationResult.result
                                    ? "success"
                                    : "error"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.validationResult.result
                                      ? "done"
                                      : "close"
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        !_vm.validationResult.result
                          ? _vm._l(_vm.validationResult.issues, function(
                              validation,
                              i
                            ) {
                              return _c(
                                "div",
                                { key: i, staticClass: "py-5" },
                                [
                                  _c(
                                    "h2",
                                    [
                                      _vm._v(_vm._s(validation.name) + " "),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            large: "",
                                            color:
                                              validation.status == "Active"
                                                ? "success"
                                                : validation.status ==
                                                  "Experimental"
                                                ? "purple"
                                                : "warning"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                validation.status == "Active"
                                                  ? "mdi-checkbox-marked-circle"
                                                  : validation.status ==
                                                    "Experimental"
                                                  ? "mdi-test-tube"
                                                  : "mdi-block-helper"
                                              )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            })
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }