<template>
  <v-form v-model="searchParamsValid" class="mx-3 mt-2">
    <v-row align="center" class="mt-3">
      <v-col cols=12 md=6 class="pl-0 py-0">
        <v-text-field
            label="Erätunnus"
        ></v-text-field>
      </v-col>
      <v-col cols=12 md=6 class="pl-0 py-0">
        <v-text-field
            label="Tilausvälityskeskus"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col class="pl-0 py-0" cols=12 sm=4>
        <v-text-field
          v-model="updatedSearchParams.carNumber"
          label="Autonumero"
        ></v-text-field>
      </v-col>
      <v-col class="pl-0 py-0" cols=12 sm=4>
        <v-text-field
          v-model="updatedSearchParams.taximeterSerialNumber"
          label="Taksamittarin sarjanumero"
        ></v-text-field>
      </v-col>
      <v-col class="pl-0 py-0" cols=12 sm=4>
        <v-text-field
          v-model="updatedSearchParams.cardNumber"
          label="Korttinumero"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols=12 md=3 class="pl-0 py-0">Tapahtumaa muokattu</v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
          v-model="updatedSearchParams.modifiedFrom"
          label="Muokattu alkaen"
          type="datetime-local"
        ></v-text-field>
      </v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
        v-model="updatedSearchParams.modifiedTo"
        label="Muokattu päättyen"
        type="datetime-local"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols=12 md=3 class="pl-0 py-0">Matka alkanut</v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
          v-model="updatedSearchParams.tripStartedFrom"
          label="Matkan alku alkaen"
          type="datetime-local"
        ></v-text-field>
      </v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
          v-model="updatedSearchParams.tripStartedTo"
          label="Matkan alku päättyen"
          type="datetime-local"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols=12 md=3 class="pl-0 py-0">Matka päättynyt</v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
          v-model="updatedSearchParams.tripEndedFrom"
          label="Matka päättynyt alkaen"
          type="datetime-local"
        ></v-text-field>
      </v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
          v-model="updatedSearchParams.tripEndedTo"
          label="Matka päättynyt päättyen"
          type="datetime-local"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>

    </v-row>
    </v-form>
</template>

<script>
import _ from "lodash";
import { TransportEventStatusMixin } from "@/models/TransportEventStatusTypes";
import { TranportEventValidationErrorMixin } from "@/models/ValidationErrors";
import { TransportEventInvoicingMixin } from "@/models/TransportEventInvoicingStates";

export default {
  props: {
    searchParams: Object
  },
  mixins: [ TransportEventStatusMixin, TranportEventValidationErrorMixin, TransportEventInvoicingMixin ],
  data () {
    return {
      updatedSearchParams: this.formatSearchParams(this.searchParams),
      searchParamsValid: true
    };
  },
  watch: {
    searchParams (val) {
      this.updatedSearchParams = this.formatSearchParams(this.searchParams);
    }
  },
  methods: {
    formatSearchParams (searchParams) {
      let params = {};
      if (!searchParams) return params;
      if (searchParams.validationStates) {
        params.validationStates = _.cloneDeep(searchParams.validationStates);
      } else {
        params.validationStates = [];
      }
      if (searchParams.validationFailReasonCodes) {
        params.validationFailReasonCodes = _.cloneDeep(searchParams.validationFailReasonCodes);
      }
      if (searchParams.salesInvoiceStates) {
        params.salesInvoiceStates = _.cloneDeep(searchParams.salesInvoiceStates);
      } else {
        params.salesInvoiceStates = [];
      }
      if (searchParams.salesInvoiceNumbers) {
        params.salesInvoiceNumbers = _.cloneDeep(searchParams.salesInvoiceNumbers);
      }
      if (searchParams.purchaseInvoiceStates) {
        params.purchaseInvoiceStates = _.cloneDeep(searchParams.purchaseInvoiceStates);
      } else {
        params.purchaseInvoiceStates = [];
      }
      if (searchParams.purchaseInvoiceNumbers) {
        params.purchaseInvoiceNumbers = _.cloneDeep(searchParams.purchaseInvoiceNumbers);
      }
      if (searchParams.carNumber) {
        params.carNumber = _.cloneDeep(searchParams.carNumber);
      }
      if (searchParams.cardNumber) {
        params.cardNumber = _.cloneDeep(searchParams.cardNumber);
      }
      if (searchParams.taximeterSerialNumber) {
        params.taximeterSerialNumber = _.cloneDeep(searchParams.taximeterSerialNumber);
      }
      if (searchParams.modifiedFrom) {
        params.modifiedFrom = _.cloneDeep(searchParams.modifiedFrom);
      }
      if (searchParams.modifiedTo) {
        params.modifiedTo = _.cloneDeep(searchParams.modifiedTo);
      }
      if (searchParams.tripStartedFrom) {
        params.tripStartedFrom = _.cloneDeep(searchParams.tripStartedFrom);
      }
      if (searchParams.tripStartedTo) {
        params.tripStartedTo = _.cloneDeep(searchParams.tripStartedTo);
      }
      if (searchParams.tripEndedFrom) {
        params.tripEndedFrom = _.cloneDeep(searchParams.tripEndedFrom);
      }
      if (searchParams.tripEndedTo) {
        params.tripEndedTo = _.cloneDeep(searchParams.tripEndedTo);
      }
      return params;
    },
    setFailedValidations () {
      this.updatedSearchParams.validationStates = this.validationStatusFailTypes;
    },
    getSearchParameters () {
      if (!this.updatedSearchParams.validationStates.includes(this.validationStatusTypes.ExternalValidationFailed)) {
        delete this.updatedSearchParams.validationFailReasonCodes;
      }
      return this.updatedSearchParams;
    }
  }
};
</script>

<style>

</style>
