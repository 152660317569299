<template>
  <v-dialog v-model="isOpen" persistent max-width="800">
    <v-card>
      <v-card-title>
        <span class="headline">Geocode address</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="address.streetName" label="Street name" placeholder="Name"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="address.streetNumber" label="Street number" placeholder="Number"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
              <v-text-field v-model="address.city" label="City"></v-text-field>
              </v-col>
              <v-col cols="6">
              <v-text-field v-model="address.zipcode" label="Zip code" placeholder="code"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn @click="getRoute" :loading="isUpdating" color="success">Geo code</v-btn>
        <v-btn @click="close" :loading="isUpdating">Close</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

const mapToGeoCodeRequest = (address) => {
  return {
    Address: {
      StreetName: address.streetName,
      StreetNumber: address.streetNumber,
      City: address.city,
      Zipcode: address.zipcode
    }
  };
};

export default {
  props: {
    isUpdating: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean,
      default: false
    } },
  data () {
    return {
      address: {
        streetName: "",
        streetNumber: "",
        city: "",
        zipcode: ""
      }
    };
  },
  methods: {
    getRoute () {
      this.$emit("geoCodeAddress", mapToGeoCodeRequest(this.address));
      this.$emit("close");
    },
    close () {
      this.$emit("close");
    }
  }
};
</script>

<style>
.v-subheader {
  padding: 0;
  font-size: 1.2em;
}
</style>
