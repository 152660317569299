var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-chip-group",
    { attrs: { column: "", dark: "" } },
    [
      _vm._l(_vm.rules, function(rule, n) {
        return [
          _c(
            "v-tooltip",
            {
              key: n,
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-chip",
                          _vm._g(
                            {
                              key: n,
                              attrs: {
                                close: "",
                                "close-icon": "mdi-delete",
                                color: "primary"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.openRuleDialog(rule, n)
                                },
                                "click:close": function($event) {
                                  return _vm.deleteRule(n)
                                }
                              }
                            },
                            on
                          ),
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  rule.name ||
                                    _vm.formatRuleTypeToString(rule.ruleType)
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            },
            [_c("span", [_vm._v(_vm._s(_vm.formatRuleToToolTip(rule)))])]
          )
        ]
      }),
      _c(
        "v-chip",
        {
          attrs: { color: "success" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.openNewRuleDialog($event)
            }
          }
        },
        [
          _vm._v(" Add new rule "),
          _c("v-icon", { attrs: { right: "" } }, [_vm._v("mdi-plus")])
        ],
        1
      ),
      _c("RuleDialog", {
        attrs: {
          isOpen: _vm.ruleDialog,
          originalRule: _vm.selectedRule,
          isNewRule: _vm.newRule,
          allowedRuleTypes: _vm.allowedRuleTypes,
          isUpdating: _vm.isUpdating
        },
        on: {
          create: _vm.addRule,
          save: _vm.modifyRule,
          close: _vm.closeRuleDialog
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }