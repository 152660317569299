import { pricingService } from "./repository";

const resource = "api/v1/pricecatalog";

export default {
  find () {
    return pricingService.get(`${resource}`);
  },
  create (priceCatalog) {
    return pricingService.post(`${resource}`, priceCatalog);
  },
  update (priceCatalogId, priceCatalog) {
    return pricingService.put(`${resource}/${priceCatalogId}`, priceCatalog);
  }
};
