<template>
  <v-dialog v-model="dialog" max-width="800px" min-height="800px">
      <template v-slot:activator="{ on }">
          <v-icon v-on="on" color="purple" large>mdi-test-tube</v-icon>
      </template>
      <v-card>
            <v-card-title>
                <span style="padding-right:10px;" class="headline"><strong>{{ validation.name }}</strong></span>
                <v-tooltip bottom :key="rule.id" v-for="(rule, i) in validation.rules">
                    <template v-slot:activator="{ on }">
                        <v-chip
                            v-on="on"
                            :color="i % 2 == 0 ? 'light-blue darken-1' : 'light-blue darken-3'"
                            class="white--text mr-2"
                        >{{ rule.ruleType }}</v-chip>
                    </template>
                    <span>{{ rule.name }}</span>
                </v-tooltip>
            </v-card-title>
            <v-divider class="pb-10"></v-divider>
            <v-card-text>
                <v-form>
                    <!-- <v-textarea @input="validate" auto-grow v-model="order" :background-color="validOrder ? 'green lighten-5' : 'red lighten-5'"> -->
                    <!-- </v-textarea> -->
                    <v-btn v-if="!validationResult.validated" color="primary" @click="testValidation">Test Validation</v-btn>

                    <template v-if="validationResult.validated">
                        <h1>Valid: <v-icon large :color="validationResult.result ? 'success' : 'error'">{{validationResult.result ? 'done' : 'close'}}</v-icon></h1>
                        <template v-if="!validationResult.result">

                            <div class="py-5" v-for="(validation, i) in validationResult.issues" :key=i>
                                <h2>{{validation.name}}
                                    <v-icon large :color="validation.status == 'Active' ? 'success' : validation.status == 'Experimental' ? 'purple' : 'warning'">
                                    {{ validation.status == "Active" ? "mdi-checkbox-marked-circle" : validation.status == "Experimental" ? "mdi-test-tube" : "mdi-block-helper" }}</v-icon>
                                </h2>
                            </div>
                        </template>
                    </template>
                </v-form>
            </v-card-text>
        </v-card>
  </v-dialog>
</template>

<script>
import { RepositoryFactory } from "../../data/repositories/repositoryFactory";

const ValidationsRepository = RepositoryFactory.get("validations");

export default {
  data: () => ({
    dialog: false,
    validOrder: false,
    order: "",
    validationResult: {
      result: false,
      issues: [],
      validated: false
    }
  }),
  methods: {
    mapResult (result) {
      if (!result.items || result.items.length === 0) {
        this.validationResult.validated = true;
        this.validationResult.result = true;
        return;
      }
      this.validationResult.issues.push(...result.items);
      this.validationResult.validated = true;
    },
    async testValidation () {
      this.order.data.operator = { id: this.validation.operatorId, name: "" };
      let { data: result } = await ValidationsRepository.testValidation(this.order);
      this.mapResult(result.data);
    }
  },
  created () {
    let result = ValidationsRepository.getSampleOrder();
    this.order = JSON.parse(result);
  },
  props: {
    validation: Object
  }
};
</script>
