var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "fill-height": "" } },
    [
      _c("h1", [_vm._v("Geo service")]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [
              _c("v-select", {
                staticClass: "ma-2",
                attrs: { items: _vm.clients, label: "Client", dense: "" },
                model: {
                  value: _vm.client,
                  callback: function($$v) {
                    _vm.client = $$v
                  },
                  expression: "client"
                }
              })
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "ma-2",
              attrs: { color: "primary" },
              on: {
                click: function($event) {
                  _vm.showRoute = true
                }
              }
            },
            [_vm._v("Calculate Route")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "ma-2",
              attrs: { color: "primary" },
              on: {
                click: function($event) {
                  _vm.showIsochrone = true
                }
              }
            },
            [_vm._v("Calculate Isochrone")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "ma-2",
              attrs: { color: "primary" },
              on: {
                click: function($event) {
                  _vm.showGeoCode = true
                }
              }
            },
            [_vm._v("Geo code address")]
          )
        ],
        1
      ),
      _c("RouteDialog", {
        attrs: { isOpen: _vm.showRoute },
        on: {
          getRoute: _vm.getRoute,
          close: function($event) {
            _vm.showRoute = false
          }
        }
      }),
      _c("IsochroneDialog", {
        attrs: { isOpen: _vm.showIsochrone },
        on: {
          getIsochrone: _vm.getIsochrone,
          close: function($event) {
            _vm.showIsochrone = false
          }
        }
      }),
      _c("GeoCodeDialog", {
        attrs: { isOpen: _vm.showGeoCode },
        on: {
          geoCodeAddress: _vm.geoCodeAddress,
          close: function($event) {
            _vm.showGeoCode = false
          }
        }
      }),
      _c(
        "v-row",
        { staticStyle: { height: "600px" } },
        [
          _c("Map", {
            attrs: {
              center: _vm.mapCenter,
              routes: _vm.route ? [_vm.route] : [],
              isochrones: _vm.isochrone ? [_vm.isochrone] : [],
              locations: _vm.geoCodeLocation ? [_vm.geoCodeLocation] : []
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }