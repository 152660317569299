<template>
    <v-card
    class="mx-auto"
    max-width="400"
    v-if="car"
  >
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">Vehicle details</v-list-item-title>
        <v-list-item-subtitle>{{car.CarNumber}}, {{car.Company}}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
                  <v-btn icon @click="close()">
                    <v-icon color="red lighten-1">mdi-close-circle</v-icon>
                  </v-btn>
                </v-list-item-action>
    </v-list-item>

    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-calendar-clock</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{formatDateTime(car.LastUpdated)}}</v-list-item-subtitle>
    </v-list-item>

    <v-list-item>
      <v-list-item-icon>
        <v-icon title="Driver Id">mdi-account</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{car.DriverId}}</v-list-item-subtitle>
    </v-list-item>

    <v-list-item>
      <v-list-item-icon>
        <v-icon title="State">mdi-state-machine</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{car.State}}</v-list-item-subtitle>
    </v-list-item>

    <v-list-item>
      <v-list-item-icon>
        <v-icon title="Area">mdi-vector-polygon</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{car.Area}}</v-list-item-subtitle>
    </v-list-item>

    <v-list-item>
      <v-list-item-icon>
        <v-icon title="Speed">mdi-send</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{car.Speed}} km/h</v-list-item-subtitle>
    </v-list-item>

    <v-list-item>
      <v-list-item-icon>
        <v-icon title="Location">mdi-map-marker</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{Number(car.Latitude).toFixed(3)}}, {{Number(car.Longitude).toFixed(3)}}</v-list-item-subtitle>
    </v-list-item>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  props: {
    car: {
      type: Object,
      default: function () {
        return null;
      }
    }
  },
  methods: {
    formatDateTime: function (datetime) {
      return moment(datetime).format("L LT");
    },
    close: function () {
      this.$emit("close");
    }
  }
};
</script>
