<template>
    <v-container>
    <v-row justify="space-around" align="center">
            <v-col style="width: 290px; flex: 0 1 auto;">
                <h2>{{ startLabelText }}</h2>
                <v-time-picker
                    :input="$emit('timeRangeUpdated', timeRangeObject)"
                    :format="timeFormat"
                    v-model="startTime">
                </v-time-picker>
            </v-col>
            <v-col style="width: 290px; flex: 0 1 auto;">
                <h2>{{ endLabelText }}</h2>
                <v-time-picker
                    :input="$emit('timeRangeUpdated', timeRangeObject)"
                    :format="timeFormat"
                    v-model="endTime">
                </v-time-picker>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
  props: {
    startLabelText: {
      type: String,
      default: "Shift Start:"
    },
    endLabelText: {
      type: String,
      default: "Shift End:"
    },
    timeFormat: {
      type: String,
      default: "24hr",
      validator: value => ["ampm", "24hr"].indexOf(value) !== -1
    }
  },
  data: () => ({
    startTime: "",
    endTime: ""
  }),
  computed: {
    timeRangeObject () {
      return {
        startTime: this.startTime,
        endTime: this.endTime
      };
    }
  }
};
</script>

<style>

</style>
